import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import Sidebar from '../components/Sidebar'

const Referal = () => {
  return (
    <div className='page'>
		<Sidebar />
        <div className='main'>
            <h1>Реферальна програма</h1>
            <div className='referal__page'>
                <div className='referal__count'>
                    <div className='referal__countOne'>
                        <p className='referal__countOne-header'>Бонуси</p>
                        <div className='referal__countOne-count'>
                            <p>Отримано бонусів</p>
                            <span className='referal__countOne-digit'>0 ₴</span>
                        </div>
                    </div>
                    <div className='referal__countOne'>
                        <p className='referal__countOne-header'>Запрошено людей</p>
                        <div className='referal__countOne-count'>
                            <p>Ви запросили</p>
                            <span className='referal__countOne-digit'>0 чол.</span>
                        </div>
                    </div>
                </div>
                <div className='referal__link'>
                    <h3>Ваше посилання</h3>
                    <div className='referal__link-text'>
                        <p>https://kirins.com.ua/ref/8kqj0hx716</p>
                        <Button>Скопіювати</Button>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Запрошені</TableCell>
                                <TableCell>Дата запрошення</TableCell>
                                <TableCell>Отримано бонусів</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <p>Іванов Михайло Іванович</p>
                                    <p>test24@gmail.com</p>
                                </TableCell>
                                <TableCell>01.12.2021</TableCell>
                                <TableCell>124</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='referal__howwork'>
                    <h3>Як це працює</h3>
                    <ol>
                        <li>Діліться посиланням з друзями та отримуйте додатковий дохід з їх покупок</li>
                        <li>З кожної покупки Автоцивілки або Зеленої карти отримуйте 5% від вартості полісу на свій бонусний рахунок</li>
                        <li>1 бонус = 1 гривні. Чим більше запрошених друзів тим більше бонусів Ви будете отримувати</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Referal