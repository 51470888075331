import { Skeleton } from '@mui/material'
import React from 'react'
import PolicySceleton from './PolicySceleton'

const PolicySceletonList = () => {
  return (
    <div className='documents-list'>
        <PolicySceleton />
        <PolicySceleton />
        <PolicySceleton />
    </div>
  )
}

export default PolicySceletonList