import React, { useEffect } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button } from '@mui/material';
import { useState } from 'react';
import { getUserAPI, resetUserPasswordAPI } from '../../api/userAPI';
import ProfileSceleton from './ProfileSceleton';
import { getDateOnly, getDateOnlyUa } from '../../helpers/date/getDateFormat';
import MyButton from '../UI/button/MyButton';
import MyModal from '../UI/modal/MyModal';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const ProfileView = ({user}) => {

    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(true)

    const [openModal, setOpenModal] = useState(false)
    const [isAgry, setIsAgry] = useState(false)
    const [isRecovery, setIsRecovery] = useState(false)

    useEffect(() => {
        getUserAPI(user.id).then(data => {
            setUserData(data)
            setLoading(false)
        }).catch(e => {
            console.error(e)
        })
    }, [])

    useEffect(() => {
        if (isAgry) {
            resetUserPasswordAPI(user.email)
            .then(data => {
                setIsRecovery(true)
                setIsAgry(false)
            })
        }
    }, [isAgry])

  return (
    <>
        <MyModal
            header='Ви впевнені, що хочете змінити пароль?'
            text='На Ваш email буде відправлено лист для відновлення паролю'
            textYes='Відновити'
            isOpen={openModal}
            setOpenModal={setOpenModal}
            setIsAgry={setIsAgry}
        /> 
    {
        loading ?
            <ProfileSceleton />
        :
        <div>
            <ul className='personal__list'>
                <li>
                    <p>Email</p>
                    <span>{userData.email}</span>
                </li>
                <li>
                    <p>Телефон</p>
                    <span>{userData.phone ? '+' + userData.phoneCode + userData.phone : 'не вказано'}</span>
                </li>
                <li>
                    <p>ІПН</p>
                    <span>{userData.inn ? userData.inn : 'не вказано'}</span>
                </li>
                <li>
                    <p>Прізвище</p>
                    <span>{userData.surname ? userData.surname : 'не вказано'}</span>
                </li>
                <li>
                    <p>Ім'я</p>
                    <span>{userData.name ? userData.name : 'не вказано'}</span>
                </li>
                <li>
                    <p>Побатькові</p>
                    <span>{userData.patronymic ? userData.patronymic : 'не вказано'}</span>
                </li>
                <li>
                    <p>Дата народження</p>
                    <span>{userData.dateOfBirth ? getDateOnlyUa(userData.dateOfBirth) : 'не вказано'}</span>
                </li>
                {/* <li>
                    <p>Фото профілю</p>
                    <span>
                        <AccountCircleIcon 
                            sx={{ 
                                width: 64, 
                                height: 64,
                                // color: '#393017'
                            }} 
                        />
                        <Button>Змінити фото</Button>
                    </span>
                </li> */}
            </ul>
            <div className='changePassword'>
                <ul className='personal__list'>
                    <li>
                        <p>Пароль</p>
                        <span>
                            {
                                isRecovery ?
                                    <div>
                                        <MarkEmailReadIcon
                                            sx={{color: 'green'}}
                                        />
                                        <span> Лист для відновлення паролю успішно надіслано</span>
                                    </div>
                                :
                                    <MyButton
                                        classNameMy='myBtn__green'
                                        onClick={e => setOpenModal(true)}
                                        disabled={isAgry ? true : false}
                                    >
                                        Змінити пароль
                                    </MyButton>
                            }
                        </span>
                    </li>
                </ul>
            </div>

        </div>
    }
    </>
  )
}

export default ProfileView