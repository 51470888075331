import React from 'react'

const LoginBlock = () => {

  return (
    <div className='loginHeader__buttons'>
        <a href='/login' className="myBtn__green-empty">
            Вхід
        </a>
        <a href='/register' className="myBtn__green">
            Реєстрація
        </a>
    </div>
  )
}

export default LoginBlock