import { createSlice } from "@reduxjs/toolkit"

const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        activeMenuItem: '',
        activeProductItem: 'all',
    },
    reducers: {
        changeActiveMenuItem(state, action) {
            state.activeMenuItem = action.payload
        },
        changeActiveProductItem(state, action) {
            state.activeProductItem = action.payload
        },
    }
})

export default menuSlice.reducer

export const {changeActiveMenuItem, 
                changeActiveProductItem,
                
            } = menuSlice.actions