import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addDocumentAPI, editDocumentAPI } from '../../api/documentAPI'
import { useMyInput } from '../../hooks/ValidateForm'
import { DOCUMENTS_TYPE_ARRAY, DOCUMENTS_TYPE_MAIN, DOCUMENTS_TYPE_MAIN_FIELDS } from '../../locale/document/DocumentLocale'
import { setAlertOpen, setAlertText, setAlertType } from '../../store/alertSlice'
import { addPersonDocument, editPersonDocument } from '../../store/personSlice'
import MyButton from '../UI/button/MyButton'
import MyDatePicker from '../UI/datePicker/MyDatePicker'
import DocumentField from './DocumentField'
import { useNavigate } from 'react-router-dom'
import { setIsAuth } from '../../store/userSlice'
import { useValidateRecord } from '../../hooks/ValidateRecord'

const DocumentForm = ({ typeForm, document, setDocEditId, setIsAddDoc, personId, hasDocuments }) => {

    const docType = useMyInput('', {isEmpty: true})
    const docSeries = useMyInput('', {isEmpty: true, minLength: 1})
    const docNumber = useMyInput(document ? document.number : '', {isEmpty: true, minLength: 4})
    const docRecord = useMyInput('', {isEmpty: true})
    const docWhoVid = useMyInput(document ? document.whoVid : '', {isEmpty: true, minLength: 2})
    const dateVid = useMyInput('', {isEmpty: true, isDateVid: true})
    const dateTo = useMyInput('', {isEmpty: true, isDateTo: true})

    const isValidRecord = useValidateRecord(docRecord.value)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [documentTypes, setDocumentTypes] = useState([])
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        // insertSelect(DOCUMENTS_TYPE_MAIN[locale][autoName.value], setAutoTypeList)
        setDocumentTypes(DOCUMENTS_TYPE_ARRAY)
    }, [docType.value])

    useEffect(() => {
        if(document) {
            if (document.series) {
                docSeries.onChange(document.series)
            }
            if (document.record) {
                docRecord.onChange(document.record)
            }
            if (document.dateVid) {
                dateVid.onChange(moment(document.dateVid)._d)
            }
            if (document.dateTo) {
                dateTo.onChange(moment(document.dateTo)._d)
            }
            if (document.type) {
                docType.onChange(document.type)
            }
        }
    }, [])

    useEffect(() => {
        if (
            docType.isInputValid &&
            docSeries.isInputValid &&
            docNumber.isInputValid &&
            docRecord.isInputValid &&
            docWhoVid.isInputValid &&
            dateVid.isInputValid &&
            dateTo.isInputValid &&
            (docRecord.value && docRecord.isInputValid ? docRecord.isInputValid && isValidRecord : true)
            // isValidRecord
        ) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [docType, docSeries, docNumber, docRecord, docWhoVid, dateVid, dateTo, isValidRecord])

    useEffect(() => {
        if (docType.value) {

            if(DOCUMENTS_TYPE_MAIN_FIELDS[docType.value].includes('series')) {
                docSeries.isConsider(false)
            } else {
                docSeries.isConsider(true)
            }
    
            if(DOCUMENTS_TYPE_MAIN_FIELDS[docType.value].includes('record')) {
                docRecord.isConsider(false)
            } else {
                docRecord.isConsider(true)
            }

            if(DOCUMENTS_TYPE_MAIN_FIELDS[docType.value].includes('dateTo')) {
                dateTo.isConsider(false)
            } else {
                dateTo.isConsider(true)
            }
        }
    }, [docType])

    const save = () => {
        const documentTemp = setData()
        // console.log(auto)
        if (typeForm === 'add') {
            addDocumentAPI(documentTemp).then(data => {
                dispatch(addPersonDocument(data))
    
                dispatch(setAlertText('Документ успішно додано'))
                dispatch(setAlertType('success'))
                dispatch(setAlertOpen(true))
    
                setIsAddDoc(false)
            }).catch(e => {
                if (e.response.status === 401) {
                    dispatch(setIsAuth(false))
                    navigate('/login')
                }
                dispatch(setAlertText(e.response.data.message))
                dispatch(setAlertType('error'))
                dispatch(setAlertOpen(true))
            })
        } else if (typeForm === 'edit') {
            editDocumentAPI(documentTemp).then(data => {
                dispatch(editPersonDocument(documentTemp))
    
                dispatch(setAlertText('Документ успішно змінено'))
                dispatch(setAlertType('success'))
                dispatch(setAlertOpen(true))
    
                setDocEditId(null)
            }).catch(e => {
                if (e.response.status === 401) {
                    dispatch(setIsAuth(false))
                    navigate('/login')
                }
                dispatch(setAlertText(e.response.data.message))
                dispatch(setAlertType('error'))
                dispatch(setAlertOpen(true))
            })
        }

        setTimeout(() => {
            dispatch(setAlertOpen(false))
        }, 3000);
    }

    const setData = () => {
        // console.log(mark.value)
        // console.log(model.value)
        let documentTemp = {
            personId: personId,
            type: docType.value, 
            series: docSeries.value ? docSeries.value.replace(/ /g, '').toUpperCase() : '', 
            number: docNumber.value ? docNumber.value.replace(/ /g, '').toUpperCase() : '', 
            record: docRecord.value ? docRecord.value : '', 
            whoVid: docWhoVid.value, 
            dateVid: dateVid.value, 
            dateTo: dateTo.value ? dateTo.value : null
        }

        if (typeForm === 'edit') {
            documentTemp = {...documentTemp, id: document.id}
        }
        
        return documentTemp
    }

  return (
    <div className={typeForm === 'edit' ? 'documentForm documentForm__edit' : 'documentForm'}>
        <div className='documents-listOne-headName'>
            KIRINS | <span>документ</span>
        </div>
        <div className='documentForm__center'>
            <div>
                <ul className='documentForm__list'>
                    <li>
                        <p>Тип документа</p>
                        <span>
                            <FormControl sx={{ m: 1, minWidth: 150, mt: 3 }}>
                                <Select
                                    // disabled={number.value ? false : true}
                                    value={docType.value}
                                    variant="standard"
                                    onChange={e => docType.onChange(e.target.value)}
                                    onClose={e => docType.onBlur(e)}
                                    error={docType.isDirty && docType.isEmpty ? true : false}
                                    color={docType.isDirty && docType.isEmpty ? 'error' : 'success'} // чтобы был успешный цвет
                                >
                                    {
                                        documentTypes.map(doc => {
                                            if (
                                                !hasDocuments.includes(doc) || 
                                                doc === 'EXTERNAL_PASSPORT' ||
                                                (document && document.type === doc)
                                            ) {
                                                return <MenuItem key={doc} value={doc}>
                                                            {
                                                                DOCUMENTS_TYPE_MAIN[doc]['uk']
                                                            }
                                                        </MenuItem>
                                            }
                                        }
                                        )
                                    }
                                </Select>
                                <FormHelperText>
                                    {docType.isDirty && docType.errors.isEmpty ? docType.errors.isEmpty : ''}
                                </FormHelperText>
                            </FormControl>
                        </span>
                    </li>
                    <DocumentField
                        field={docSeries}
                        document={docType.value} 
                        type='series'
                        label={'Серия'}
                        placeholder={'МН'}
                    />
                    <DocumentField
                        field={docNumber}
                        document={docType.value} 
                        type='number'
                        label={'Номер'}
                        placeholder={'123456'}
                    />
                    <DocumentField
                        field={docRecord}
                        document={docType.value} 
                        type='record'
                        label={'Запис'}
                        placeholder={'11111111-11111'}
                        isRecord={true}
                        isValidRecord={isValidRecord}
                    />
                    <DocumentField
                        field={docWhoVid}
                        document={docType.value} 
                        type='issuedBy'
                        label={'Ким видано'}
                        placeholder={'Ким видано документ'}
                    />
                    {
                        docType.value ?
                            <li>
                                <p>Дата видачі</p>
                                <span>
                                    <MyDatePicker
                                        labelText='Дата видачі'
                                        value={dateVid.value}
                                        onBlur={e => dateVid.onBlur(e)}
                                        onChange={date => dateVid.onChange(date)}
                                        isError={dateVid.dateVidError}
                                        isDirty={dateVid.isDirty}
                                        error={dateVid.errors.isDateVid ? dateVid.errors.isDateVid : ''}
                                        maxDateType='today'
                                        isDisable={false}
                                    />
                                </span>
                            </li>
                        : ''
                    }
                    {
                        docType.value && DOCUMENTS_TYPE_MAIN_FIELDS[docType.value].includes('dateTo') ?
                            <li>
                                <p>Дійсне до</p>
                                <span>
                                    <MyDatePicker
                                        labelText='Дата видачі'
                                        placeholderText='31.12.2033'
                                        value={dateTo.value}
                                        onBlur={e => dateTo.onBlur(e)}
                                        onChange={date => dateTo.onChange(date)}
                                        isError={dateTo.dateToError}
                                        isDirty={dateTo.isDirty}
                                        error={dateTo.errors.isDateTo ? dateTo.errors.isDateTo : ''}
                                        maxDateType='to30years'
                                        minDateType='today'
                                        isDisable={false}
                                    />
                                </span>
                            </li>
                        : ''
                    }
                </ul>
            </div>
        </div>
        <div className='documentForm__buttons-form'>
            <MyButton
                disabled={isValid ? false : true}
                onClick={save}
            >
                {/* <AddCircleOutlineIcon /> */}
                {
                    typeForm === 'add' ? 'Додати документ' : ' Редагувати документ'
                }
            </MyButton>
            <MyButton 
                classNameMy='myBtn__grey'
                onClick={e => {
                    if (typeForm === 'add') {
                        setIsAddDoc(false)
                    } else {
                        setDocEditId(null)
                    }
                }}
            >
                {/* <AddCircleOutlineIcon /> */}
                Відмінити
            </MyButton>
        </div>
    </div>
  )
}

export default DocumentForm