import { TextField } from '@mui/material'
import React from 'react'

const MyInputForm = ({ field, fieldError, isField, label, placeholder, inputProps, forciblyError, forciblyErrorText }) => {
  return (
    <TextField
        label={label}
        placeholder={placeholder}
        size="small"
        variant='standard'
        onChange={e => field.onChange(e.target.value)} 
        onBlur={e => field.onBlur(e)} 
        value={field.value}
        error={field.isDirty && (field[fieldError] || forciblyError) ? true : false} // чтобы был текст ошибки
        color={
            field.isDirty && (field[fieldError] || forciblyError) ? 'error' : 'success'
            // inn.isDirty && !inn.innError ? 'success' : ''
        } // чтобы был успешный цвет
        helperText={
			field.isDirty && field.errors[isField] ? field.errors[isField] :
			field.isDirty && forciblyErrorText ? forciblyErrorText : ''
		} //Текст ошибки
        focused={field.isDirty ? true : false}
        InputLabelProps={{
            shrink: true
        }}
        InputProps={inputProps}
    />
  )
}

export default MyInputForm