import React from 'react'
import tas from '../../assets/img/tas-logo.svg'
import { getProductNameById } from '../../helpers/getProductNameById'
import PolicyAuto from './PolicyAuto'
import PolicyGeneral from './PolicyGeneral'
import PolicyInsurer from './PolicyInsurer'
import PolicyPersons from './PolicyPersons'
import { getPolicyColor } from '../../helpers/policy/PolicyTime'

const PolicyInfo = ({ policy }) => {

    const getStatus = () => {
        const timeEnd = getPolicyColor(policy.policy ? policy.policy.dateEnd : null) // 0, 1, -1

        if (Object.keys(policy).length && policy.policy.status) {
            if (policy.policy.status === 'EMITTED' && timeEnd === -1) {
                return <p className='policy__statusInfo policy__statusRed'>Закінчилась</p>
            } else if (policy.policy.status === 'EMITTED' && timeEnd !== -1) {
                return <p className='policy__statusInfo policy__statusGreen'>Оформлено</p>
            } else if (policy.policy.status === 'DRAFT') {
                return <p className='policy__statusInfo'>Не оформлено</p>
            } else {
                return <p className='policy__statusInfo'>Не оформлено</p>
            }
        }
        return ''
    }

  return (
    <div>
        <div className='policy__head'>
            <div>
                <div className='documents-listOne-headName'>
                    KIRINS | 
                    <span>
                        {/* {policy && policy.policy ? ' ' + getProductNameById(policy.policy.productId) : ''} */}
                        { policy.product ? ' ' + policy.product.nameUa : ''}
                    </span>
                </div>
            </div>
            <div className='policy__status'>
                <p>Статус</p>
                {
                    getStatus()
                }
            </div>
            <div>
                {/* <img src={tas} alt="TAS" /> */}
                {
                    policy.insuranceCompany && policy.insuranceCompany.logo ?
                        <img src={`/img/company-logo/${policy.insuranceCompany.logo}`} alt={policy.insuranceCompany.nameUa} />
                    :
                        <img src={`/assets/img/kirins_logo_ua_new.svg`} alt='KIRINS' />
                }
            </div>
        </div>
        <div className='policy_info'>
            <PolicyGeneral 
                policy={policy.policy} 
                insuranceCompany={policy.insuranceCompany}
                product={policy.product}
            />
            <div className='policy_infoRight'>
                {
                    policy.person ? policy.person.map(personOne => {
                        if (personOne.isSaller === true) {
                            return (
                                <PolicyInsurer 
                                    key={policy.policy.id}
                                    person={personOne} 
                                    city={policy.city}
                                    product={policy.product}
                                />
                            )
                        }
                    })
                    : ''
                }
                {
                    policy.auto ?
                        <PolicyAuto auto={policy.auto} />
                    : ''
                }
                <PolicyPersons persons={policy.person} />
            </div>
            
        </div>

    </div>
  )
}

export default PolicyInfo