import { $authHost } from "./index"

export const getCitiesByQueryAPI = async (query) => {
    const {data} = await $authHost.post(`api/ewa/cities`, { query })
    return data
}

export const getPoliciesByUserAPI = async (offset, limit, productId) => {
    let params = ''
    if ((offset || offset === 0) && limit) {
        params = `?offset=${offset}&limit=${limit}`
    }
    if (productId) {
        if(params) {
            params += `&productId=${productId}`
        } else {
            params = `?productId=${productId}`
        }
    }
    const {data} = await $authHost.get('api/policy/' + params)
    return data
}

export const getPolicyByUserAPI = async (id) => {
    const {data} = await $authHost.get('api/policy/' + id)
    return data
}