import { $authHost } from "./index"

export const addCarAPI = async (car) => {
    const {data} = await $authHost.post('api/cars/', car)
    return data
}

export const getCarAPI = async (id) => {
    const {data} = await $authHost.get('api/cars/' + id)
    return data
}

export const getCarsAPI = async (id) => {
    const {data} = await $authHost.get('api/cars/')
    return data
}

export const editCarAPI = async (car) => {
    const {data} = await $authHost.post('api/cars/' + car.id, car)
    return data
}

export const deleteCarAPI = async (id) => {
    console.log(id)
    // const formData = new FormData()
    // formData.append('id', id)
    const {data} = await $authHost.delete('api/cars/' + id, id)
    return data
}

export const getAutoByNumberAPI = async (number) => {
    // const {data} = await $authHost.get(`api/ewa/auto_by_number?number=${number}`)
    const {data} = await $authHost.post('api/auto/data_by_number', {number})
    return data
}

export const checkAutoNumberAPI = async (number) => {
    // const {data} = await $authHost.get(`api/ewa/auto_by_number?number=${number}`)
    const {data} = await $authHost.post('api/auto/check_number', {number})
    return data
}

export const getMarksAutoByNameAPI = async (query) => {
    const {data} = await $authHost.post(`api/directory/marks/query`, {query})
    return data
}

export const getModelsAutoByNameAPI = async (query, markId, typeAuto) => {
    const {data} = await $authHost.post(`api/directory/models/query`, {query, markId, typeAuto})
    return data
}

export const getCityRegisterByNumberAPI = async (number) => {
    const {data} = await $authHost.post(`api/osago/city_register_by_number/`, {
        number
    })
    return data
}