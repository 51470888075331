export const autoNames = { // Список авто
    'ru' : {
        "auto" : 'Легковой',
        "bike" : 'Мотоцикл',
        "bus" : 'Автобус',
        "truck" : 'Грузовой',
        "trailer" : 'Прицеп',
    },
    'uk' : {
        "auto" : 'Легковий',
        "bike" : 'Мотоцикл',
        "bus" : 'Автобус',
        "truck" : 'Вантажний',
        "trailer" : 'Причіп',
    },
    'en' : {
        "auto" : 'A car',
        "bike" : 'A bike',
        "bus" : 'A bus',
        "truck" : 'A truck',
        "trailer" : 'A trailer',
    },
}

export const autoNameEwaToMy = {
    'CAR' : 'auto',
    'MOTO' : 'bike',
    'PASSENGER' : 'bus',
    'FREIGHT' : 'truck',
    'TRAILER' : 'trailer',
}

export const autoTypeNames = { // Список авто
    'ru' : {
        "auto" : 'Объем двигателя',
        "bike" : 'Объем двигателя',
        "bus" : 'К-во мест',
        "truck" : 'Грузоподъемность',
        "trailer" : 'Тип прицепа',
    },
    'uk' : {
        "auto" : 'Об`єм двигуна',
        "bike" : 'Об`єм двигуна',
        "bus" : 'К-сть місць',
        "truck" : 'Вантажопідйомність',
        "trailer" : 'Тип причепу',
    },
    'en' : {
        "auto" : 'Engine volume',
        "bike" : 'Engine volume',
        "bus" : 'Number of seats',
        "truck" : 'Carrying capacity',
        "trailer" : 'Trailer type',
    },
}

export const autoTypes = { // Список авто
    'ru' : {
        "auto" : {
            'B1' : 'До 1.6 л',
            'B2' : '1.6 - 2 л',
            'B3' : '2 - 3 л',
            'B4' : '3+ л',
            'B5' : 'Электромобиль',
        },
        "bike" : {
            'A1' : 'До 300 см.куб',
            'A2' : '300+ см.куб',
        },
        "bus" : {
            'D1' : 'До 20',
            'D2' : '20+',
        },
        "truck" : {
            'C1' : 'До 2 тонн',
            'C2' : '2+ тонны',
        },
        "trailer" : {
            'F' : 'К легковым',
            'E' : 'К грузовым',
        },
    },
    'uk' : {
        "auto" : {
            'B1' : 'До 1.6 л',
            'B2' : '1.6 - 2 л',
            'B3' : '2 - 3 л',
            'B4' : '3+ л',
            'B5' : 'Электромобіль',
        },
        "bike" : {
            'A1' : 'До 300 см.куб',
            'A2' : '300+ см.куб',
        },
        "bus" : {
            'D1' : 'До 20',
            'D2' : '20+',
        },
        "truck" : {
            'C1' : 'До 2 тонн',
            'C2' : '2+ тонни',
        },
        "trailer" : {
            'F' : 'До легкових',
            'E' : 'До вантажних',
        },
    },
    'en' : {
        "auto" : {
            'B1' : 'Up to 1.6 L',
            'B2' : '1.6 - 2 L',
            'B3' : '2 - 3 L',
            'B4' : '3+ L',
            'B5' : 'Electric car',
        },
        "bike" : {
            'A1' : 'Up to 300 cm3',
            'A2' : '300+ cm3',
        },
        "bus" : {
            'D1' : 'До 20',
            'D2' : '20+',
        },
        "truck" : {
            'C1' : 'Up to 2 tons',
            'C2' : '2+ tons',
        },
        "trailer" : {
            'F' : 'Trailer for cars',
            'E' : 'Treiler for trucks',
        },
    },
}