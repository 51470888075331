import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react'
import { getMarksAutoByNameAPI } from '../../api/carAPI';

const AutoMarkFilter = ({ mark, disabled }) => {

    const [markTemp, setMarkTemp] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [marksList, setMarksList] = useState([])
    const [closeList, setCloseList] = useState(true)
    
    const getAutoMarksFromBd = (name) => {
        if (name.length > 1) {
            setIsLoading(true)
            getMarksAutoByNameAPI(name).then(data => {
                // console.log(data)
                // setListData(data)
                setMarksList(data.marks)
                setIsLoading(false)
                setCloseList(false)
                if (data.marks && data.marks.length === 0) {
                    setCloseList(true)
                    setMarksList([])
                }
            }).catch(e => console.log(e))
        } else if (name.length === 0) {
            mark.onChange({name: ''})
            setMarksList([])
        }
    }

    const filterOptions = (options, state) => { // Делаю поиск как по сокращенному полю на русском так и на укр
        let newOptions = [];
            options.forEach((element) => {
                if (
                    element.name
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                )
                    newOptions.push(element);
            });
        return newOptions;
    }

    const setListData = (data) => { // чтобы в поле id получить не наше ИД, а ЕВЫ
        if (data.length > 0) {
            const newList = data.map(one => {
                return {
                    markId: one.ewaId,
                    markEwaId: one.ewaId,
                    name: one.name,
                    cdbMtibuCode: one.cdbMtibuCode ? one.cdbMtibuCode : ''
                }
            })
            setMarksList(newList)
        }
    }

  return (
    <Autocomplete
        value={mark.value ? mark.value : markTemp}
        disabled={disabled ? true : false}
        onChange={(event, newValue) => { // Весь объект города
            if (newValue) {
                mark.onChange({
                    id: newValue.id ? newValue.id : '',
                    ewaId: newValue.ewaId ? newValue.ewaId : '',
                    name: newValue.name ? newValue.name : '',
                    cdbMtibuCode: newValue.cdbMtibuCode ? newValue.cdbMtibuCode : ''
                })
            }
            setCloseList(true)
        }}
        onBlur={e => {
            mark.onBlur(e)
            setCloseList(true)
        }} 
        // sx={{width:150}}
        clearOnBlur={false}
        open={!closeList || isLoading ? true : false}
        inputValue={markTemp}
        onInputChange={(event, newInputValue) => {
            if (event === null) {
                mark.onChange({...mark.value, name: newInputValue})
                setCloseList(true)
            } else if (event.type === 'change') {
                mark.onChange({name: newInputValue})
                getAutoMarksFromBd(newInputValue)
            }
            setMarkTemp(newInputValue);
            if (newInputValue.length === 0) {
                mark.onChange({name: ''})
                setIsLoading(false)
                setCloseList(true)
            }
        }}
        id="marksAuto"
        size="small"
        options={marksList}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option ? option.name : ''}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        noOptionsText={isLoading ? 'Пошук...' : 'Не знайдено'}
        clearText={'Очистити'}
        // componentsProps={{
        //     paper: {
        //         sx: {
        //         minWidth: 150,
        //         maxWidth: 300
        //         }
        //     }
        //     }}
        // sx={{ width: 300 }}
        renderInput={(params) => 
            <TextField
                {...params} 
                variant="standard"
                // label="Марка ТС" 
                placeholder="BMW" 
                error={mark.isDirty && mark.markAutoError ? true : false} // чтобы был текст ошибки
                color={
                    mark.isDirty && mark.isMarkAuto ? 'error' : 'success'
                } // чтобы был успешный цвет
                helperText={mark.isDirty && mark.errors.isMarkAuto ? mark.errors.isMarkAuto : ''} // Текст ошибки
                focused={mark.isDirty ? true : false}
                InputLabelProps={{
                    shrink: true
                }}
            />
        }
    />
  )
}

export default AutoMarkFilter