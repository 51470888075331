import React, { useRef, useState } from 'react'
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';

const MyDropdown = ({ children, idButton, idMenu, ButtonMain, classWrapper }) => {

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
        } else if (event.key === 'Escape') {
        	setOpen(false);
        }
    }
  
  return (
    <div 
        className={classWrapper}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={handleOpen}
    >
		<div
            ref={anchorRef}
            id={idButton}
            aria-controls={open ? idMenu : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            // onClick={handleToggle}
        >
			<ButtonMain />
		</div>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              	{...TransitionProps}
				style={{
					transformOrigin:
						placement === 'bottom-start' ? 'left top' : 'left bottom',
				}}
            >
				<Paper>
					<ClickAwayListener onClickAway={handleClose}>
						<MenuList
							autoFocusItem={open}
							id={idMenu}
							aria-labelledby={idMenu}
							onKeyDown={handleListKeyDown}
						>
							{
								children
							}
						</MenuList>
					</ClickAwayListener>
				</Paper>
            </Grow>
          )}
        </Popper>
    </div>
  )
}

export default MyDropdown