import React from 'react'

const MenuList = ({ menuItems, menuId }) => {

  return (
    <>
        {
            menuItems ?
            menuItems.map(item => {
                if (menuId === item.menuNameId ) {
                    return (
                        <li key={item.href}>
                            <a href={'/' + item.href}>
                                { item['nameUa']}
                            </a>
                        </li>
                    )
                }
            })
            : ''
        }      
    </>
  )
}

export default MenuList