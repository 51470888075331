import { useEffect, useState } from "react"
import moment from "moment"

export const useMyValidation = (value, validations, isConsiderInput, isDirty) => {
    const [isEmpty, setIsEmpty] = useState(true)
    const [minLengthError, setMinLengthError] = useState(false)
    const [maxLengthError, setMaxLengthError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [innError, setInnError] = useState(false)
    const [edrpouError, setEdrpouError] = useState(false)
    const [fioUaError, setFioUaError] = useState(false)
    const [nameUaError, setNameUaError] = useState(false)
    const [fioEnError, setFioEnError] = useState(false)
    const [nameEnError, setNameEnError] = useState(false)
    const [yearError, setYearError] = useState(false)
    const [dateBirth18Error, setDateBirth18Error] = useState(false)
    const [dateStartError, setDateStartError] = useState(false)
    const [dateVidError, setDateVidError] = useState(false)
    const [dateToError, setDateToError] = useState(false)
    const [numberAutoError, setNumberAutoError] = useState(false)
    const [markAutoError, setMarkAutoError] = useState(false)
    const [isInputValid, setIsInputValid] = useState(false)
    const [lengthError, setLengthError] = useState(false)
    const [digitError, setDigitError] = useState(false)

    const [errors, setErrors] = useState({
        isEmpty: '',
        minLength: '',
        maxLength: '',
        isEmail: '',
        isFioUa: '',
        isNameUa: '',
        isFioEn: '',
        isNameEn: '',
        isInn: '',
        isEdrpou: '',
        isDateStart: '',
        isDateBirth18: '',
        isDateVid: '',
        isDateTo: '',
        isYear: '',
        isAdress: '',
        isPhone: '',
        length: '',
        isDigit: '',
    })

    useEffect(() => {
        let isEmpty = '',
        minLength = '',
        maxLength = '',
        isEmail = '',
        isFioUa = '',
        isNameUa = '',
        isFioEn =  '', 
        isNameEn =  '', 
        isInn =  '',
        isEdrpou =  '',
        isYear =  '',
        isDateBirth18 = '',
        isDateVid = '',
        isDateTo = '',
        isDateStart = '',
        isNumberAuto = '',
        isMarkAuto = '',
        length = '',
        isDigit = ''

        for (const validation in validations) {
            let reg = '' // регулярка
            const dateNow = moment()
            switch (validation) {
                case 'isEmpty':
                    if(value){
                        setIsEmpty(false)
                        isEmpty = ''
                    } else {
                        setIsEmpty(true)
                        isEmpty = 'Поле не може бути порожнім'  
                    }
                    break
                case 'minLength':
                    if (!value) {
                        setMinLengthError(true)
                        minLength = 'Введіть значення'
                    } else if (value.length < validations[validation]){
                        setMinLengthError(true)
                        minLength = 'Минимальная длина: ' + validations[validation]
                    } else {
                        setMinLengthError(false)
                        minLength = ''  
                    }
                    break
                case 'maxLength':
                    if (value.length > validations[validation]){
                        setMaxLengthError(true)
                        maxLength = 'Максимальна довжина: ' + validations[validation]
                    } else {
                        setMaxLengthError(false)
                        maxLength = ''  
                    }
                    break
                case 'length':
                    console.log('validations[validation]', validations[validation])
                    if (value.length < validations[validation]){
                        setLengthError(true)
                        length = 'Довжина менше: ' + validations[validation]
                    } else if (value.length > validations[validation]){
                        setLengthError(true)
                        length = 'Довжина більше: ' + validations[validation]
                    } else {
                        setLengthError(false)
                        length = ''  
                    }
                    break
                case 'isEmail':
                    reg = /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,6}$/
                    if (reg.test(String(value).toLocaleLowerCase())) {
                        setEmailError(false)
                        isEmail = ''
                    } else {
                        setEmailError(true)
                        isEmail = 'Вкажіть коректний email'
                    }
                    break
                case 'isFioUa':
                    // reg = /^[A-Za-zа-яёА-ЯЁа-щА-ЩЬьЮюЯяЇїІіЄєҐґ\u0410-\u044F`\u044F'\u044Fʼ\u044F-\s]{6,}$/
                    reg = /^[а-яёА-ЯЁа-щА-ЩЬьЮюЯяЇїІіЄєҐґ\u0410-\u044F`\u044F'\u044Fʼ\u044F-\s]{2,}$/
                    if (reg.test(String(value).toLocaleLowerCase())) {
                        setFioUaError(false)
                        isFioUa = ''
                    } else {
                        setFioUaError(true)
                        isFioUa = 'Вкажіть українською мовою'
                    }
                    break
                case 'isNameUa':
                    reg = /^[A-Za-zа-яёА-ЯЁа-щА-ЩЬьЮюЯяЇїІіЄєҐґ\u0410-\u044F`\u044F'\u044Fʼ\u044F-\s]{6,}$/
                    if (reg.test(String(value).toLocaleLowerCase())) {
                        setNameUaError(false)
                        isNameUa = ''
                    } else {
                        setNameUaError(true)
                        isNameUa = 'Вкажіть назву українською мовою'
                    }
                    break
                case 'isFioEn':
                    reg = /^[A-Za-z\u044F-\s]{2,}$/
                    if (reg.test(String(value).toLocaleLowerCase())) {
                        setFioEnError(false)
                        isFioEn = ''
                    } else {
                        setFioEnError(true)
                        isFioEn = "Вкажіть англійською мовою"
                    }
                    break
                case 'isNameEn':
                    reg = /^[A-Za-z\u044F-\s]{2,}$/
                    if (reg.test(String(value).toLocaleLowerCase())) {
                        setNameEnError(false)
                        isNameEn = ''
                    } else {
                        setNameEnError(true)
                        isNameEn = "Вкажіть назву англійською мовою"
                    }
                    break
                case 'isInn':
                    reg = /^\d{10}$/
                    // const innData = useInnValidation(value)
                    if (reg.test(value)) {
                        setInnError(false)
                        isInn = ''
                    } else {
                        setInnError(true)
                        isInn = 'Вкажіть коректний ІПН (10 цифр)'
                    }
                    break
                case 'isEdrpou':
                    reg = /^\d{8}$/
                    if (reg.test(value)) {
                        setEdrpouError(false)
                        isEdrpou = ''
                    } else {
                        setEdrpouError(true)
                        isEdrpou = 'Код ЄДРПОУ маэ бути 8 цифр'
                    }
                    break
                case 'isYear':
                    reg = /^\d{4}$/
                    if (reg.test(value)) {
                        setYearError(false)
                        isYear = ''
                    } else {
                        setYearError(true)
                        isYear = 'Вкажіть коректний ІПН'
                    }
                    break
                case 'isDateBirth18':
                    // console.log(moment(value).toNow(true))
                    // console.log(value)
                    
                    if (isDirty && (!value || dateNow.diff(moment(value), 'years') > 100)) {
                        setDateBirth18Error(true)
                        isDateBirth18 = 'Введіть коректну дату'
                    } else if (dateNow.diff(moment(value), 'years') < 18) {
                        setDateBirth18Error(true)
                        isDateBirth18 = 'Страхувальнику має бути 18 років'
                    } else {
                        setDateBirth18Error(false)
                        isDateBirth18 = ''
                    }
                    // console.log(moment().diff(moment(value), 'years'))
                    // if (moment(value).toNow()) {
                    //     setYearError(false)
                    //     isYear = ''
                    // } else {
                    //     setYearError(true)
                    //     isYear = 'Вкажіть коректний ІПН'
                    // }
                    break
                case 'isDateStart':
                    // console.log(value)
                    // if (isDirty && (!value || value == '')) {
                    // if (isDirty && !value) {
                    if (!value) {
                        setDateStartError(true)
                        isDateStart = 'Введіть коректну дату'
                        break
                    } else if (dateNow.diff(moment(value), 'days') > 0) {
                        setDateStartError(true)
                        isDateStart = 'Дата початку не може бути раніше ніж сьогодні'
                        break
                    } 
                    // else {
                    //     setDateStartError(false)
                    //     isDateStart = ''
                    // }
                    setDateStartError(false)
                    isDateStart = ''
                    break
                case 'isDateVid':
                    if (isDirty && !value) {
                        setDateVidError(true)
                        isDateVid = 'Введіть коректну дату'
                    } else if (dateNow.diff(moment(value), 'days') < 0) {
                        setDateVidError(true)
                        isDateVid = 'Дата видачі не може бути пізніше ніж сьогодні'
                    } else {
                        setDateVidError(false)
                        isDateVid = ''
                    }
                    break
                case 'isDateTo':
                    if (isDirty && !value) {
                        setDateToError(true)
                        isDateTo = 'Введіть коректну дату'
                    } else if (dateNow.diff(moment(value), 'days') > 0) {
                        setDateToError(true)
                        isDateTo = 'Дата не може бути раніше ніж сьогодні'
                    } else {
                        setDateToError(false)
                        isDateTo = ''
                    }
                    break
                // case 'isNumberAuto':
                //     const isValidNumber = useNumberAutoValidate(value)
                //     if(isValidNumber){
                //         setNumberAutoError(false)
                //         isNumberAuto = ''
                //     } else {
                //         setNumberAutoError(true)
                //         isNumberAuto = 'Номер авто не відповідає номеру України'
                //     }
                //     break
                case 'isMarkAuto':
                    if(value.name !== '' && value.name !== undefined){
                        if (validations['isMinMark'] && value.name.length < validations['isMinMark']) {
                            setMarkAutoError(true)
                            isMarkAuto = 'Мінімум симовлів: ' + validations['isMinMark']
                        } else {
                            setMarkAutoError(false)
                            isMarkAuto = ''
                        }
                    } else {
                        setMarkAutoError(true)
                        isMarkAuto = 'Поле не може бути порожнім'
                    }
                    break
                case 'isDigit':
                    reg = /^\d*$/
                    if (reg.test(value)) {
                        setDigitError(false)
                        isDigit = ''
                    } else {
                        setDigitError(true)
                        isDigit = 'Допустимі тільки цифри'
                    }
                    break
                default:
                    break
            }
        }

        setErrors({
            isEmpty,
            minLength,
            maxLength,
            isEmail,
            isFioUa,
            isNameUa,
            isFioEn,
            isNameEn,
            isInn,
            isEdrpou,
            isYear,
            isDateBirth18,
            isDateVid,
            isDateTo,
            isDateStart,
            isNumberAuto,
            isMarkAuto,
            length,
            isDigit,
        })

    }, [value])

    useEffect(() => {
        if (isConsiderInput) {
            setIsInputValid(true)
        } else if (
            (isEmpty || minLengthError || maxLengthError || emailError || innError || fioUaError || nameUaError || 
                fioEnError || nameEnError || yearError || dateBirth18Error || dateStartError || dateVidError || 
                dateToError || numberAutoError || markAutoError || lengthError || digitError
            )
            // && !isConsiderInput
        ) {
            setIsInputValid(false)
        } else {
            setIsInputValid(true)
            // console.log('isInputValid: ' + isInputValid)
        }
    }, [
        isEmpty, minLengthError, maxLengthError, emailError, innError, fioUaError, nameUaError, fioEnError, nameEnError, yearError, 
        dateBirth18Error, dateStartError, dateVidError, dateToError, numberAutoError, markAutoError, lengthError, digitError,
        isConsiderInput
    ])

    // console.log('isInputValid: ' + isInputValid)

    return {
        isEmpty,
        isInputValid,
        fioUaError,
        nameUaError,
        fioEnError,
        nameEnError,
        dateBirth18Error,
        dateStartError,
        dateVidError,
        dateToError,
        minLengthError,
        maxLengthError,
        emailError,
        innError,
        edrpouError,
        numberAutoError,
        markAutoError,
        lengthError,
        digitError,
        errors
    }
}

export const useMyInput = (initialState, validations) => {
    const [value, setValue] = useState(initialState)
    const [isDirty, setIsDirty] = useState(false)
    const [isConsiderInput, setIsConsiderInput] = useState(false) // если true - не учитывается в валидации
    const valid = useMyValidation(value, validations, isConsiderInput, isDirty)

    // console.log('isConsiderInput: ' + isConsiderInput)
    const onChange = (e) => {
        setValue(e)
    }

    const onBlur = (e) => {
        setIsDirty(true)
    }

    const isConsider = (val) => {
        if (val) {
            setIsConsiderInput(true)
        } else {
            setIsConsiderInput(false)
        }
    }

    return {
        value, onChange, onBlur, isConsider, isDirty, ...valid
    }
} 