import { faCar, faCompass, faEarthAmericas, faListUl, faUmbrella, faUser, faPlane, faFlag, faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeActiveProductItem } from '../../store/menuSlice'
import PolicyProductsSceleton from './PolicyProductsSceleton'

const PolicyProducts = ({ products }) => {

    const activeProductItem = useSelector(state => state.menu.activeProductItem)
    const dispatch = useDispatch()

    const productIcons = {
		2 : faCar, // осаго
		1 : faCompass, // зеленая карта
		// {id: 3, ico: faEarthAmericas}, // Туризм
		3 : faUmbrella, // ДГО
		4 : faUser, // Иностранцы
		5 : faPlane, // Туризм
		6 : faHouse, // Майно
		7 : faFlag, // Польша
    }

    const changeProduct = (id) => {
		dispatch(changeActiveProductItem(id))
	}

  return (
    <>
        <div className='documents-menu-ico'>
            <Tooltip title='Всі страховки' >
                <div className={activeProductItem === 'all' ? 'active' : ''}>
                    <FontAwesomeIcon
                        onClick={() => changeProduct('all')}
                        icon={faListUl} 
                    />
                </div>
            </Tooltip>
            {
                products.length > 0 ? products.map(product => 
                    <Tooltip key={product.id} title={product.nameUa} >
                        <div className={activeProductItem === product.id ? 'active' : ''}>
                            <FontAwesomeIcon 
                                onClick={() => changeProduct(product.id)}
                                icon={productIcons[product.id] ? productIcons[product.id] : faListUl} 
                                // icon={productIcons.map(icon => console.log(icon))} 
                            />
                        </div>
                    </Tooltip>
                )
                : <PolicyProductsSceleton />
            }
        </div>
        <h3 className='pilicies__h3'>
            {
                activeProductItem === 'all' ? 
                    'Всі страховки' 
                :
                    products.map(product => product.id === activeProductItem ? product.nameUa : '')
            }
        </h3>
    </>
  )
}

export default PolicyProducts