import { FormControl, InputAdornment } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMyInput } from '../../hooks/ValidateForm'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MyInputForm from '../../components/UI/input/MyInputForm';
import PhoneCode from './PhoneCode';
import { useSelector } from 'react-redux';

const PhoneField = ({ setPhoneCode, phoneInput, phoneCodeInput, setPhone, setIsValid }) => {

    const user = useSelector(state => state.user)

    const [phoneLength, setPhoneLength] = useState(9)

    const phoneCode = useMyInput('380', {isEmpty: true})
    const phone = useMyInput('', {isEmpty: true})
    
    const [openCodes, setOpenCodes] = useState(false)
    const [correctLength, setCorrectLength] = useState(false)
    const [correctLengthText, setCorrectLengthText] = useState('')

    useEffect(() => {
        setPhoneCode(phoneCode.value)
        setPhone(phone.value)
    }, [phoneCode.value, phone.value])

    useEffect(() => {
        if (phoneCodeInput) {
            phoneCode.onChange(phoneCodeInput)
        }
        if (phoneInput) {
            phone.onChange(phoneInput)
        }
    }, [phoneInput, phoneCodeInput])

    useEffect(() => {
        if (user.phone) {
            phone.onChange(user.phone ? user.phone : '')
            phone.onBlur()
        }
    }, [user])

    useEffect(() => {
        if (phoneLength === phone.value.length) {
            setCorrectLength(true)
            setCorrectLengthText('')
        } else if (phoneLength < phone.value.length || phoneLength > phone.value.length) {
            setCorrectLength(false)
            setCorrectLengthText('Допустима к-сть цифр: ' + phoneLength)
        }
    }, [phoneLength, phone])

    useEffect(() => {
        if (
            phone.isInputValid && correctLength
        ) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }

    }, [phone])

    const getPhoneCode = () => {
        return (
            <InputAdornment
                sx={{cursor:'pointer'}} 
                position="start"
                onClick={() => setOpenCodes(!openCodes)}
            >
                +{phoneCode.value}
                <ArrowDropDownIcon />
            </InputAdornment>
        )
    }

  return (
    <FormControl fullWidth>
        <MyInputForm
            field={phone} 
            fieldError={'lengthError'} 
            isField={'length'} 
            // label={'Телефон'} 
            forciblyError={correctLength ? false : true}
            forciblyErrorText={correctLengthText}
            label={''} 
            placeholder={'501112233'}
            inputProps={{
                startAdornment: getPhoneCode(),
            }}
        />
        {
            openCodes ? 
            <PhoneCode
                code={phoneCode} 
                setOpenCodes={setOpenCodes} 
                setPhoneLength={setPhoneLength}
            /> : ''
        }
    </FormControl>
  )
}

export default PhoneField