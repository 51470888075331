import Header from "./components/Header";
import "./assets/css/main.css"
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { checkAPI } from "./api/userAPI";
import { setIsAuth, setUser } from "./store/userSlice";
import MySnakbar from "./components/UI/snackbar/MySnakbar";
import Footer from "./components/Footer";
import { getMenuItemsAPI, getMenuNameAPI } from "./api/menuAPI";

function App() {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isAuth = useSelector(state => state.user.isAuth)
	const user = useSelector(state => state.user.user)
	// const a = useUrl()
	// console.log('navigate', window.location.pathname)
	// const isAuth = false

	const [menu, setMenu] = useState([])
	const [menuItems, setMenuItems] = useState([])

	useEffect(() => {
		checkAPI()
		.then(data => {
			dispatch(setUser(data))
			dispatch(setIsAuth(true))
		})
		.catch(e => {
			// console.log('window.location.pathname', window.location.pathname)
			if (
				window.location.pathname !== '/register' &&
				window.location.pathname !== '/reset' &&
				window.location.pathname !== '/recovery' &&
				window.location.pathname !== '/activate-email'
			) {
				dispatch(setUser({}))
				dispatch(setIsAuth(false))
				navigate('/login')
			}
		})
		// .finally(() => setLoading(false))
		
		// if (window.localStorage.getItem('token')) {
		// 	dispatch(setIsAuth(true))
		// }

		getMenuNameAPI().then(data => {
			setMenu(data)
		})
		getMenuItemsAPI().then(data => {
			setMenuItems(data)
		})

   	}, [])

	useEffect(() => {
		// if(!window.localStorage.getItem('token') && !isAuth) {
		// 	navigate('/login')
		// }
	}, [isAuth])

	return (
		<div className="App">
			<Header />
			<MySnakbar />
    	 	{
				isAuth
					?
						user.role === 'ADMIN'
							?
							<Routes>
								{privateRoutes.map(route => 
									<Route path={route.path} element={route.element} key={route.path} />	
								)}
							</Routes>
							:
							<Routes>
								{privateRoutes.map(route => 
									<Route path={route.path} element={route.element} key={route.path} />	
								)}
							</Routes>
					: 
						<Routes>
							{publicRoutes.map(route => 
								<Route path={route.path} element={route.element} key={route.path}  />	
							)}
						</Routes>
			}
			<Footer 
				menu={menu}
				menuItems={menuItems}
			/>
		</div>
  	);
}

export default App;
