import React from 'react'
import LoginForm from '../components/login/LoginForm';
import RecoveryForm from '../components/login/RecoveryForm';

const Recovery = () => {
  return (
    <div className='login__page'>
        <RecoveryForm />
    </div>
  )
}

export default Recovery