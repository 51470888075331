import React from 'react'

const DocumentOneFullItem = ({ text, field }) => {
  return (
    <>
        {
            field ?
                <li className='documentOneFull'>
                    <p>{ text }</p>
                    <span>
                        { field }
                    </span>
                </li>
            : ''
        }
    </>
  )
}

export default DocumentOneFullItem