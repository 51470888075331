import React from 'react'
import CarsList from '../components/car/CarsList'
import Sidebar from '../components/Sidebar'

const Cars = () => {
  return (
    <div className='page'>
		<Sidebar />
        <div className='main'>
            <h1>Мої авто</h1>
            <div>
                <CarsList />
            </div>
        </div>
    </div>
  )
}

export default Cars