import React, { useEffect, useState } from 'react'
import { activateEmailUserAPI } from '../api/userAPI'
import { useSearchParams } from 'react-router-dom'
import MySpiner from '../components/UI/spiner/MySpiner'
import ActivateEmailSuccess from '../components/activateEmail/ActivateEmailSuccess'
import ActivateEmailError from '../components/activateEmail/ActivateEmailError'

const ActivateEmail = () => {

    const params = useSearchParams()
    const email = params[0].get('email')
    const link = params[0].get('link')

    const [isActive, setIsActive] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState({
        status: null,
        message: ''
    })

    useEffect(() => {
        if (email && link) {
            activateEmailUserAPI(email, link)
            .then(data => {
                console.log(data)
                setIsActive(true)
                setLoading(false)
            })
            .catch(e => {
                setError({
                    status: 404,
                    message: e.response.data.message
                })
                setLoading(false)
            })
        } else {
            window.location.href = '/404'
        }
    }, [])

  return (
    <div className="activateEmail">
        {
            loading ? <MySpiner /> : ''
        }
        {
            isActive && !loading ? 
                <ActivateEmailSuccess />
            : ''
        }
        {
            error.status === 404 ? <ActivateEmailError /> : ''
        }
    </div>
  )
}

export default ActivateEmail