import { $host, $authHost } from "./index"
import jwt_decode from "jwt-decode"

export const registerUserAPI = async (email, password, phoneCode, phone) => {
    const {data} = await $authHost.post('api/user/register', {email, password, phoneCode, phone})
    return data
}

export const loginAPI = async (email, password) => {
    const {data} = await $host.post('api/user/login', {email, password})
    localStorage.setItem('token', data)
    return jwt_decode(data)
}

export const activateEmailUserAPI = async (email, link) => {
    const {data} = await $host.post('api/user/activate_email', {email, link})
    return data
}

export const sentActivateEmailLaterUserAPI = async (email) => {
    const {data} = await $host.post('api/user/send_activate_email', {email})
    return data
}

export const resetUserPasswordAPI = async (email) => {
    const {data} = await $authHost.post('api/user/reset_password', {email})
    return data
}

export const checkKeyForRecoveryAPI = async (key) => {
    const {data} = await $authHost.post('api/user/check_key_for_recovery', {key})
    return data
}

export const recoveryPasswordAPI = async (key, password) => {
    const {data} = await $authHost.post('api/user/recovery_password', {key, password})
    return data
}

export const checkAPI = async () => {
    const {data} = await $authHost.get('api/user/auth')
    localStorage.setItem('token', data)
    return jwt_decode(data)
}

export const getUserAPI = async (id) => {
    const {data} = await $authHost.get('api/user/' + id)
    return data
}

export const editUserAPI = async (user) => {
    console.log(user)
    const {data} = await $authHost.post('api/user/' + user.id, user)
    return data
}

export const getPhoneCodesAPI = async (query) => {
    const {data} = await $authHost.post('api/directory/phone_codes', {query})
    return data
}