import React from 'react'

const ResetSuccess = () => {
  return (
    <div className='login__success-p'>
        <p>
            На Ваш email відправлено лист з інструкцією щодо відновлення паролю.<br />
            Слідуйте інструкціям в листі.
        </p>

    </div>
  )
}

export default ResetSuccess