import React, { useEffect, useState } from 'react'
import CitiesFilter from '../../filter/city/CitiesFilter'
import { getCityRegisterByNumberAPI } from '../../api/carAPI'
import { useSelector } from 'react-redux'
import MyModalLoading from '../UI/modal/MyModalLoading'

const CarCityField = ({ city }) => {

    const number = useSelector(state => state.car.getAuto.number)
    const isValidateNumber = useSelector(state => state.car.getAuto.isValidateNumber)
    const selectAuto = useSelector(state => state.car.getAuto.selectAuto)

    const [openModalLoading, setOpenModalLoading] = useState(false)

    useEffect(() => {
        if (number && isValidateNumber && selectAuto) {
            getCityRegister()
        }
    }, [number, isValidateNumber, selectAuto])

    const getCityRegister = () => {
        setOpenModalLoading(true)
        getCityRegisterByNumberAPI(number).then(data => {
            city.onChange(data)
            setOpenModalLoading(false)
        }).catch(e => {
            setOpenModalLoading(false)
        })
    }

  return (
    <>
        {
            openModalLoading ?
                <MyModalLoading
                    header={'Завантаження'}
                    text={'Триває завантаження даних по автомобілю'}
                    open={openModalLoading}
                    setOpen={setOpenModalLoading}
                />
            : ''
        }
        <CitiesFilter city={city} />
    </>
  )
}

export default CarCityField