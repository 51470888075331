import { Skeleton } from '@mui/material'
import React from 'react'

const PolicySceleton = () => {
  return (
    <div className='documents-listOne'>
        <div className='documents-listOne-head'>
            <div className='documents-listOne-headName'>
                KIRINS | 
                <span>
                    <Skeleton variant="text" width={50} />    
                </span>
            </div>
            <div>
                <Skeleton variant="text" width={70} />  
            </div>
        </div>
        <div className='documents-listOne-name'>
            <Skeleton variant="text" width={100} />  
            <Skeleton variant="text" width={100} />  
            
        </div>
        <div className='documents-listOne-link'>
            <Skeleton variant="text" width={70} />  
            <p>
                <Skeleton variant="text" width={70} />  
            </p>
        </div>
    </div>
  )
}

export default PolicySceleton