import React from 'react'
import { DOCUMENTS_TYPE_MAIN_FIELDS } from '../../locale/document/DocumentLocale'
import TextFieldValidate from '../form/filed/TextFieldValidate'

const DocumentField = ({ field, document, type, label, placeholder, isRecord, isValidRecord }) => {
  return (
    <>
        {
            document ? DOCUMENTS_TYPE_MAIN_FIELDS[document].map(doc => {
                if (doc === type) {
                    return (
                        <li key={doc} >
                            <p>{label}</p>
                            <span>
                                <TextFieldValidate
                                    field={field} 
                                    fieldError={'minLengthError'} 
                                    isField={'minLength'} 
                                    // label={label} 
                                    placeholder={placeholder}
                                    isRecord={isRecord}
                                    isValidRecord={isValidRecord}
                                />
                            </span>
                        </li>
                    )
                }
            }) : ''
        }
    </>
  )
}

export default DocumentField