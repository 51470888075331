import { CircularProgress } from '@mui/material'
import React from 'react'

const MySpiner = ({ text }) => {
  return (
    <div className='spiner'>
        <CircularProgress sx={{color: '#27AE60'}} />
        <p>{ text }</p>
    </div>
  )
}

export default MySpiner