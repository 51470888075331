import React from 'react'
import { useState } from 'react'

const PolicyPersons = ({ persons }) => {

    const getName = (person) => {
        if (person.name) {
            let text = ''
            if (person.surname) {
                text += person.surname
            }
            if (person.name) {
                text += ' ' + person.name
            }
            if (person.patronymic) {
                text += ' ' + person.patronymic
            }
            return text
        } else if (person.nameEng) {
            return person.nameEng + ' ' + person.surnameEng
        }
    }

  return (
    <>
        {
            persons && persons.length > 1 ?
                <div>
                    <h3>Застраховані</h3>
                    <ul>
                        {
                            persons.map(person => {
                                if (person.isSaller === false) {
                                    return (
                                        <li key={person.id}>
                                            <p>Застрахований №1</p>
                                            <span>
                                                {getName(person)}<br />
                                                {person.number}<br />
                                                {person.dateOfBirth}<br />
                                            </span>
                                        </li>
                                    )
                                }
                            })
                        }
                    </ul>
                </div>
            : ''
        }
    </>
  )
}

export default PolicyPersons