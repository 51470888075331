import React, { useEffect } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { IconButton, InputAdornment, Link, TextField } from '@mui/material'
import MyButton from '../UI/button/MyButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuth, setUser } from '../../store/userSlice';
import { useState } from 'react';
import { loginAPI, sentActivateEmailLaterUserAPI } from '../../api/userAPI';
import EmailFilter from '../../filter/email/EmailFilter';
import { useMyInput } from '../../hooks/ValidateForm';
import PasswordField from '../../filter/password/PasswordField';
import RegisterSuccess from './RegisterSuccess';

const LoginForm = ({page}) => {

    const isAuth = useSelector(state => state.user.isAuth)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const [email, setEmail] = useState('')
    const email = useMyInput('', {isEmpty: true, isEmail: true})
    const password = useMyInput('', {isEmpty: true, minLength: 5})
    // const [password, setPassword] = useState('')

    const [isValid, setIsValid] = useState(false)
    const [loadingRequest, setLoadingRequest] = useState(false)
    const [notActivateEmail, setNotActivateEmail] = useState(false)
    const [sendedAcivateEmail, setsendedAcivateEmail] = useState(false)
    const [error, setError] = useState({
        status: null,
        message: ''
    })

    useEffect(() => {
        if (isAuth) {
            navigate('profile')
        }
    }, [isAuth])

    useEffect(() => {
        if (
            email.isInputValid &&
            password.isInputValid
        ) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [email, password])

    const toPage = (link) => {
        navigate('/' + link)
    }

    const enter = async (e) => {
        e.preventDefault()
        setLoadingRequest(true)
        loginAPI(email.value, password.value).then(data => {
            dispatch(setUser(data))
            dispatch(setIsAuth(true))
            navigate('/profile')
            setLoadingRequest(false)
        }).catch(e => {
            console.log(e.response.data.message)
            setError({
                status: 404,
                message: e.response.data.message
            })
            setLoadingRequest(false)
            if (e.response.status === 403 && e.response.data.message === 'Email не підтверджено') {
                setNotActivateEmail(true)
            } else {
                setNotActivateEmail(false)
            }
        })
    }

    const sendEmailActiveLeter = (e) => {
        e.preventDefault()
        sentActivateEmailLaterUserAPI(email.value)
        .then(data => {
            console.log(data)
            setsendedAcivateEmail(true)
        })
        .catch(e => {
            console.log(e.response.data.message)
        })
    }

  return (
    <form className='login__form'>
        <div className={
            error.status === 404 ? 'login__error' : 'hide'
        }>
            { error.message }
        </div>
        {
            notActivateEmail && !sendedAcivateEmail ?
                <div>
                    <MyButton onClick={e => sendEmailActiveLeter(e)}>Надіслати лист підтвердження повторно</MyButton>
                </div>
            : ''
        }
        {
            !sendedAcivateEmail ?
                <>
                    <h3>Вхід</h3>
                    <EmailFilter 
                        email={email}
                    />

                    <PasswordField
                        password={password}
                    />

                    <MyButton 
                        classNameMy={'myBtn__green'}
                        type='submit'
                        onClick={enter}
                        disabled={!isValid || loadingRequest ? true : false}
                    >
                        Увійти
                    </MyButton>
                    <div className='login__link'>
                        <Link onClick={() => toPage('reset')}>Забули пароль?</Link>
                        <Link onClick={() => toPage('register')}>Реєстрація</Link>
                    </div>
                </>
            : <RegisterSuccess />
        }
        
        {/* <div className='login__social'>
            <div className='login__social-fb'>
                <FacebookIcon />
                <p>Увійти за допомогою Facebook</p>
            </div>
            <div className='login__social-google'>
                <GoogleIcon />
                <p>Увійти за допомогою Google</p>
            </div>
        </div> */}
    </form>
  )
}

export default LoginForm