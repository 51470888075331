import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ViewListIcon from '@mui/icons-material/ViewList';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import { changeActiveMenuItem } from '../store/menuSlice';

const Sidebar = () => {

	// const [activeItem, setActiveItem] = useState(null)
	const activeMenuItem = useSelector(state => state.menu.activeMenuItem)
	const user = useSelector(state => state.user.user)
	const dispatch = useDispatch()

	const menu = [
		{link: '/profile/insurance', icon: <ViewListIcon />, name: 'Мої страховки'},
		{link: '/profile/cars', icon: <DirectionsCarFilledIcon />, name: 'Мої авто'},
		{link: '/profile/documents', icon: <AssignmentIcon />, name: 'Мої документи'},
		// {link: '/profile/referal', icon: <MobileScreenShareIcon />, name: 'Рефералка'},
		{link: '/profile/personal-data', icon: <AssignmentIndIcon />, name: 'Профіль'},
	]

	const changeMenuItem = (item) => {
		dispatch(changeActiveMenuItem(item.link))
	}

  return (
    <div className='sidebar'>
		<ul>
			{menu.map((item) =>
				<li key={item.link} className={activeMenuItem === item.link ? 'active' : ''}>
					<Link
						onClick={() => changeMenuItem(item)} 
						to={item.link}
					>
						{item.icon}
						<span>{item.name}</span>
					</Link>
				</li>
			)}
		</ul>
		{/* <ul>
			<li className="active">
				<Link to='/profile/insurance'>
					<ViewListIcon />
					<span>Мої страховки</span>
				</Link>
			</li>
			<li>
				<Link to='/profile/cars'>
					<DirectionsCarFilledIcon />
					<span>Мої авто</span>
				</Link>
			</li>
			<li>
				<Link to='/profile/documents'>
					<AssignmentIcon />
					<span>Мої документи</span>
				</Link>
			</li>
			<li>
				<Link to='/profile/referal'>
					<MobileScreenShareIcon />
					<span>Рефералка</span>
				</Link>
			</li>
			<li>
				<Link to='/profile/personal-data'>
					<AssignmentIndIcon />
					<span>Профіль</span>
				</Link>
			</li>
		</ul> */}
    </div>
  )
}

export default Sidebar