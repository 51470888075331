import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const MyModal = ({ header, text, isOpen, setOpenModal, setIsAgry, textYes, textNo, onlyOk }) => {

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={e => setOpenModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          { header }
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                { text }   
            </DialogContentText>
        </DialogContent>
        <DialogActions>
			{
				onlyOk ?
					<button className="myBtn__green" autoFocus onClick={() => {
						setOpenModal(false)
					}}>
						Закрити
					</button>
				:
				<>
					<button className="myBtn__grey" onClick={() => {
						setIsAgry(false)
						setOpenModal(false)
					}}>
						{textNo ? textNo : 'Не треба'}
					</button>
					<button className="myBtn__green" autoFocus onClick={() => {
						setIsAgry(true)
						setOpenModal(false)
					}}>
						{textYes ? textYes : 'Отримати'}
					</button>
				</>
			}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MyModal