import React from 'react'
import { Skeleton } from '@mui/material';

const CarSceleton = () => {

  return (
    <div className='carsOne'>
        <div className='documents-listOne-headName'>
            KIRINS | <span>car</span>
        </div>
        <ul className='carsOne__list'>
            <li>
                <p>Державний номер</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>Марка/модель</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>VIN-код</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>Об'єм двигуна</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>Рік випуску</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            {/* <li>
                <p>Місце прописки власника</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li> */}
        </ul>
    </div>
  )
}

export default CarSceleton