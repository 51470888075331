import React from 'react'
import MyButton from '../UI/button/MyButton'
import { useNavigate } from 'react-router-dom'

const ActivateEmailSuccess = () => {

	const navigate = useNavigate()

  return (
    <div>
        <p>Email успішно підтверджено!</p>
		<MyButton onClick={() => navigate('/login')} >Вхід</MyButton>
    </div>
  )
}

export default ActivateEmailSuccess