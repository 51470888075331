import { FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCarAPI, editCarAPI } from '../../api/carAPI'
import AutoMarkFilter from '../../filter/auto/AutoMarkFilter'
import AutoModelFilter from '../../filter/auto/AutoModelFilter'
import { useMyInput } from '../../hooks/ValidateForm'
import { autoNames, autoTypeNames, autoTypes } from '../../locale/car/CarLocale'
import { setAlertOpen, setAlertText, setAlertType } from '../../store/alertSlice'
import { addCar, editCar, setAutoDataNumber } from '../../store/carSlice'
import MyButton from '../UI/button/MyButton'
import MyYearPicker from '../UI/datePicker/MyYearPicker'
import { useVinAutoValidate } from '../../hooks/ValidateVinAuto'
import { useNavigate } from 'react-router-dom'
import { setIsAuth } from '../../store/userSlice'
import AutoNumberFilter from '../../filter/auto/AutoNumberFilter'
import CarCityField from './CarCityField'

const CarFrom = ({ typeForm, car, setCarEditId, setIsAdd }) => {

    const isValidateNumber = useSelector(state => state.car.getAuto.isValidateNumber)
    const selectAuto = useSelector(state => state.car.getAuto.selectAuto)
    const isGetData = useSelector(state => state.car.getAuto.isGetData)
    const listAuto = useSelector(state => state.car.getAuto.list)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const locale = 'uk'

    const markTemp = car ? {
        id: car.markId, 
        // ewaId: car.markEwaId, 
        cdbMtibuCode: car.cdbMtibuCode,
        name: car.mark,
    } : {name: ''}

    const modelTemp = car ? {
        id: car.modelId, 
        // ewaId: car.modelEwaId, 
        name: car.model,
    } : {name: ''}

    // console.log(car)

    const autoName = useMyInput('', {isEmpty: true})
    const autoType = useMyInput('', {isEmpty: true})
    const mark = useMyInput(markTemp, {isEmpty: true, isMarkAuto: true, isMinMark: 2})
    const model = useMyInput(modelTemp, {isEmpty: true, isMarkAuto: true, isMinMark: 1})
    const year = useMyInput(car ? car.year : '', {isEmpty: true, minLength: 4})
    const number = useMyInput(car ? car.number : '', {isEmpty: true, minLength: 1, maxLength: 15})
    const vin = useMyInput(car ? car.vin : '', {isEmpty: true, minLength: 4})
    const volumeEngine = useMyInput(car && car.volumeEngine ? car.volumeEngine : '', {isEmpty: true, minLength: 2, maxLength: 10, isDigit: true})
    const city = useMyInput(car ? car.city : '', {isEmpty: true})

    // const isNumberValidate = useNumberAutoValidate(number.value)
    const isVinValidate = useVinAutoValidate(vin.value)

    const [isValidForm, setIsValidForm] = useState(false)
    // const [openModal, setOpenModal] = useState(false)
    // const [isAgry, setIsAgry] = useState(false)
    // const [numberTemp, setNumberTemp] = useState('')
    const [autoNameList, setAutoNameList] = useState('')
    const [autoTypeList, setAutoTypeList] = useState('')

    // console.log(autoType.value)

    // const [carTemp, setCarTemp] = useState({})

    useEffect(() => {
        insertSelect(autoNames[locale], setAutoNameList)
        if(car) {
            year.onChange(moment(car.year, 'YYYY')._d)
            autoName.onChange(car.autoName)
            autoType.onChange(car.autoType)
            // console.log(autoType.value)
        }
    }, [])

    useEffect(() => {
        insertSelect(autoTypes[locale][autoName.value], setAutoTypeList)
    }, [autoName.value])

    useEffect(() => {
        if (selectAuto) {
            setDataAutoField()
        }
    }, [selectAuto])

    useEffect(() => {
        if (isGetData && listAuto.length === 0) {
            resetDataAutoField()
        }
    }, [isGetData, listAuto])

    useEffect(() => {
        if (
            mark.isInputValid && model.isInputValid && year.isInputValid && number.isInputValid &&
            vin.isInputValid && autoName.isInputValid && autoType.isInputValid &&
            city.isInputValid && isValidateNumber && isVinValidate.isValidVin 
            && (autoName.value === 'bike' || autoName.value === 'auto' ? volumeEngine.isInputValid : true)
        ) {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    }, [mark.isInputValid, model.isInputValid, year.isInputValid, number.isInputValid, vin.isInputValid, 
        autoName.isInputValid, autoName.value, autoType.isInputValid, volumeEngine.isInputValid, city.isInputValid, 
        isValidateNumber, isVinValidate.isValidVin])

    const setDataAutoField = () => {

        mark.onChange(selectAuto.mark)
        mark.onBlur()
        model.onChange(selectAuto.model)
        model.onBlur()
        vin.onChange(selectAuto.vin)
        vin.onBlur()
        autoName.onChange(selectAuto.type)
        autoName.onBlur()
        autoType.onChange(selectAuto.category)
        autoType.onBlur()
        volumeEngine.onChange(selectAuto.engineVolume)
        volumeEngine.onBlur()
        if (selectAuto.year) {
            year.onChange(moment().year(selectAuto.year)._d)
            year.onBlur()
        } else {
            year.onChange('')
            year.onBlur()
        }
    }

    const resetDataAutoField = () => { // Сброс данных авто если не нашлось
        mark.onChange({id: '', name: '', cdbMtibuCode: ''})
        model.onChange({id: '', name: ''})
        vin.onChange('')
        year.onChange('')
        autoName.onChange('')
        autoType.onChange('')
        volumeEngine.onChange('')
    }

    const insertSelect = (localeArr, setSelect, sort) => {
        let arr = []
		for (const item in localeArr) {
            if (sort && sort === 'text') {
                arr.push({value: localeArr[item], text: item})
            } else {
                arr.push({value: item, text: localeArr[item]})
            }
        }

        setSelect(arr)
    }

    const save = () => {
        const auto = setData()
        // console.log(auto)
        if (typeForm === 'add') {
            addCarAPI(auto).then(data => {
                dispatch(addCar(data))
    
                dispatch(setAlertText('Автомобіль успішно додано'))
                dispatch(setAlertType('success'))
                dispatch(setAlertOpen(true))
    
                setIsAdd(false)
                dispatch(setAutoDataNumber(''))
            }).catch(e => {
                if (e.response.status === 401) {
                    dispatch(setIsAuth(false))
                    navigate('/login')
                }
                dispatch(setAlertText(e.response.data.message))
                dispatch(setAlertType('error'))
                dispatch(setAlertOpen(true))
            })
        } else if (typeForm === 'edit') {
            // console.log(auto)
            editCarAPI(auto).then(data => {
                dispatch(editCar(auto))
    
                dispatch(setAlertText('Дані автомобіля успішно змінено'))
                dispatch(setAlertType('success'))
                dispatch(setAlertOpen(true))
    
                setCarEditId(null)
                dispatch(setAutoDataNumber(''))
            }).catch(e => {
                if (e.response.status === 401) {
                    dispatch(setIsAuth(false))
                    navigate('/login')
                }
                dispatch(setAlertText(e.response.data.message))
                dispatch(setAlertType('error'))
                dispatch(setAlertOpen(true))
            })
        }

        setTimeout(() => {
            dispatch(setAlertOpen(false))
        }, 3000);
    }

    const setData = () => {
        // console.log(mark.value)
        // console.log(model.value)
        let auto = {
            autoName: autoName.value, 
            autoType: autoType.value, 
            year: moment(year.value).format('YYYY'), 
            number: number.value, 
            vin: vin.value, 
            volumeEngine: volumeEngine.value, 
            mark: mark.value.name, 
            markId: mark.value.id,
            // markEwaId: mark.value.ewaId,
            model: model.value.name,
            modelId: model.value.id,
            // modelEwaId: model.value.ewaId,
            cityId: city.value.id,
            // cityEwaId: city.value.ewaId,
            cdbMtibuCode: mark.value.cdbMtibuCode
        }

        if (typeForm === 'edit') {
            auto = {...auto, id: car.id}
        }
        
        // console.log(auto)
        return auto
        // setCarTemp(auto)
    }

  return (
    <div>
        <div className='carsOne'>
            <div className='documents-listOne-headName'>
                KIRINS | <span>car</span>
            </div>
            <ul className='carsOne__list carsOne__list-form'>
                <li>
                    <p>Державний номер</p>
                    <span>
                        <AutoNumberFilter 
                            number={number}
                        />
                    </span>
                </li>
                <li>
                    <p>Тип авто</p>
                    <span>
                        <FormControl sx={{ m: 1, minWidth: 150, mt: 3 }}>
                            <Select
                                disabled={number.value ? false : true}
                                value={autoName.value}
                                variant="standard"
                                onChange={e => {
                                    autoName.onChange(e.target.value)
                                    autoType.onChange('')
                                }}
                                onClose={e => autoName.onBlur(e)}
                                error={autoName.isDirty && autoName.isEmpty ? true : false}
                                color={autoName.isDirty && autoName.isEmpty ? 'error' : 'success'} // чтобы был успешный цвет
                            >
                                {
                                    autoNameList ? autoNameList.map(one =>
                                        <MenuItem key={one.value} value={one.value}>
                                            {one.text}
                                        </MenuItem>
                                    ) : ''
                                }
                            </Select>
                            <FormHelperText>
                                {autoName.isDirty && autoName.errors.isEmpty ? autoName.errors.isEmpty : ''}
                            </FormHelperText>
                        </FormControl>
                    </span>
                </li>
                <li>
                    <p>{autoName.value ? autoTypeNames[locale][autoName.value] : ' Обʼєм двигуна'}</p>
                    <span>
                        <FormControl sx={{ m: 1, minWidth: 150, mt: 3 }}>
                            <Select
                                disabled={autoName.value ? false : true}
                                value={autoType.value}
                                variant="standard"
                                // defaultValue={autoType.value}
                                onChange={e => autoType.onChange(e.target.value)}
                                onClose={e => autoType.onBlur(e)}
                                error={autoType.isDirty && autoType.isEmpty ? true : false}
                                color={autoType.isDirty && autoType.isEmpty ? 'error' : 'success'} // чтобы был успешный цвет
                            >
                                {
                                    autoTypeList ? autoTypeList.map(one =>
                                        <MenuItem key={one.value} value={one.value}>
                                            {one.text}
                                        </MenuItem>
                                    ) : ''
                                }
                            </Select>
                            <FormHelperText>
                                {autoType.isDirty && autoType.errors.isEmpty ? autoType.errors.isEmpty : ''}
                            </FormHelperText>
                        </FormControl>
                    </span>
                </li>
                <li>
                    <p>Марка</p>
                    <span>
                        <AutoMarkFilter
                            disabled={autoName.value ? false : true}
                            mark={mark} 
                        />
                    </span>
                </li>
                <li>
                    <p>Модель</p>
                    <span>
                        <AutoModelFilter
                            mark={mark}
                            model={model}
                            typeAuto={autoName.value}
                            disabled={autoName.value && mark.value.name ? false : true}
                        />
                    </span>
                </li>
                <li>
                    <p>VIN-код</p>
                    <span>
                        <TextField
                            variant='standard'
                            placeholder='WVWZZZ00011122233'
                            size="small"
                            onChange={e => vin.onChange(e.target.value.toUpperCase())} 
                            onBlur={e => vin.onBlur(e)} 
                            value={vin.value}
                            error={vin.isDirty && (vin.minLengthError || !isVinValidate.isValidVin) ? true : false} // чтобы был текст ошибки
                            color={vin.isDirty && (vin.minLengthError || !isVinValidate.isValidVin) ? 'error' : 'success'} // чтобы был успешный цвет
                            helperText={
                                vin.isDirty && vin.errors.minLength ? vin.errors.minLength :
                                vin.isDirty && !isVinValidate.isValidVin ? isVinValidate.textError : ''
                            } //Текст ошибки
                            focused={vin.isDirty ? true : false}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </span>
                </li>
                {
                    autoName.value === 'bike' || autoName.value === 'auto' ?
                        <li>
                            <p>
                                Об'єм двигуна ({autoType.value === 'B5' ? 'kw' : 'см3'})
                            </p>
                            <span>
                                <TextField
                                    variant='standard'
                                    placeholder='1998'
                                    size="small"
                                    onChange={e => volumeEngine.onChange(e.target.value)} 
                                    onBlur={e => volumeEngine.onBlur(e)} 
                                    value={volumeEngine.value}
                                    error={
                                        volumeEngine.isDirty && 
                                        (volumeEngine.minLengthError || volumeEngine.maxLengthError || volumeEngine.digitError) ? true : false
                                    } // чтобы был текст ошибки
                                    color={
                                        volumeEngine.isDirty && 
                                        (volumeEngine.minLengthError || volumeEngine.maxLengthError || volumeEngine.digitError) ? 'error' : 'success'
                                    } // чтобы был успешный цвет
                                    helperText={
                                        volumeEngine.isDirty && volumeEngine.errors.minLength ? volumeEngine.errors.minLength :
                                        volumeEngine.isDirty && volumeEngine.errors.maxLength ? volumeEngine.errors.maxLength :
                                        volumeEngine.isDirty && volumeEngine.errors.isDigit ? volumeEngine.errors.isDigit : ''
                                    } //Текст ошибки
                                    focused={volumeEngine.isDirty ? true : false}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </span>
                        </li>
                    : ''
                }
                <li>
                    <p>Рік випуску</p>
                    <span>
                        <MyYearPicker
                            // labelText='Год выпуска'
                            value={year.value}
                            // value={dateStart.value}
                            onBlur={e => year.onBlur(e)}
                            // onChange={date => console.log(date)}
                            onChange={date => year.onChange(date)}
                            isError={year.minLengthError}
                            isDirty={year.isDirty}
                            error={year.errors.minLength ? year.errors.minLength : ''}
                            // maxDateType='today'
                        />
                    </span>
                </li>
                <li>
                    <p>Місце прописки власника</p>
                    <span>
                        <CarCityField city={city} />
                    </span>
                </li>
            </ul>
            <div className='carsOne__save'>
                <MyButton 
                    onClick={save}
                    disabled={isValidForm ? false : true}
                >
                    Зберегти
                </MyButton>
                <MyButton 
                    onClick={() => {
                        if (typeForm === 'add') {
                            setIsAdd(false)
                        } else {
                            setCarEditId(null)
                        }
                        dispatch(setAutoDataNumber(''))
                    }}
                    classNameMy={'myBtn__grey'} 
                >
                    Відмінити
                </MyButton>
            </div>
        </div>
    </div>
  )
}

export default CarFrom