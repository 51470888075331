import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getPolicyByUserAPI } from '../api/policyAPI'
import PolicyInfo from '../components/policy/PolicyInfo'
import Sidebar from '../components/Sidebar'
import { useDispatch } from 'react-redux'
import { setIsAuth } from '../store/userSlice'

const PolicyOne = () => {

	let { id } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [policy, setPolicy] = useState({})

	useEffect(() => {
		getPolicyByUserAPI(id)
    .then(data => {
		setPolicy(data)
		// console.log(data)
	})
    .catch(e => {
		if (e.response.status === 401) {
			dispatch(setIsAuth(false))
			navigate('/login')
		}
  	})
	}, [])

  return (
    <div className='page'>
		<Sidebar />
        <div className='main'>
            <h1>Перегляд страхового поліса</h1>
            <div>
                <PolicyInfo policy={policy} />
            </div>
        </div>
    </div>
  )
}

export default PolicyOne