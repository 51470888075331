import moment from 'moment'

export const getDateOnly = (date) => {
    // console.log(date)
    // console.log(moment(date).utc().set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0}))
    // console.log(moment(date).utc().format('YYYY-MM-DD'))
    // console.log(moment(date).parseZone())
    // console.log(date)
    let dateTemp = null
    if(date) {
        // dateTemp = moment(date).utc().format('YYYY-MM-DD')
        // dateTemp = moment(date).set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0}).format('YYYY-MM-DD')
        dateTemp = moment(date).format('YYYY-MM-DD')
        // console.log(dateTemp)
    }
    return dateTemp
}

export const getDateOnlyUa = (date) => {
    let dateTemp = null
    if(date) {
        dateTemp = moment(date).format('DD.MM.YYYY')
    }
    return dateTemp
}