import React, { useEffect, useState } from 'react'
import ProfileView from '../components/profile/ProfileView'
import Sidebar from '../components/Sidebar'
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import ProfileEdit from '../components/profile/ProfileEdit';
import { getUserAPI } from '../api/userAPI';
import ProfileSceleton from '../components/profile/ProfileSceleton';

const Profile = () => {

    const user = useSelector(state => state.user.user)

    const [edit, setEdit] = useState(false)
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getUserAPI(user.id).then(data => {
            setUserData(data)
            setLoading(false)
        })
    }, [])

    // console.log(edit)

  return (
    <div className='page'>
		<Sidebar />
        <div className='main'>
            <h1>Мій профіль</h1>
            <div className='personal-bg'>
                <div className='personal-header'>
                    <div className='documents-listOne-headName'>
                        KIRINS | <span>профіль</span>
                    </div>
                    <div 
                        className='carsOne__edit'
                        onClick={e => setEdit(!edit)}
                    >
                        {!edit ?
                            <>
                                <EditIcon />
                                <p>Редагувати</p>
                            </>
                            :
                            ''
                        }
                    </div>
                </div>
                {
                    loading ?
                        <ProfileSceleton />
                    :
                        <>
                            {!edit ?
                                <ProfileView user={user} userData={userData} />
                                :
                                <ProfileEdit user={user} userData={userData} setEdit={setEdit} />
                            }
                        </>
                }
            </div>
        </div>
    </div>
  )
}

export default Profile