import React, { useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import MyButton from '../UI/button/MyButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Tooltip } from '@mui/material';
import PersonForm from '../person/PersonForm';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import DocumentForm from './DocumentForm';
import DocumentOne from './DocumentOne';

const DocumentsList = ({ documents, hasDocuments, personId }) => {

    // const people = useSelector(state => state.person.people)
    // const [documents, setDocuments] = useState([])
    const [docEditId, setDocEditId] = useState(null)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        // getPeopleAPI().then(data => {
        //     console.log(data)
        //     dispatch(setPeople(data))
        //     setLoading(false)
        // }).catch(e => {
        //     // console.error(e)
        // })
    }, [])

  return (
    <>
        {
            documents && documents.length > 0 ? 
                documents.map(doc => {
                    if (doc.id === docEditId) {
                        return <DocumentForm 
                            key={doc.id}
                            typeForm='edit' 
                            document={doc}
                            docEditId={docEditId}
                            setDocEditId={setDocEditId}
                            hasDocuments={hasDocuments}
                            personId={personId}
                        />
                    } else {
                        return <DocumentOne 
                            key={doc.id}
                            document={doc} 
                            setDocEditId={setDocEditId} 
                            personId={personId}
                        />
                    }
                })
            : 
                <li>
                    <p>Документи</p>
                    <span>не додано</span>
                </li>
        }
        {/* {
            !isAddPerson ?
                <MyButton onClick={e => setIdAddPerson(true)} >
                    <AddCircleOutlineIcon />
                    Додати людину
                </MyButton>
            :
                <PersonForm
                    typeForm='add'
                    setIdAddPerson={setIdAddPerson}
                />
        } */}
    </>
  )
}

export default DocumentsList