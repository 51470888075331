import React from 'react'

const RecoverySuccess = () => {
  return (
    <div className='login__success-p'>
        <p>
            Ваш пароль успішно змінено.<br />
            Тепер Ви можете увійти до особистого кабінету з новим паролем.
        </p>
    </div>
  )
}

export default RecoverySuccess