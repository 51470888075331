import React from 'react'
import moment from 'moment'

const PolicyGeneral = ({ policy, insuranceCompany, product }) => {
  return (
    <div className='policy_infoLeft'>
        <h3>Загальні дані</h3>
        <ul>
            <li>
                <p>Продукт</p>
                <span>
                    { product ? product.nameUa : ''}
                </span>
            </li>
            {
                product && product.id === 4 ? '' :
                <li>
                    <p>СК</p>
                    <span>
                        { insuranceCompany ? insuranceCompany.nameUa : '' }
                    </span>
                </li>
            }
            {
                product && product.id === 4 ? '' :
                <li>
                    <p>Номер полісу</p>
                    <span>
                        { policy && policy.number ? policy.number : 'Не вказано'}
                    </span>
                </li>
            }
            {
                policy && policy.franshiza ?
                    <li>
                        <p>Франшиза</p>
                        <span>{policy.franshiza} грн</span>
                    </li>
                : ''
            }
            
            <li>
                <p>Дата оформлення</p>
                <span>
                    { policy ? moment(policy.createdAt).format('DD.MM.YYYY') : ''}
                </span>
            </li>
            <li>
                <p>Дата початку</p>
                <span>
                    { policy ? moment(policy.dateStart).format('DD.MM.YYYY') : ''}
                </span>
            </li>
            <li>
                <p>Дата закінчення</p>
                <span>
                    { policy ? moment(policy.dateEnd).format('DD.MM.YYYY') : ''}
                </span>
            </li>
            {
                policy && policy.strahSum ?
                    <li>
                        <p>Страхова сума</p>
                        <span>{policy.strahSum} грн</span>
                    </li>
                : ''
            }
            <li>
                <p>Ціна повна</p>
                <span>{ policy ? policy.priceFull : 0 } грн</span>
            </li>
            {
                policy && policy.priceSale ?
                    <li>
                        <p>Ціна зі знижкою</p>
                        <span>{policy.priceSale} грн</span>
                    </li>
                : ''
            }
        </ul>
    </div>
  )
}

export default PolicyGeneral