import React from 'react'
import MenuHeader from './menu/MenuHeader'
import logo from '../assets/img/logo_kirins.svg'
import calendarIco from '../assets/img/calendar-ico.svg'
import emailIco from '../assets/img/email-ico.svg'
import liqPayIco from '../assets/img/liqpay-ico.svg'
import mastercardIco from '../assets/img/mastercard.svg'
import phoneIco from '../assets/img/phone-ico.svg'
import visaIco from '../assets/img/visa.svg'
import MenuList from './menu/MenuList'
import MessangersList from './contacts/MessangersList'

const Footer = ({ menu, menuItems }) => {
    // console.log('menuItems', menuItems)

    const menuAutoId = 3
    const menuTourId = 4
    const menuLifeId = 5
    const menuAboutId = 6

  return (
    <footer className="footer" itemScope itemType="https://schema.org/WPFooter">
        <div className="container-fluid container-lg">
            <div className="row">
                <div className="footer__wrap">
                    <div className="footer__menu">
                        <div className="footer__menu-left">
                            <div className="footer__menu-leftOne">
                                <p className="footer__menu-header">
									<MenuHeader menu={menu} menuId={menuAutoId} />
                                </p>
								<ul>
									<MenuList menuItems={menuItems} menuId={menuAutoId} />
								</ul>
                            </div>

                            <div className="footer__menu-leftOne">
                                <p className="footer__menu-header">
									<MenuHeader menu={menu} menuId={menuTourId} />
								</p>
                                <ul>
									<MenuList menuItems={menuItems} menuId={menuTourId} />
								</ul>
                            </div>

                            <div className="footer__menu-leftOne">
                                <p className="footer__menu-header">
									<MenuHeader menu={menu} menuId={menuLifeId} />
								</p>
                                <ul>
									<MenuList menuItems={menuItems} menuId={menuLifeId} />
								</ul>
                            </div>
                        </div>
                        <div className="footer__menu-right">
                            <div className="footer__menu-rightOne">
                                <p className="footer__menu-header">
									<MenuHeader menu={menu} menuId={menuAboutId}  />
								</p>
                                <ul>
									<MenuList menuItems={menuItems} menuId={menuAboutId}  />
								</ul>
                            </div>
                            <div className="footer__menu-rightOne">
                                <p className="footer__menu-header">Ми приймаємо</p>
                                <div className="footer__menu-rightOne-payments">
                                    <img src={visaIco} alt="Логотип KIRINS" width={64} height='auto' />
                                    <img src={mastercardIco} alt="Логотип KIRINS" width={50} height='auto' />
                                    <img src={liqPayIco} alt="Логотип KIRINS" width={110} height='auto' />
                                    {/* <Image src="/assets/img/visa.svg" width="64" height="50" alt="logo Visa" />
                                    <Image src="/assets/img/mastercard.svg" width="50" height="50" alt="logo Mastercard" />
                                    <Image src="/assets/img/liqpay-ico.svg" width="110" height="50" alt="logo LiqPay" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer__info">
                        {/* <Image loading="lazy" className="footer__info-logo" src="/assets/img/logo_kirins.svg" width={176} height={110} alt="Логотип KIRINS" /> */}
                        {/* <Image loading="lazy" className="footer__info-logo" src="/assets/img/kirins_logo_ua.svg" width={176} height={110} alt="Логотип KIRINS" /> */}
                        <img src={logo} className='footer__info-logo' alt="Логотип KIRINS" width={176} height='auto' />
                        <address className="footer__info-contacts">
                            <p>
                                {/* <Image src="/assets/img/phone-ico.svg" alt="телефон Kirins" width={24} height={24} /> */}
                                <img src={phoneIco} alt="Логотип KIRINS" />
                                <a href="tel:380637778363">
                                    +38 (063) 777 83 63
                                </a>
                            </p>
                            <p>
                                {/* <Image src="/assets/img/email-ico.svg" alt="Email Kirins" width={24} height={24} /> */}
                                <img src={emailIco} alt="Логотип KIRINS" />
                                <a href="mailto:insurance@kirins.com.ua">
                                    insurance@kirins.com.ua
                                </a>
                            </p>
                            <p>
                                {/* <Image src={'/assets/img/calendar-ico.svg'} alt="Время работы Kirins" width={24} height={24} /> */}
                                <img src={calendarIco} alt="Логотип KIRINS" />
                                <span>Сайт: 24/7</span>
                            </p>
                            <p>
                                {/* <Image src={'/assets/img/calendar-ico.svg'} alt="Время работы Kirins" width={24} height={24} /> */}
                                <img src={calendarIco} alt="Логотип KIRINS" />
                                <span>Підтримка 9:00 - 18:00</span>
                            </p>
                        </address>
                            <MessangersList />
                    </div>
                </div>
                <p className="footer__copyright">
                    &copy; {' '}
                    <span itemProp="copyrightYear">
                        {new Date().getFullYear()}
                    </span> {' '}
                    Всі права захищено
                </p>
            </div>
        </div>
    </footer>
  )
}

export default Footer