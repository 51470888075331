import { createSlice } from "@reduxjs/toolkit"

const personSlice = createSlice({
    name: 'person',
    initialState: {
        people: [],
    },
    reducers: {
        setPeople(state, action) {
            state.people = action.payload
        },
        addPerson(state, action) {
            state.people.unshift(action.payload)
        },
        deletePerson(state, action) {
            state.people = state.people.filter(person => person.id !== action.payload)
        },
        editPerson(state, action) {
            state.people = state.people.map(person => {
                if (person.id === action.payload.id)
                    return action.payload
                return person
            })
        },
        addPersonDocument(state, action) {
            state.people = state.people.map(person => {
                if (person.id === action.payload.personId) {
                    if (person.documents) {
                        person.documents.push(action.payload)
                    } else {
                        person.documents = []
                        person.documents.push(action.payload)
                    }
                }
                return person
            })
        },
        editPersonDocument(state, action) {
            state.people = state.people.map(person => {
                if (person.documents.length > 0) {
                    person.documents = person.documents.map(doc => {
                        if (doc.id === action.payload.id && doc.personId === person.id) {
                            return action.payload
                        }
                        return doc
                    })
                }
                return person
            })
        },
        deletePersonDocument(state, action) {
            state.people = state.people.map(person => {
                if (person.documents.length > 0) {
                    person.documents = person.documents.filter(doc => doc.id !== action.payload)
                }
                return person
            })
        },
    }
})

export default personSlice.reducer

export const {setPeople,
                addPerson,
                deletePerson,
                editPerson,
                addPersonDocument,
                editPersonDocument,
                deletePersonDocument,
                
            } = personSlice.actions