import { TextField } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useEffect } from 'react'
import { useState } from 'react'
import { useMyInput } from '../../hooks/ValidateForm'
import { useInnValidation } from '../../hooks/ValidateInn'
import TextFieldValidate from '../form/filed/TextFieldValidate'
import MyButton from '../UI/button/MyButton'
import MyDatePicker from '../UI/datePicker/MyDatePicker'
import { setAlertOpen, setAlertText, setAlertType } from '../../store/alertSlice'
import { addPersonAPI, editPersonAPI } from '../../api/personAPI'
import { addPerson, editPerson } from '../../store/personSlice'
import CitiesFilter from '../../filter/city/CitiesFilter'
import { getDateOnly } from '../../helpers/date/getDateFormat'
import { setIsAuth } from '../../store/userSlice'
import { useNavigate } from 'react-router-dom'
import InnField from '../../filter/inn/InnField'

const PersonForm = ({ typeForm, person, setIdAddPerson, personEditId, setPersonEditId }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const inn = useMyInput(person ? person.inn : '', {isEmpty: true, isInn: true})
    const dateOfBirth = useMyInput('', {isEmpty: true}) // 17.01.1969
    const name = useMyInput(person ? person.name : '', {isEmpty: true, isFioUa: true})
    const surname = useMyInput(person ? person.surname : '', {isEmpty: true, isFioUa: true})
    const patronymic = useMyInput(person ? person.patronymic : '', {isEmpty: true, isFioUa: true})
    const nameEng = useMyInput(person ? person.nameEng : '', {isEmpty: true, isFioEn: true})
    const surnameEng = useMyInput(person ? person.surnameEng : '', {isEmpty: true, isFioEn: true})
    const city = useMyInput(person ? person.city : '', {isEmpty: true})
    const adress = useMyInput(person ? person.adress : '', {isEmpty: true, minLength: 7})
    const build = useMyInput(person ? person.build : '', {isEmpty: true, minLength: 1})
    const flat = useMyInput(person ? person.flat : '', {})

    // const validateInn = useInnValidation(inn.value)

    const [confirmInnAndDate, setConfirmInnAndDate] = useState(true)
    // const [confirmInnAndDateText, setConfirmInnAndDateText] = useState('')
    const [isValid, setIsValid] = useState(false)

    // useEffect(() => {
    //     checkDataInn()
    // }, [dateOfBirth, inn])

    useEffect(() => {
        if(person) {
            dateOfBirth.onChange(moment(person.dateOfBirth)._d)
        }
    }, [])


    useEffect(() => {
        if (
            inn.isInputValid &&
            dateOfBirth.isInputValid &&
            name.isInputValid &&
            surname.isInputValid &&
            patronymic.isInputValid &&
            nameEng.isInputValid &&
            surnameEng.isInputValid &&
            city.isInputValid &&
            adress.isInputValid &&
            build.isInputValid &&
            // flat.isInputValid &&
            confirmInnAndDate
        ) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [
        inn, dateOfBirth, name, surname, patronymic, nameEng, surnameEng, 
        city, adress, build,
        confirmInnAndDate
    ])

    // const checkDataInn = () => { // Проверка инн и введенной даты рождения
    //     if (dateOfBirth.isInputValid && inn.isInputValid) {
    //         if (validateInn.checkLastDigit) {
    //             if(moment(dateOfBirth.value).format('DD.MM.YYYY') === moment(validateInn.dateOfBirth).format('DD.MM.YYYY')) {
    //                 setConfirmInnAndDate(true)
    //                 setConfirmInnAndDateText('')
    //             } else {
    //                 setConfirmInnAndDate(false)
    //                 setConfirmInnAndDateText('Дата народження не відповідає ІПН')
    //             }
    //         } else {
    //             setConfirmInnAndDate(false)
    //             setConfirmInnAndDateText('Код ІПН має помилку')
    //         }
            
    //     }
    // }

    const save = () => {
        const personTemp = setData()
        console.log(personTemp)
        if (typeForm === 'add') {
            addPersonAPI(personTemp).then(data => {
                dispatch(addPerson(data))
    
                dispatch(setAlertText('Людину успішно додано'))
                dispatch(setAlertType('success'))
                dispatch(setAlertOpen(true))
    
                setIdAddPerson(false)
            }).catch(e => {
                if (e.response.status === 401) {
                    dispatch(setIsAuth(false))
                    navigate('/login')
                }
                dispatch(setAlertText(e.response.data.message))
                dispatch(setAlertType('error'))
                dispatch(setAlertOpen(true))
            })
        } else if (typeForm === 'edit') {
            editPersonAPI(personTemp).then(data => {
                dispatch(editPerson(data))
    
                dispatch(setAlertText('Дані людини успішно змінено'))
                dispatch(setAlertType('success'))
                dispatch(setAlertOpen(true))
    
                setPersonEditId(null)
            }).catch(e => {
                if (e.response.status === 401) {
                    dispatch(setIsAuth(false))
                    navigate('/login')
                }
                dispatch(setAlertText(e.response.data.message))
                dispatch(setAlertType('error'))
                dispatch(setAlertOpen(true))
            })
        }

        setTimeout(() => {
            dispatch(setAlertOpen(false))
        }, 3000);
    }

    const setData = () => {
        // console.log(mark.value)
        // console.log(moment(dateOfBirth.value).format('DD.MM.YYYY'))
        let personTemp = {
            inn: inn.value, 
            // dateOfBirth: moment(dateOfBirth.value).format('YYYY-MM-DD'), 
            // dateOfBirth: moment().format('YYYY-MM-DD'), 
            // dateOfBirth: '', 
            dateOfBirth: getDateOnly(dateOfBirth.value), 
            name: name.value, 
            surname: surname.value, 
            patronymic: patronymic.value, 
            nameEng: nameEng.value, 
            surnameEng: surnameEng.value, 
            cityId: city.value.id, 
            cityEwaId: city.value.ewaId, 
            adress: adress.value, 
            build: build.value, 
            flat: flat.value, 
        }

        if (typeForm === 'edit') {
            personTemp = {...personTemp, id: person.id}
        }
        
        // console.log(auto)
        return personTemp
        // setCarTemp(auto)
    }

  return (
    <div className='personOne'>
        <div className='documents-listOne-headName'>
            KIRINS | <span>людина</span>
        </div>
        <div className='personOne__center'>
            <div>
                <ul className='personOne__list'>
                    <li>
                        <p>Код ІПН</p>
                        <span>
                            <InnField 
                                inn={inn}
                                dateOfBirth={dateOfBirth}
                                setConfirm={setConfirmInnAndDate}
                            />
                            {/* <TextField
                                variant='standard'
                                placeholder='1111111111'
                                size="small"
                                onChange={e => {
                                    inn.onChange(e.target.value)
                                }} 
                                onBlur={e => inn.onBlur(e)} 
                                value={inn.value}
                                error={
                                    inn.isDirty && 
                                    (inn.innError || !confirmInnAndDate || !validateInn.checkLastDigit) ? true : false
                                } // чтобы был текст ошибки
                                color={
                                    inn.isDirty && 
                                    (inn.innError || !confirmInnAndDate || !validateInn.checkLastDigit) ? 'error' : 'success'
                                } // чтобы был успешный цвет
                                helperText={
                                    inn.isDirty && inn.errors.isInn ? inn.errors.isInn : 
                                    inn.isDirty && !confirmInnAndDate ? confirmInnAndDateText :
                                    inn.isDirty && !validateInn.checkLastDigit ? 'Код ІПН не вірний' : ''
                                } //Текст ошибки
                                focused={inn.isDirty ? true : false}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            /> */}
                        </span>
                    </li>
                    <li>
                        <p>Дата народження</p>
                        <span>
                            <MyDatePicker
                                labelText='Дата рождения'
                                value={dateOfBirth.value}
                                onBlur={e => dateOfBirth.onBlur(e)}
                                onChange={date => dateOfBirth.onChange(date)}
                                // onChange={date => console.log(date)}
                                isError={dateOfBirth.dateBirth18Error}
                                isDirty={dateOfBirth.isDirty}
                                error={dateOfBirth.errors.isDateBirth18 ? dateOfBirth.errors.isDateBirth18 : ''}
                                maxDateType={'today'}
                                isDisable={false}
                            />
                        </span>
                    </li>
                    <li>
                        <p>Прізвище (українською)</p>
                        <span>
                            <TextFieldValidate
                                field={surname} 
                                fieldError={'fioUaError'} 
                                isField={'isFioUa'} 
                                placeholder={'Іванов'}
                            />
                        </span>
                    </li>
                    <li>
                        <p>Імʼя (українською)</p>
                        <span>
                            <TextFieldValidate
                                field={name} 
                                fieldError={'fioUaError'} 
                                isField={'isFioUa'} 
                                placeholder={'Іван'}
                            />
                        </span>
                    </li>
                    <li>
                        <p>Побатькові (українською)</p>
                        <span>
                            <TextFieldValidate
                                field={patronymic} 
                                fieldError={'fioUaError'} 
                                isField={'isFioUa'} 
                                placeholder={'Іванович'}
                            />
                        </span>
                    </li>
                    <li>
                        <p>Прізвище (англійською)</p>
                        <span>
                            <TextFieldValidate
                                field={surnameEng} 
                                fieldError={'fioEnError'} 
                                isField={'isFioEn'} 
                                placeholder={'IVANOV'}
                            />
                        </span>
                    </li>
                    <li>
                        <p>Імʼя (англійською)</p>
                        <span>
                            <TextFieldValidate
                                field={nameEng} 
                                fieldError={'fioEnError'} 
                                isField={'isFioEn'} 
                                placeholder={'IVAN'}
                            />
                        </span>
                    </li>
                    <li>
                        <p>Містро прописки</p>
                        <span>
                            <CitiesFilter city={city} />
                        </span>
                    </li>
                    <li>
                        <p>Вулиця</p>
                        <span>
                            <TextFieldValidate
                                field={adress} 
                                fieldError={'minLengthError'} 
                                isField={'minLength'} 
                                placeholder={'вул. Нова'}
                            />
                        </span>
                    </li>
                    <li>
                        <p>Будинок</p>
                        <span>
                            <TextFieldValidate
                                field={build} 
                                fieldError={'minLengthError'} 
                                isField={'minLength'} 
                                placeholder={'12'}
                            />
                        </span>
                    </li>
                    <li>
                        <p>Квартира</p>
                        <span>
                            <TextFieldValidate
                                field={flat} 
                                // fieldError={''} 
                                // isField={''} 
                                placeholder={'199'}
                            />
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div className='personOne__buttons-form'>
            <MyButton
                disabled={isValid ? false : true}
                onClick={save}
            >
                {/* <AddCircleOutlineIcon /> */}
                {
                    typeForm === 'add' ? 'Додати людину' : ' Редагувати людину'
                }
            </MyButton>
            <MyButton 
                classNameMy='myBtn__grey'
                onClick={e => {
                    if (typeForm === 'add') {
                        setIdAddPerson(false)
                    } else {
                        setPersonEditId(null)
                    }
                }}
            >
                {/* <AddCircleOutlineIcon /> */}
                Відмінити
            </MyButton>
        </div>
    </div>
  )
}

export default PersonForm