import React from 'react'
import moment from 'moment'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, FormControl, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { editUserAPI, getUserAPI } from '../../api/userAPI';
import { useEffect } from 'react';
import MyButton from '../UI/button/MyButton';
import ProfileSceleton from './ProfileSceleton';
import { useMyInput } from '../../hooks/ValidateForm';
import PhoneCode from '../../filter/phoneCode/PhoneCode';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useInnValidation } from '../../hooks/ValidateInn';
import InnField from '../../filter/inn/InnField';
import MyDatePicker from '../UI/datePicker/MyDatePicker';
import TextFieldValidate from '../form/filed/TextFieldValidate';
import PhoneField from '../../filter/phoneCode/PhoneField';
import { getDateOnly } from '../../helpers/date/getDateFormat';

const ProfileEdit = ({user, setEdit, userData}) => {

    const inn = useMyInput(user && user.inn ? user.inn : '', {isEmpty: true, isInn: true})
    const dateOfBirth = useMyInput('', {isEmpty: true}) // 17.01.1969
    const name = useMyInput(user && user.name ? user.name : '', {isEmpty: true, isFioUa: true})
    const surname = useMyInput(user && user.surname ? user.surname : '', {isEmpty: true, isFioUa: true})
    const patronymic = useMyInput(user && user.patronymic ? user.patronymic : '', {isEmpty: true, isFioUa: true})
    // const adress = useMyInput(user && user.surname ? user.surname : '', {isEmpty: true, minLength: 10})
    // const build = useMyInput(person ? person.build : '', {isEmpty: true, minLength: 1})
    // const flat = useMyInput(person ? person.flat : '', {})

    const [confirmInnAndDate, setConfirmInnAndDate] = useState(true)
    const [isValid, setIsValid] = useState(false)

    // const [userData, setUserData] = useState({})
    // const [loading, setLoading] = useState(true)
    // const [phoneLength, setPhoneLength] = useState(9)
    // const [openCodes, setOpenCodes] = useState(false)

    // const phoneCode = useMyInput('380', {isEmpty: true})

    const [phoneCode, setPhoneCode] = useState(user.phoneCode ? user.phoneCode : '')
	const [phone, setPhone] = useState(user.phone ? user.phone : '')
    const [isValidPhone, setIsValidPhone] = useState(false)

    useEffect(() => {
        if(userData) {
            if (userData.dateOfBirth) {
                dateOfBirth.onChange(moment(userData.dateOfBirth, 'YYYY-MM-DD')._d)
            }
            if (userData.inn) {
                inn.onChange(userData.inn)
            }
            if (userData.surname) {
                surname.onChange(userData.surname)
            }
            if (userData.name) {
                name.onChange(userData.name)
            }
            if (userData.patronymic) {
                patronymic.onChange(userData.patronymic)
            }
        }
    }, [userData])

    // useEffect(() => {
    //     getUserAPI(user.id).then(data => {
    //         setUserData(data)
    //         setLoading(false)
    //     })
    // }, [])

    useEffect(() => {
        if (
            inn.isInputValid &&
            dateOfBirth.isInputValid &&
            name.isInputValid &&
            surname.isInputValid &&
            patronymic.isInputValid &&
            isValidPhone &&
            // city.isInputValid &&
            // adress.isInputValid &&
            // build.isInputValid &&
            // flat.isInputValid &&
            confirmInnAndDate
        ) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [
        inn, dateOfBirth, name, surname, patronymic, isValidPhone, confirmInnAndDate
    ])

    const edit = () => {
        const userDataTemp = setData()
        editUserAPI(userDataTemp).then(data => {
            setEdit(false)
        }).catch(e => {
            console.error(e)
        })
    }

    const setData = () => {
        let personTemp = {
            id: user.id,
            inn: inn.value, 
            dateOfBirth: getDateOnly(dateOfBirth.value), 
            name: name.value, 
            surname: surname.value, 
            patronymic: patronymic.value, 
            phoneCode: phoneCode, 
            phone: phone, 
        }
        
        return personTemp
    }

    // const getPhoneCode = () => {
    //     return (
    //         <InputAdornment
    //             sx={{cursor:'pointer'}} 
    //             position="start"
    //             onClick={() => setOpenCodes(!openCodes)}
    //         >
    //             +{phoneCode.value}
    //             <ArrowDropDownIcon />
    //         </InputAdornment>
    //     )
    // }

  return (
    <div>
        <ul className='personal__list'>
            <li>
                <p>Email</p>
                {/* <span>{userData.email}</span> */}
                <span>
                    {userData.email}
                </span>
            </li>
            <li>
                <p>Телефон</p>
                <span>
                    <PhoneField
                        setPhoneCode={setPhoneCode}
                        setPhone={setPhone}
                        phoneCodeInput={userData.phoneCode}
                        phoneInput={userData.phone}
                        setIsValid={setIsValidPhone}
                    />
                    {/* <FormControl fullWidth>
                        <TextField
                            type="text" 
                            id="phone" 
                            label="" 
                            variant="standard"
                            value={userData.phone}
                            onChange={e => setUserData({...userData, phone: e.target.value})}
                            InputProps={{
                                startAdornment: getPhoneCode(),
                            }}
                        />
                        {
                            openCodes ? 
                            <PhoneCode 
                                code={phoneCode} 
                                setOpenCodes={setOpenCodes} 
                                setPhoneLength={setPhoneLength}
                            /> : ''
                        }
                    </FormControl> */}
                </span>
            </li>
            <li>
                <p>ІПН</p>
                <span>
                    <InnField
                        inn={inn}
                        dateOfBirth={dateOfBirth}
                        setConfirm={setConfirmInnAndDate}
                    />
                    {/* <TextField
                        type="text" 
                        id="inn" 
                        label="" 
                        variant="standard"
                        value={userData.inn}
                        onChange={e => setUserData({...userData, inn: e.target.value})}
                    /> */}
                </span>
            </li>
            <li>
                <p>Прізвище</p>
                <span>
                    <TextFieldValidate
                        field={surname} 
                        fieldError={'fioUaError'} 
                        isField={'isFioUa'} 
                        placeholder={'Іванов'}
                    />
                </span>
            </li>
            <li>
                <p>Ім'я</p>
                <span>
                    <TextFieldValidate
                        field={name} 
                        fieldError={'fioUaError'} 
                        isField={'isFioUa'} 
                        placeholder={'Іван'}
                    />
                </span>
            </li>
            <li>
                <p>Побатькові</p>
                <span>
                    <TextFieldValidate
                        field={patronymic} 
                        fieldError={'fioUaError'} 
                        isField={'isFioUa'} 
                        placeholder={'Іванович'}
                    />
                </span>
            </li>
            <li>
                <p>Дата народження</p>
                <span>
                    <div>
                        <MyDatePicker
                            labelText='Дата рождения'
                            value={dateOfBirth.value}
                            onBlur={e => dateOfBirth.onBlur(e)}
                            onChange={date => dateOfBirth.onChange(date)}
                            // onChange={date => console.log(date)}
                            isError={dateOfBirth.dateBirth18Error}
                            isDirty={dateOfBirth.isDirty}
                            error={dateOfBirth.errors.isDateBirth18 ? dateOfBirth.errors.isDateBirth18 : ''}
                            maxDateType={'today'}
                            isDisable={false}
                        />
                    </div>
                </span>
            </li>
            
            {/* <li>
                <p>Фото профілю</p>
                <span>
                    <AccountCircleIcon 
                        sx={{ 
                            width: 64, 
                            height: 64,
                            // color: '#393017'
                        }} 
                    />
                    <Button>Змінити фото</Button>
                </span>
            </li> */}
        </ul>
        <div className='saveButton'>
            <MyButton 
                classNameMy={'myBtn__green'}
                onClick={edit}
                disabled={isValid ? false : true}
            >Зберегти</MyButton>
            <MyButton onClick={() => setEdit(false)} classNameMy={'myBtn__grey'}>Відмінити</MyButton>
        </div>

    </div>
  )
}

export default ProfileEdit