import { $authHost } from "./index"

export const addDocumentAPI = async (document) => {
    const {data} = await $authHost.post('api/document/', document)
    return data
}

export const editDocumentAPI = async (document) => {
    const {data} = await $authHost.post(`api/document/${document.id}`, document)
    return data
}

export const deleteDocumentAPI = async (document) => {
    const {data} = await $authHost.delete(`api/document/${document.id}/${document.personId}`)
    return data
}
