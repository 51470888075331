import React from 'react'
import { Skeleton } from '@mui/material';

const ProfileSceleton = () => {

  return (
    <div>
        <ul className='personal__list'>
            <li>
                <p>Email</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>Телефон</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>ІПН</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>Прізвище</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>Ім'я</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>Побатькові</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            <li>
                <p>Дата народження</p>
                <span><Skeleton variant="text" width={250} /></span>
            </li>
            {/* <li>
                <p>Фото профілю</p>
                <span>
                    <Skeleton variant="circular" width={40} height={40} />
                </span>
            </li> */}
        </ul>
    </div>
  )
}

export default ProfileSceleton