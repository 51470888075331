import Cars from '../pages/Cars'
import Documents from '../pages/Documents'
import Policies from '../pages/Policies'
import PolicyOne from '../pages/PolicyOne'
import Profile from '../pages/Profile'
import Referal from '../pages/Referal'
import Login from '../pages/Login'
import Register from '../pages/Register'
import { Navigate } from 'react-router-dom'
import GreenCard from '../pages/GreenCard'
import Reset from '../pages/Reset'
import Recovery from '../pages/Recovery'
import ActivateEmail from '../pages/ActivateEmail'

export const privateRoutes = [
    // {path: '/', element: <Policies /> },
    {path: '/', element: <Navigate to='http://localhost:3000/' />},
    {path: '/login', element: <Login />},
    {path: '/register', element: <Register />},
    {path: '/reset', element: <Reset />},
    {path: '/recovery', element: <Recovery />},
    {path: '/activate-email', element: <ActivateEmail />},
    {path: '/profile', element: <Policies /> },
    {path: '/profile/insurance', element: <Policies /> },
    {path: '/profile/insurance/:id', element: <PolicyOne /> },
    {path: '/profile/personal-data', element: <Profile /> },
    {path: '/profile/cars', element: <Cars /> },
    {path: '/profile/documents', element: <Documents /> },
    {path: '/profile/referal', element: <Referal /> },
    {path: '*', element: <Navigate to='/profile' />}
]

export const publicRoutes = [
    {path: '/', element: <Navigate to='http://localhost:3000/' />},
    {path: '/login', element: <Login />},
    {path: '/register', element: <Register />},
    {path: '/reset', element: <Reset />},
    {path: '/recovery', element: <Recovery />},
    {path: '/activate-email', element: <ActivateEmail />},
    // {path: '*', element: <Navigate to='/login' />}
]
