import { createSlice } from "@reduxjs/toolkit"

const alertSlice = createSlice({
    name: 'error',
    initialState: {
        email: {
            isValid: true,
            textHeader: '',
            textBody: '',
            isConfirmOne: false,
            isCanOffer: true,
            isDoRequest: false,
        }
    },
    reducers: {
        setErrorEmailIsValid(state, action) {
            state.email.isValid = action.payload
        },
        setErrorEmailTextHeader(state, action) {
            state.email.textHeader = action.payload
        },
        setErrorEmailTextBody(state, action) {
            state.email.textBody = action.payload
        },
        setErrorEmailIsConfirmOne(state, action) {
            state.email.isConfirmOne = action.payload
        },
        setErrorEmailIsCanOffer(state, action) {
            state.email.isCanOffer = action.payload
        },
        setErrorEmailIsDoRequest(state, action) {
            state.email.isDoRequest = action.payload
        },
    }
})

export default alertSlice.reducer

export const {
    setErrorEmailIsValid,
    setErrorEmailTextHeader,
    setErrorEmailTextBody,
    setErrorEmailIsConfirmOne,
    setErrorEmailIsCanOffer,
    setErrorEmailIsDoRequest,
} = alertSlice.actions