import React from 'react'

const MessangersList = ({ className }) => {
  return (
    <div className={className ? className : 'footer__info-messengers'}>
        <a href="https://telegram.im/@kirins_bot" target="_blank">
            <img src="/assets/img/telegram.svg" alt="telegram" width={34} height={34} />
        </a>
        <a href="viber://pa?chatURI=kirins" target="_blank">
            <img src="/assets/img/viber.svg" alt="viber" width={34} height={34} />
        </a>
        <a href="https://www.facebook.com/Kirins.ua/" target="_blank">
            <img src="/assets/img/facebook.svg" alt="facebook" width={34} height={34} />
        </a>
        <a href="https://instagram.com/kirins.ua" target="_blank">
            <img src="/assets/img/instagram.svg" alt="instagram" width={34} height={34} />
        </a>
        <a href="https://www.youtube.com/@KIRINS_ua" target="_blank">
            <img src="/assets/img/youtube.svg" alt="youtube" width={34} height={34} />
        </a>
    </div>
  )
}

export default MessangersList