import React from 'react'
import DocumentOneFullItem from './DocumentOneFullItem'
import moment from 'moment'

const DocumentOneFull = ({ document }) => {
  return (
    <>
        <DocumentOneFullItem
            text='Серія'
            field={document.series}
        />
        <DocumentOneFullItem
            text='Номер'
            field={document.number}
        />
        <DocumentOneFullItem
            text='Запис'
            field={document.record}
        />
        <DocumentOneFullItem
            text='Дата видачі'
            field={moment(document.dateVid).format('DD.MM.YYYY')}
        />
        {
            document.dateTo ? 
                <DocumentOneFullItem
                    text='Дійсне до'
                    field={moment(document.dateTo).format('DD.MM.YYYY')}
                />
            : ''
        }
        <DocumentOneFullItem
            text='Ким видано'
            field={document.whoVid}
        />
    </>
  )
}

export default DocumentOneFull