import React from 'react'
import { TextField } from '@mui/material'

const TextFieldValidate = ({ field, fieldError, isField, label, placeholder, isRecord, isValidRecord }) => {

	const isRecordError = isRecord ? 
		isValidRecord === false ? true : false
		: false
  return (
    <TextField
        variant='standard'
        label={label}
        placeholder={placeholder}
        size="small"
        onChange={e => field.onChange(e.target.value)} 
        onBlur={e => field.onBlur(e)} 
        value={field.value}
        error={
			field.isDirty && 
			(field[fieldError] || isRecordError) ? true : false
		} // чтобы был текст ошибки
        color={
            field.isDirty && (field[fieldError] || isRecordError) ? 'error' : 'success'
            // inn.isDirty && !inn.innError ? 'success' : ''
        } // чтобы был успешный цвет
        helperText={
			field.isDirty && field.errors[isField] ? field.errors[isField] :
			field.isDirty && isRecordError ? 'Зпис має помилку' : ''
		} //Текст ошибки
        focused={field.isDirty ? true : false}
        InputLabelProps={{
            shrink: true
        }}
    />
  )
}

export default TextFieldValidate