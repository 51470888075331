import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useInnValidation } from '../../hooks/ValidateInn'

const InnField = ({ inn, dateOfBirth, setConfirm }) => { // inn, dateOfBirth - useInput

    const validateInn = useInnValidation(inn.value)

    console.log('validateInn', validateInn)

    const [confirmInnAndDate, setConfirmInnAndDate] = useState(true)
    const [confirmInnAndDateText, setConfirmInnAndDateText] = useState('')

    useEffect(() => {
        checkDataInn()
    }, [dateOfBirth, inn])

    useEffect(() => {
        if (confirmInnAndDate && validateInn.checkLastDigit) {
            setConfirm(true)
        } else {
            setConfirm(false)
        }
    }, [confirmInnAndDate, validateInn])

    const checkDataInn = () => { // Проверка инн и введенной даты рождения
        if (dateOfBirth.isInputValid && inn.isInputValid) {
            if (validateInn.checkLastDigit) {
                if(moment(dateOfBirth.value).format('DD.MM.YYYY') === moment(validateInn.dateOfBirth).format('DD.MM.YYYY')) {
                    setConfirmInnAndDate(true)
                    setConfirmInnAndDateText('')
                } else {
                    setConfirmInnAndDate(false)
                    setConfirmInnAndDateText('Дата народження не відповідає ІПН')
                }
            } else {
                setConfirmInnAndDate(false)
                setConfirmInnAndDateText('Код ІПН має помилку')
            }
            
        }
    }

  return (
    <TextField
        variant='standard'
        placeholder='1111111111'
        size="small"
        onChange={e => {
            inn.onChange(e.target.value)
        }} 
        onBlur={e => inn.onBlur(e)} 
        value={inn.value}
        error={
            inn.isDirty && 
            (inn.innError || !confirmInnAndDate || !validateInn.checkLastDigit) ? true : false
        } // чтобы был текст ошибки
        color={
            inn.isDirty && 
            (inn.innError || !confirmInnAndDate || !validateInn.checkLastDigit) ? 'error' : 'success'
        } // чтобы был успешный цвет
        helperText={
            inn.isDirty && inn.errors.isInn ? inn.errors.isInn : 
            inn.isDirty && !confirmInnAndDate ? confirmInnAndDateText :
            inn.isDirty && !validateInn.checkLastDigit ? 'Код ІПН не вірний' : ''
        } //Текст ошибки
        focused={inn.isDirty ? true : false}
        InputLabelProps={{
            shrink: true
        }}
    />
  )
}

export default InnField