import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPeopleAPI } from '../../api/personAPI';
import { setPeople } from '../../store/personSlice';
import MyButton from '../UI/button/MyButton';
import PersonForm from './PersonForm';
import PersonOne from './PersonOne';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { setIsAuth } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';
import PersonSceleton from './PersonSceleton';

const PersonList = () => {

    const [isAddPerson, setIdAddPerson] = useState(false)

    const people = useSelector(state => state.person.people)
    // const [isAdd, setIsAdd] = useState(false)
    const [personEditId, setPersonEditId] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        getPeopleAPI()
        .then(data => {
            dispatch(setPeople(data))
            setLoading(false)
        })
        .catch(e => {
            setLoading(false)
            if (e.response.status === 401) {
                dispatch(setIsAuth(false))
                navigate('/login')
            }
        })
    }, [])
    
  return (
    <div>
        {
            loading ?
                <PersonSceleton />
            :
                <>
                    {
                        !isAddPerson ?
                            <MyButton 
                                classNameMy='addBtn__logzone myBtn__green'
                                onClick={e => setIdAddPerson(true)} 
                            >
                                <AddCircleOutlineIcon />
                                Додати людину
                            </MyButton>
                        :
                            <PersonForm
                                typeForm='add'
                                setIdAddPerson={setIdAddPerson}
                            />
                    }
                    {
                        people && people.length > 0 ? 
                            people.map(person => {
                                if (person.id === personEditId) {
                                    return <PersonForm 
                                        key={person.id}
                                        typeForm='edit' 
                                        person={person}
                                        personEditId={personEditId}
                                        setPersonEditId={setPersonEditId}
                                    />
                                } else {
                                    return <PersonOne 
                                        key={person.id}
                                        person={person} 
                                        setPersonEditId={setPersonEditId} 
                                    />
                                }
                            })
                        : 
                        // !loading && people.length === 0 ?
                            <div className='notFound__img-block'>
                                <img src="/img/profile/people_not_found.png" alt="людей не знайдено" />
                            </div>
                    // :
                    }
                </>
        }
    </div>
  )
}

export default PersonList