import { Tooltip } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getProductNameById } from '../../helpers/getProductNameById'
import { getPolicyColor } from '../../helpers/policy/PolicyTime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ReportIcon from '@mui/icons-material/Report';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { getNameByPolicy } from '../../helpers/policy/PolicyHelper'

const PolicyItem = ({ policy, products }) => {

    const timeEnd = getPolicyColor(policy.dateEnd) // 0, 1, -1

  return (
    <div className='documents-listOne'>
        <div className='documents-listOne-head'>
            <div className='documents-listOne-headName'>
                KIRINS | 
                <span>
                    {/* {' ' + getProductNameById(policy.productId)} */}
                    {' '}
                    {products.length > 0 && products.map(product => product.id === policy.productId ? product.nameUa : '')}
                </span>
            </div>
            <div>
                <Tooltip title={policy.sk ? policy.sk.nameUa : ''}>
                    <div 
                        className={
                            'documents-listOne-headCompany' +
                            (
                                policy.status !== 'EMITTED' ? ' kirins_bg_brown' : ' kirins_bg_green'
                            )
                        }
                    >
                        {policy.sk ? policy.sk.nameUa : 'KIRINS'}
                    </div>
                </Tooltip>
            </div>
        </div>
        <div className='documents-listOne-name'>
            {
                // policy.autoNumber ?
                policy.auto ?
                    <p className='documents-listOne-numberFio'>{policy.auto.number.toUpperCase()}</p>
                :
                    <p className='documents-listOne-numberFio'>{getNameByPolicy({ customer: policy.customer, company: policy.company })}</p>
                    // <p className='documents-listOne-numberFio'>{policy.fio}</p>
            }
            {
                // policy.autoMarkAndModel ?
                policy.auto ?
                    <p>{policy.auto.mark.toUpperCase()} {policy.auto.model.toUpperCase()}</p>
                : ''
            }
            
        </div>
        <div className='documents-listOne-link'>
            <Link to={`/profile/insurance/${policy.id}`}>Детальніше</Link>
            <p>
                {
                    policy.status === 'EMITTED' ?
                        <span>до {moment(policy.dateEnd).format('DD.MM.YYYY')}</span>
                    : ''
                }
                <span
                    className={
                        (
                            timeEnd === -1 ? ' kirins_red' :
                            timeEnd === 0 ? ' kirins_yellow' : ' kirins_green'
                        )
                    }
                >
                    {
                        policy.status === 'EMITTED' && timeEnd === -1 ?
                            <Tooltip title='Страховка закінчила свою дію' >
                                <span className='kirins_red'>
                                    <NotificationImportantIcon />
                                </span>
                            </Tooltip>
                        : policy.status === 'EMITTED' && timeEnd === 0 ?
                            <Tooltip title='Дія страховки підходить до кінця' >
                                <span className='kirins_yellow'>
                                    <NotificationImportantIcon />
                                </span>
                            </Tooltip>
                        : ''
                    }
                </span>
            </p>
        </div>
    </div>
  )
}

export default PolicyItem