import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordField = ({ password }) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

  return (
    <TextField
        label={'Пароль'}
        placeholder={'Введіть пароль'}
        size="small"
        variant='standard'
        type={showPassword ? 'text' : 'password'}
        onChange={e => password.onChange(e.target.value)} 
        onBlur={e => password.onBlur(e)} 
        value={password.value}
        error={password.isDirty && password.minLengthError ? true : false} // чтобы был текст ошибки
        color={
            password.isDirty && password.minLengthError ? 'error' : 'success'
            // inn.isDirty && !inn.innError ? 'success' : ''
        } // чтобы был успешный цвет
        helperText={password.isDirty && password.errors.minLength ? password.errors.minLength : ''} //Текст ошибки
        focused={password.isDirty ? true : false}
        InputLabelProps={{
            shrink: true
        }}
        InputProps={{
            endAdornment: 
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>,
            // type:"password",
            autoComplete: 'password'
        }}
    />
  )
}

export default PasswordField