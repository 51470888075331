export const useValidateRecord = (record) => { // влидация ID карты
    if (record) {
        const recordTemp = record.replace(/ /g, '')
        const reg = /^\d{8}-\d{5}$/ // 11111111 (8 цифр)- 11111 (5 цифр)
    
        if (recordTemp.length !== 14) {
            return false
        }
    
        if (reg.test(recordTemp)) {
            return true
        }
    }

    return false
}