export const DOCUMENTS_TYPE_MAIN = { // Основные типы документов
    'PASSPORT': { // паспорт
        'ru': 'Паспорт',
        'uk': 'Паспорт',
        'en': 'Passport',
    },
    'ID_PASSPORT': { // ID-паспорт
        'ru': 'ID-карта',
        'uk': 'ID-картка',
        'en': 'ID-card',
    },
    'DRIVING_LICENSE': { // водительское удостоверение
        'ru': 'Водительское удостоверение',
        'uk': 'Посвідчення водія',
        'en': 'Driving licence',
    },
    'EXTERNAL_PASSPORT': { // загран паспорт
        'ru': 'Загран паспорт',
        'uk': 'Закордонний паспорт',
        'en': 'Abroad passport',
    },
    'FOREIGN_PASSPORT': { // иностранный паспорт
        'ru': 'Иностранный паспорт',
        'uk': 'Іноземний паспорт',
        'en': 'Foreign passport',
    },
    'RESIDENCE_PERMIT': { // вид на жительство
        'ru': 'Вид на жительство',
        'uk': 'Вид на проживання',
        'en': 'Residence',
    },
    'ID_RESIDENCE_PERMIT': { // ID-вид на жительство
        'ru': 'ID-вид на жительство',
        'uk': 'ID-Вид на проживання',
        'en': 'ID-Residence',
    },
    'REGISTRATION_CARD': { // регистрационный талон
        'ru': 'Регистрационный талон',
        'uk': 'Реєстраційний талон',
        'en': 'Registration card',
    },
    'BIRTH_CERTIFICATE': { // свидетельство о рождении
        'ru': 'Свидетельство о рождении',
        'uk': 'Свідоцтво про народження',
        'en': 'Birth certificate',
    },
    'PENSION_CERTIFICATE': { // пенсионное удостоверение
        'ru': 'Пенсионное удостоверение',
        'uk': 'Пенсійне посвідчення',
        'en': 'Пенсионное удостоверение',
    },
    'E_PENSION_CERTIFICATE': { // электронное пенсионное удостоверение
        'ru': 'Электронное пенсионное удостоверение',
        'uk': 'Електронне пенсійне посвідчення',
        'en': '',
    },
    'DISABILITY_CERTIFICATE': { // удостоверение об инвалидности
        'ru': 'Удостоверение об инвалидности',
        'uk': 'Посвідчення про інвалідність',
        'en': '',
    },
    'VETERAN_CERTIFICATE': { // удостоверение участника войны
        'ru': 'Удостоверение участника войны',
        'uk': 'Посвідчення учасника війни',
        'en': '',
    },
    'CHERNOBYL_CERTIFICATE': { // чернобыльское удостоверение
        'ru': 'Чернобыльское удостоверение',
        'uk': 'Чорнобильське посвідчення',
        'en': '',
    }
}

export const DOCUMENTS_TYPE_ARRAY = [
    'PASSPORT', 
    'ID_PASSPORT', 
    'DRIVING_LICENSE', 
    'EXTERNAL_PASSPORT', 
    'BIRTH_CERTIFICATE',
    'PENSION_CERTIFICATE',
    'E_PENSION_CERTIFICATE',
    'DISABILITY_CERTIFICATE',
    'VETERAN_CERTIFICATE',
    'CHERNOBYL_CERTIFICATE',
    'FOREIGN_PASSPORT', 
    'RESIDENCE_PERMIT',
    'ID_RESIDENCE_PERMIT',
]

// series - серия
// number - номер
// record - запись
// issuedBy - кем выдано
// dateFrom - дата выдачи
// dateTo - до когда действует
export const DOCUMENTS_TYPE_MAIN_FIELDS = { // какие поля есть у документа
    'PASSPORT': ['series', 'number', 'issuedBy', 'dateFrom'],
    'ID_PASSPORT': ['record', 'number', 'issuedBy', 'dateFrom'],
    'DRIVING_LICENSE': ['series', 'number', 'issuedBy', 'dateFrom'],
    'EXTERNAL_PASSPORT': ['series', 'number', 'issuedBy', 'dateFrom'],
    'FOREIGN_PASSPORT': ['series', 'number', 'issuedBy', 'dateFrom'],
    'RESIDENCE_PERMIT': ['series', 'number', 'issuedBy', 'dateFrom'],
    'ID_RESIDENCE_PERMIT': ['series', 'number', 'issuedBy', 'dateFrom'],
    'REGISTRATION_CARD': ['series', 'number', 'issuedBy', 'dateFrom'],
    'BIRTH_CERTIFICATE': ['series', 'number', 'issuedBy', 'dateFrom'],
    'PENSION_CERTIFICATE': ['series', 'number', 'issuedBy', 'dateFrom'],
    'E_PENSION_CERTIFICATE': ['series', 'number', 'issuedBy', 'dateFrom', 'dateTo'],
    'DISABILITY_CERTIFICATE': ['series', 'number', 'issuedBy', 'dateFrom'],
    'VETERAN_CERTIFICATE': ['series', 'number', 'issuedBy', 'dateFrom'],
    'CHERNOBYL_CERTIFICATE': ['series', 'number', 'issuedBy', 'dateFrom']
}