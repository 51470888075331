import { Pagination, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getPoliciesByUserAPI } from '../../api/policyAPI'
import PolicyItem from './PolicyItem'
import { useDispatch, useSelector } from 'react-redux'
import { setIsAuth } from '../../store/userSlice'
import PolicySceleton from './PolicySceleton'
import PolicySceletonList from './PolicySceletonList'

const PoliciesList = ({ products }) => {

    const activeProductItem = useSelector(state => state.menu.activeProductItem)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [policies, setPolicies] = useState([])
    const [productId, setProductId] = useState('')
    const [count, setCount] = useState(0)
    const [offset, setOffset] = useState(0)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(9)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (activeProductItem === 'all') {
            setProductId('')
            changePage('', 1)
        } else {
            setProductId(activeProductItem)
            changePage('', 1)
        }
    }, [activeProductItem])

    useEffect(() => {
        setLoading (true)
        getPoliciesByUserAPI(offset, limit, productId)
        .then(data => {
            setLoading(false)
            setPolicies(data.policies)
            setCount(data.count)
        })
        .catch(e => {
            setLoading(false)
            if (e.response.status === 401) {
                dispatch(setIsAuth(false))
                navigate('/login')
            }
        })
    }, [offset, limit, productId])

    const changePage = (event, value) => {
        setPage(value)
        setOffset(value - 1);
    }

    return (
        <>
            {
                !loading && policies.length > 0 ?
                    <>
                        <div className='documents-list'>
                            {
                                policies.length > 0 ? policies.map(policy => 
                                    <PolicyItem key={policy.id} policy={policy} products={products} />
                                ) : ''
                            }
                            
                            {/* <div className='documents-listOne'>
                                <div className='documents-listOne-head'>
                                    <div className='documents-listOne-headName'>
                                        KIRINS | <span>Тревел</span>
                                    </div>
                                    <Tooltip title="СК Княжа">
                                        <div className='documents-listOne-headCompany'>
                                            Княжа
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='documents-listOne-name'>
                                    <p className='documents-listOne-numberFio'>MYKHAILOV VALENTYN</p>
                                    <p>Європа</p>
                                </div>
                                <div className='documents-listOne-link'>
                                    <Link to='#'>Детальніше</Link>
                                    <p>до 31.12.2022</p>
                                </div>
                            </div>
                            <div className='documents-listOne'>
                                <div className='documents-listOne-head'>
                                    <div className='documents-listOne-headName'>
                                        KIRINS | <span>ОСЦПВ</span>
                                    </div>
                                    <Tooltip title="СК Інтерплюс">
                                        <div className='documents-listOne-headCompany'>
                                            Інтерплюс
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='documents-listOne-name'>
                                    <p className='documents-listOne-numberFio'>АХ0000АХ</p>
                                    <p>Volkswagen Golf</p>
                                </div>
                                <div className='documents-listOne-link'>
                                    <Link to='#'>Детальніше</Link>
                                    <p>до 31.12.2022</p>
                                </div>
                            </div>
                            <div className='documents-listOne'>
                                <div className='documents-listOne-head'>
                                    <div className='documents-listOne-headName'>
                                        KIRINS | <span>Зелена карта</span>
                                    </div>
                                    <Tooltip title="СК PZU (ПЗУ)">
                                        <div className='documents-listOne-headCompany'>
                                            PZU
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='documents-listOne-name'>
                                    <p className='documents-listOne-numberFio'>АХ0000АХ</p>
                                    <p>Volkswagen Golf</p>
                                </div>
                                <div className='documents-listOne-link'>
                                    <Link to='#'>Детальніше</Link>
                                    <p>до 31.12.2022</p>
                                </div>
                            </div> */}
                        </div>
                        <Pagination count={Math.round(count/limit)} page={page} onChange={changePage} />
                    </> :
                !loading && policies.length === 0 ?
                    <div className='notFound__img-block'>
                        <img src="/img/profile/policy_not_found.png" alt="полісів не знайдено" />
                    </div>
                :
                    <PolicySceletonList />
            }
        </>
    )
}

export default PoliciesList