import React, { useEffect } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { IconButton, InputAdornment, Link, TextField } from '@mui/material'
import MyButton from '../UI/button/MyButton';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuth, setUser } from '../../store/userSlice';
import { useState } from 'react';
import { checkKeyForRecoveryAPI, loginAPI, recoveryPasswordAPI } from '../../api/userAPI';
import EmailFilter from '../../filter/email/EmailFilter';
import { useMyInput } from '../../hooks/ValidateForm';
import PasswordField from '../../filter/password/PasswordField';
import { useMemo } from 'react';
import MySpiner from '../UI/spiner/MySpiner';
import RecoverySuccess from './RecoverySuccess';

const RecoveryForm = ({page}) => {

    const isAuth = useSelector(state => state.user.isAuth)
    const params = useSearchParams()
    const key = params[0].get('key')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const password = useMyInput('', {isEmpty: true, minLength: 5})

    const [isValid, setIsValid] = useState(false)
    const [isValidKey, setIsValidKey] = useState(false)
    const [isValidKeyLoading, setIsValidKeyLoading] = useState(true)
    const [loadingRequest, setLoadingRequest] = useState(false)
    const [successRecover, setSuccessRecover] = useState(false)
    const [error, setError] = useState({
        status: null,
        message: ''
    })

    useEffect(() => {
        if (key) {
            checkKeyForRecoveryAPI(key)
            .then(data => {
                if (data === true) {
                    setIsValidKey(true)
                }
                setIsValidKeyLoading(false)
            })
            .catch(e => {
                setError({
                    status: 404,
                    message: 'Посилання не дійсне'
                })
                setIsValidKeyLoading(false)
                setIsValidKey(false)
            })
        } else {
            // navigate('/404')
            window.location.href = '/404'
        }
    }, [key])

    useEffect(() => {
        if (
            password.isInputValid
        ) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [password])

    // const toPage = (link) => {
    //     navigate('/' + link)
    // }

    const recovery = async (e) => {
        e.preventDefault()
        setLoadingRequest(true)
        recoveryPasswordAPI(key, password.value).then(data => {
            console.log(data)
            setError({
                status: 200,
                message: 'Пароль успішно змінено'
            })
            setLoadingRequest(false)
            setSuccessRecover(true)
        }).catch(e => {
            console.log(e.response.data.message)
            setError({
                status: 404,
                message: e.response.data.message
            })
            setIsValidKey(false)
            setLoadingRequest(false)
        })
    }

  return (
    <form className='login__form'>
        <div className={
            error.status === 404 ? 'login__error' :
            error.status === 200 ? 'login__success' : 'hide'
        }>
            { error.message }
        </div>
        {
            isValidKeyLoading ? <MySpiner /> : ''
        }
        {
            error.status === 200 ? <RecoverySuccess /> : ''
        }
        {
            isValidKey && !successRecover ?
                <>
                    <h3>Зміна паролю</h3>

                    <p className='login__textHelp'>Вкажіть новий пароль</p>

                    <PasswordField
                        password={password}
                    />

                    <MyButton 
                        classNameMy={'myBtn__green'}
                        type='submit'
                        onClick={recovery}
                        disabled={!isValid || loadingRequest || successRecover ? true : false}
                    >
                        Змінити
                    </MyButton>
                </>
            : ''
        }
        
        
        {/* <div className='login__social'>
            <div className='login__social-fb'>
                <FacebookIcon />
                <p>Увійти за допомогою Facebook</p>
            </div>
            <div className='login__social-google'>
                <GoogleIcon />
                <p>Увійти за допомогою Google</p>
            </div>
        </div> */}
    </form>
  )
}

export default RecoveryForm