import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react'
import { getPhoneCodesAPI } from '../../api/userAPI';

const PhoneCode = ({ code, setOpenCodes, setPhoneLength }) => { // объект из формы валидации

    const [codeTemp, setCodeTemp] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [codeList, setCodeList] = useState([])

    const getCodes = (query) => {
        if (query.length >= 1) {
            getPhoneCodesAPI(query).then(data => {
                setCodeList(data)
                setIsLoading(false)
                // console.log(data)
            }).catch(e => {
                setCodeList([])
                setIsLoading(false)
                console.error(e)
            })
        } else {
            setCodeList([])
        }
    }

    const filterOptions = (options, state) => { // Делаю поиск по нужным полям
        let newOptions = [];
        options.forEach((element) => {
            if (
                !Number.isInteger(Number(state.inputValue))
                &&
                (
                    element.nameUa
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    ||
                    element.nameEn
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    ||
                    element.nameRu
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                )
                ||
                element.phoneCode
                    .includes(state.inputValue)
            )
                newOptions.push(element);
        });
        return newOptions;
    }

    const getLabel = (option) => {
        if (option && typeof option !== 'string') {
            let text = '+' + option.phoneCode + ' ' + option.nameUa
            // if (locale === 'uk') {
            //     text += option.nameUa
            // } else if (locale === 'ru') {
            //     text += option.nameRu
            // } else if (locale === 'en') {
            //     text += option.nameEn
            // }
    
            return text
        }
        return ''
    }

  return (
    <Autocomplete
        // value={code ? code.value : ''}
        onChange={(event, newValue) => { // Весь объект города
            if (newValue) {
                code.onChange(newValue.phoneCode)
                setPhoneLength(newValue.phoneLength)
                setOpenCodes(false)
                setCodeTemp(null)
            }
            setCodeTemp(null)
        }}
        inputValue={codeTemp}
        onInputChange={(event, newInputValue) => {
            setCodeTemp(newInputValue);
            if (newInputValue) {
                getCodes(newInputValue)
            }
        }}
        id="controllable-states-demo"
        size="small"
        options={codeList}
        getOptionLabel={(option) => option ? getLabel(option) : ''}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, value) => option.phoneCode === value ? value : ''}
        // clearIcon={null}
        noOptionsText={isLoading ? 'Пошук...' : 'Не знайдено'}
        onClose={() => setOpenCodes(false)}
        // disableClearable={true}
        // componentsProps={{
        //     paper: {
        //         sx: {
        //         width: 250
        //         }
        //     },
        // }}
        renderInput={(params) => 
            <TextField
                {...params} 
                variant='standard'
                value={codeTemp}
                placeholder="Пошук..." 
                error={code.isDirty && code.isEmpty ? true : false} // чтобы был текст ошибки
                color={
                    code.isDirty && code.isEmpty ? 'error' : 'success'
                } // чтобы был успешный цвет
                focused={code.isDirty ? true : false}
                InputLabelProps={{
                    shrink: true
                }}
            />
        }
    />
  )
}

export default PhoneCode