import React, { useEffect } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Link, TextField } from '@mui/material'
import MyButton from '../UI/button/MyButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuth, setUser } from '../../store/userSlice';
import { useState } from 'react';
import { loginAPI, registerUserAPI, resetUserPasswordAPI } from '../../api/userAPI';
import EmailFilter from '../../filter/email/EmailFilter';
import { useMyInput } from '../../hooks/ValidateForm';
import PasswordField from '../../filter/password/PasswordField';
import PhoneField from '../../filter/phoneCode/PhoneField';
import { setErrorEmailIsDoRequest } from '../../store/errorSlice';
import { errorMessage } from '../../helpers/errors/ErrorsConsole';
import RegisterSuccess from './RegisterSuccess';

const RegisterForm = () => {

    const isAuth = useSelector(state => state.user.isAuth)
    const emailError = useSelector(state => state.error.email)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const [email, setEmail] = useState('')
    const email = useMyInput('', {isEmpty: true, isEmail: true})
    const password = useMyInput('', {isEmpty: true, minLength: 5})
    // const [password, setPassword] = useState('')

    const [phoneCode, setPhoneCode] = useState('')
	const [phone, setPhone] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [isValidPhone, setIsValidPhone] = useState(false)
    const [isClickNext, setIsClickNext] = useState(false) // нажали ли клиент кнопку далее
    const [loadingRequest, setLoadingRequest] = useState(false)
    const [error, setError] = useState({
        status: null,
        message: ''
    })

    useEffect(() => {
        if (
            email.isInputValid &&
            password.isInputValid &&
            isValidPhone 
            && (emailError.isValid || (emailError.isCanOffer && emailError.isConfirmOne))
        ) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [email, password, isValidPhone, emailError])

    useEffect(() => {
        if (isClickNext && emailError.isCanOffer && emailError.isConfirmOne && emailError.isValid) {
        // if (isClickNext) {
            register()
        }
    }, [isClickNext, emailError])

    const toPage = (link) => {
        navigate('/' + link)
    }

    const checkEmailRegister = (e) => {
        e.preventDefault()
        setIsClickNext(true)
        setLoadingRequest(true)
        if (!emailError.isConfirmOne) {
            // checkEmailRequest()
            dispatch(setErrorEmailIsDoRequest(true))
            setLoadingRequest(false)
        } else {
            register()
        }
    }

    const register = () => {
        setLoadingRequest(true)
        registerUserAPI(email.value, password.value, phoneCode, phone).then(data => {
            setError({
                status: 200,
                message: 'Реєстрація пройшла успішно'
            })
            setLoadingRequest(false)
            // dispatch(setUser(data))
            // dispatch(setIsAuth(true))
            // navigate('/profile')
        }).catch(e => {
            console.log(e.message)
            // setIsClickNext(true)
            setError({
                status: 404,
                message: e.response.data.message
            })
            setLoadingRequest(false)
        })
        setIsClickNext(false)
    }
    
  return (
    <form className='login__form'>
        <div className={
            error.status === 404 ? 'login__error' :
            error.status === 200 ? 'login__success' : 'hide'
        }>
            { error.message }
        </div>
        {
            error.status === 200 ? <RegisterSuccess /> : ''
        }
        {
            error.status !== 200 ?
                <>
                    <h3>Реєстрація</h3>
                    <EmailFilter 
                        email={email}
                    />
                    <PhoneField
                        setPhoneCode={setPhoneCode}
                        setPhone={setPhone}
                        setIsValid={setIsValidPhone}
                    />
                    <PasswordField
                        password={password}
                    />

                    <MyButton 
                        classNameMy={'myBtn__green'}
                        type='submit'
                        onClick={checkEmailRegister}
                        disabled={
                            !isValid || loadingRequest ? true : false // Если идет запрос
                            // emailError.isCanOffer && emailError.isConfirmOne ? false : // Если прошел запрос и можно продолжать (в случае ошибки 1 раз)
                            // !isValid ? true : false
                        }
                    >
                        Зареєструватись
                    </MyButton>
                    <div className='login__link'>
                        <Link onClick={() => toPage('login')}>Вхід</Link>
                    </div>
                </>
            : ''
        }
        {/* <div className='login__social'>
            <div className='login__social-fb'>
                <FacebookIcon />
                <p>Увійти за допомогою Facebook</p>
            </div>
            <div className='login__social-google'>
                <GoogleIcon />
                <p>Увійти за допомогою Google</p>
            </div>
        </div> */}
    </form>
  )
}

export default RegisterForm