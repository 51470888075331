import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { autoNames, autoTypes } from '../../locale/car/CarLocale';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteCarAPI } from '../../api/carAPI';
import { useDispatch } from 'react-redux';
import { setAlertOpen, setAlertText, setAlertType } from '../../store/alertSlice';
import { deleteCar } from '../../store/carSlice'
import MyModal from '../UI/modal/MyModal';

const CarOne = ({car, carEditId, setCarEditId}) => {

    const dispatch = useDispatch()

    const [openModal, setOpenModal] = useState(false)
    const [isAgry, setIsAgry] = useState(false)

    useEffect(() => {
        if (isAgry) {
            deleteCarOne()
        }
    }, [isAgry])

    const deleteCarOne = () => {
        // console.log(car.id)
        deleteCarAPI(car.id).then(data => {
            dispatch(deleteCar(car.id))

            dispatch(setAlertText('Автомобіль успішно видалено'))
            dispatch(setAlertType('success'))
            dispatch(setAlertOpen(true))
        }).catch(e => {
            dispatch(setAlertText(e.response.data.message))
            dispatch(setAlertType('error'))
            dispatch(setAlertOpen(true))
        })

        setTimeout(() => {
            dispatch(setAlertOpen(false))
        }, 3000);
    }

  return (
    <div className='carsOne'>
        <MyModal
            header='Ви впевнені, що хочете видалити авто?'
            text='Повернути авто вже не буде можливості'
            isOpen={openModal}
            setOpenModal={setOpenModal}
            setIsAgry={setIsAgry}
            textYes='Видалити'
            textNo='Скасувати'
        /> 
        <div className='documents-listOne-headName'>
            KIRINS | <span>car</span>
        </div>
        <ul className='carsOne__list'>
            <li>
                <p>Державний номер</p>
                <span className='carsOne__list-number'>{car.number ? car.number : 'не вказано'}</span>
            </li>
            <li>
                <p>Марка/модель</p>
                <span>
                    {car.mark ? car.mark + ' ' : 'не вказано'}
                    {car.model ? car.model : ''}
                </span>
            </li>
            <li>
                <p>Тип/категорія</p>
                <span>
                    {car.autoName ? autoNames['uk'][car.autoName] : 'не вказано'}
                    {car.autoType ? ' (' + autoTypes['uk'][car.autoName][car.autoType] + ')' : ''}
                </span>
            </li>
            <li>
                <p>VIN-код</p>
                <span>{car.vin ? car.vin : 'не вказано'}</span>
            </li>
            <li>
                <p>Об'єм двигуна</p>
                <span>{car.volumeEngine ? car.volumeEngine + ' см3' : 'не вказано'}</span>
            </li>
            <li>
                <p>Рік випуску</p>
                <span>{car.year ? car.year : 'не вказано'}</span>
            </li>
            <li>
                <p>Місто прописки власника</p>
                <span>{car.city ? car.city.nameFullUa : 'не вказано'}</span>
            </li>
            {/* <li>
                <p>Місце прописки власника</p>
                <span>Харків</span>
            </li> */}
        </ul>
        {
        !carEditId ? 
        <div className='carsOne__edit-wrap'>
            <div className='carsOne__edit' onClick={() => setCarEditId(car.id)} >
                <EditIcon />
                <p>Редагувати</p>
            </div>
            <div className='carsOne__delete' onClick={e => setOpenModal(true)} >
                <DeleteIcon />
                <p>Видалити</p>
            </div>
        </div>
        :
        ''
        }
    </div>
  )
}

export default CarOne