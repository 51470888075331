import { createSlice } from "@reduxjs/toolkit"

const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        text: '',
        type: 'success',
        open: false,
    },
    reducers: {
        setAlertText(state, action) {
            state.text = action.payload
        },
        setAlertType(state, action) {
            state.type = action.payload
        },
        setAlertOpen(state, action) {
            state.open = action.payload
        },
    }
})

export default alertSlice.reducer

export const {setAlertText,
                setAlertType,
                setAlertOpen,
                
            } = alertSlice.actions