import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import LoginForm from '../components/login/LoginForm'
import RegisterForm from '../components/login/RegisterForm'

const Register = () => {
    const isAuth = useSelector(state => state.user.isAuth)
  return (
    <div className='login__page'>
        <RegisterForm />
        {
            isAuth ?
            <Navigate to='/profile' />
            :
            ''
        }
    </div>
  )
}

export default Register