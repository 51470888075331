import React, { useState } from 'react'
import { DOCUMENTS_TYPE_MAIN } from '../../types/DocumentTypes'
import moment from 'moment'
import { getNameByPolicy } from '../../helpers/policy/PolicyHelper'

const PolicyInsurer = ({ person, city, product }) => {

    const [visible, setVisible] = useState(false)

  return (
    <div>
        <h3>Страхувальник</h3>
        <ul>
            <li>
                <p>ПІБ</p>
                <span>
                    { 
                        // person ? person.surname + ' ' + person.name + 
                        //     (person.patronymic ? ' ' + person.patronymic : '' ) 
                        // : '' 
                        person ? getNameByPolicy({ customer: person }) : '' 
                    }
                </span>
            </li>
            {
                visible ?
                    <>
                        {
                            product && product.id === 4 ? '' :
                            <li>
                                <p>ІПН</p>
                                <span>
                                    { person && person.inn ? person.inn : 'немає' }
                                </span>
                            </li>
                        }
                        {
                            product && product.id === 4 ? 
                            <li>
                                <p>Документ</p>
                                <span>
                                    { person ? person.number : '' }
                                </span>
                            </li>
                            :
                            <li>
                                <p>Документ</p>
                                <span>
                                    { person && person.type ? DOCUMENTS_TYPE_MAIN[person.type]['uk'] : '' }
                                    <br />
                                    { person && person.series ? person.series + ' ' : '' }
                                    { person ? person.number : '' }
                                </span>
                            </li>
                        }
                        <li>
                            <p>Дата народження</p>
                            <span>
                                { person ? moment(person.dateOfBirth).format('DD.MM.YYYY') : '' }
                            </span>
                        </li>
                        {/* <li>
                            <p>Прописка власника</p>
                            <span>
                                { city ? city.nameUa : '' }
                            </span>
                        </li> */}
                        {
                            product && product.id === 4 ? '' :
                            <li>
                                <p>Адреса</p>
                                <span>
                                    { city ? city.nameUa + ' ' : '' }
                                    { person ? person.adress : '' }
                                </span>
                            </li>
                        }
                    </>
                : ''
            }
            <p
                className='policy_info-more'
                onClick={e => setVisible(!visible)}
            >
                {
                    !visible ? 'Детальніше' : 'Сховати'
                }
            </p>
        </ul>
    </div>
  )
}

export default PolicyInsurer