import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../assets/img/logo_kirins.svg'
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuth, setUser } from '../store/userSlice';
import { useEffect } from 'react';
import { getMenuItemsAPI, getMenuNameAPI } from '../api/menuAPI';
import MyButton from './UI/button/MyButton';
import LoginBlockMobile from './login/menu/LoginBlockMobile';
import ProfileList from './login/menu/ProfileList';
import LoginBlock from './login/menu/LoginBlock';
import LinkMui from '@mui/material/Link';

const Header = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isAuth = useSelector(state => state.user.isAuth)

    const [menu, setMenu] = useState()
    const [menuItems, setMenuItems] = useState()
    const menuInsuranceId = 1
    const menuMainId = 2

    useEffect(() => {
        getMenuNameAPI().then(data => {
            setMenu(data)
        })
        getMenuItemsAPI().then(data => {
            // console.log(data)
            setMenuItems(data)
        })
    }, [])

    // const exit = () => {
    //     dispatch(setIsAuth(false))
    //     dispatch(setUser({}))
    //     localStorage.removeItem('token')
    //     // navigate('/')
    //     window.location.href = '/'
    // }

  return (
    <header>
        {/* <div className="container-fluid container-lg header__wrap"> */}
        <div className="container-fluid header__wrap">
            <div className="header__logo">
                <a href="/">
                    <img src={'/assets/img/kirins_logo_ua_new.svg'} alt="Логотип KIRINS" />
                </a>
            </div>
            <nav className="navbar header__nav" itemScope itemType="https://schema.org/SiteNavigationElement">
                <div className="header__nav-desktop">
                    <ul className="navbar-nav header__nav-ul">
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="isurance__nav" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {
                                    menu ? menu.map(item => item.id === menuInsuranceId ? item.nameUa : '') : ''
                                }
                            </Link>
                            <ul className="dropdown-menu isurance__nav-ul" aria-labelledby="isurance__nav">
                                {
                                    menuItems ? menuItems.map(item => {
                                        if(item.menuNameId === menuInsuranceId) {
                                            return <li key={item.href}><a className="dropdown-item" href={'/' + item.href}>{item.nameUa}</a></li>
                                        }
                                    }) : ''
                                }
                                {/* <MenuList menuItems={menuItems} menuId={menuInsuranceId} />                                  */}
                            </ul>
                        </li>
                        {
                            menuItems ? menuItems.map(item => {
                                if(item.menuNameId === menuMainId) {
                                    return <li key={item.href}><a className="dropdown-item" href={'/' + item.href}>{item.nameUa}</a></li>
                                }
                            }) : ''
                        }
                        
                    </ul>
                        {
                            !isAuth ?
                                <LoginBlock />
                            :
                                <ProfileList />
                        }
                    {/* <Locale router={router} /> */}
                </div>
                <div className="header__nav-mobile">
                    <ul className="navbar-nav header__nav-ul">
                        <li className="nav-item dropdown isurance__nav-hover">
                            {/* <Link href="" > */}
                                <LinkMui className="nav-link dropdown-toggle" id="isurance__nav" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {
                                        menu ? menu.map(item => item.id === menuInsuranceId ? item.nameUa : '') : ''
                                    }
                                </LinkMui>
                            {/* </Link> */}
                            <ul className="dropdown-menu isurance__nav-ul" aria-labelledby="isurance__nav">
                                {
                                    menuItems ? menuItems.map(item => {
                                        if(item.menuNameId === menuInsuranceId) {
                                            return <li key={item.href}><a className="dropdown-item" href={'/' + item.href}>{item.nameUa}</a></li>
                                        }
                                    }) : ''
                                }
                            </ul>
                        </li>
                    </ul>
                    <ul className="navbar-nav header__nav-ul header__nav-mobile-ul">
                        <li className="nav-item dropdown header__nav-mobile-hover">
                            {/* <Link href=""> */}
                                <LinkMui className="nav-link dropdown-toggle" id="header__nav-mobile-more" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="/assets/img/menu-mobile-ico.svg" alt="иконка меню" width={16} height={16} />
                                </LinkMui>
                            {/* </Link> */}
                            <ul className="dropdown-menu isurance__nav-ul" aria-labelledby="header__nav-mobile-more">
                                {
                                    menuItems ? menuItems.map(item => {
                                        if(item.menuNameId === menuMainId) {
                                            return <li key={item.href}><a className="dropdown-item" href={'/' + item.href}>{item.nameUa}</a></li>
                                        }
                                    }) : ''
                                }
                            </ul>
                        </li>
                    </ul>
                    {
                        !isAuth ?
                            <LoginBlockMobile />
                        :
                            <ProfileList />
                    }
                </div>
                
            </nav>
        </div>
    </header>
  )
}

export default Header