import { createSlice } from "@reduxjs/toolkit"

const menuSlice = createSlice({
    name: 'user',
    initialState: {
        user: {},
        isAuth: false,
    },
    reducers: {
        setIsAuth(state, action) {
            state.isAuth = action.payload
        },
        setUser(state, action) {
            state.user = action.payload
        },
    }
})

export default menuSlice.reducer

export const {setIsAuth,
                setUser,
                
            } = menuSlice.actions