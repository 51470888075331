export const getNameByPolicy = ({ customer, company }) => {
    try {
        let name = ''
        if (company && company.nameUa) {
            if (company.nameUa) {
                name = company.nameUa
            } else {
                name = company.nameEn
            }
        } else {
            if (customer.name) {
                if (customer.surname) {
                    name += customer.surname
                }
                if (customer.name) {
                    name += ' ' + customer.name
                }
                if (customer.patronymic) {
                    name += ' ' + customer.patronymic
                }
            } else if (customer.nameEng) {
                name = customer.nameEng + ' ' + customer.surnameEng
            }
        }

        return name
    } catch (e) {
        console.error('policyHelper => getNameByPolicy', e)
    }
}