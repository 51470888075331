import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { MenuItem } from '@mui/material';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import ShieldIcon from '@mui/icons-material/Shield';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MyDropdown from '../../UI/dropdown/MyDropdown';
import { setIsAuth, setUser } from '../../../store/userSlice';

const ProfileList = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const exit = () => {
        dispatch(setIsAuth(false))
        dispatch(setUser({}))
        localStorage.removeItem('token')
        window.location.href = '/'
    }
  
  return (
    <div>
        <MyDropdown
            idButton='profile-button'
            idMenu='profile-menu'
            classWrapper='profileBlock'
            ButtonMain={() => <AccountCircleIcon sx={{width: '35px', height: '35px'}} />}
        >
            <MenuItem onClick={e => navigate('/profile/insurance')}>
                <ShieldIcon />
                Мої страховки
            </MenuItem>
            <MenuItem onClick={e => navigate('/profile/cars')}>
                <DirectionsCarIcon />
                Мої авто
            </MenuItem>
            <MenuItem onClick={e => navigate('/profile/documents')}>
                <FolderCopyIcon />
                Мої документи
            </MenuItem>
            <MenuItem onClick={e => navigate('/profile/personal-data')}>
                <SettingsIcon />
                Мій профіль
            </MenuItem>
            <MenuItem onClick={exit}>
                <LogoutIcon />
                Вийти
            </MenuItem>
        </MyDropdown>
    </div>
  )
}

export default ProfileList