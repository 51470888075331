import React, { useEffect, useState } from 'react'
import MyButton from '../UI/button/MyButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getCarsAPI } from '../../api/carAPI';
import { setAutoDataSelect, setCars } from '../../store/carSlice';
import { useDispatch, useSelector } from 'react-redux';
import CarOne from './CarOne';
import CarSceleton from './CarSceleton';
import noCarImg from '../../assets/img/notFound/no_car_img.jpg'
import CarFrom from './CarFrom';
import { setIsAuth } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';

const CarsList = () => {

    const cars = useSelector(state => state.car.cars)
    const [isAdd, setIsAdd] = useState(false)
    const [carEditId, setCarEditId] = useState(null)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getCarsAPI()
        .then(data => {
            dispatch(setCars(data))
            setLoading(false)
        })
        .catch(e => {
            if (e.response.status === 401) {
                dispatch(setIsAuth(false))
                navigate('/login')
            }
        })
    }, [])

  return (
    <div>
        {
            loading ?
                <CarSceleton />
            :
                <>
                {!isAdd ?
                    <MyButton 
                        classNameMy='addBtn__logzone myBtn__green'
                        onClick={() => {
                            setIsAdd(true)
                            dispatch(setAutoDataSelect(null))
                        }}
                    >
                        <AddCircleOutlineIcon />
                        Додати авто
                    </MyButton>
                    :
                    // <CarAdd setIsAdd={setIsAdd} />   
                    <CarFrom 
                        typeForm='add'
                        setIsAdd={setIsAdd} 
                    />   
                }
                {
                    cars.length > 0 
                    // cars.length == 0 
                    ?
                        cars.map(car => {
                            if (carEditId === car.id && !isAdd) {
                                return <CarFrom
                                            key={car.id} 
                                            typeForm='edit'
                                            car={car} 
                                            setCarEditId={setCarEditId} 
                                        />     
                            }
                            return <CarOne 
                                        key={car.id} 
                                        car={car} 
                                        carEditId={carEditId} 
                                        setCarEditId={setCarEditId} 
                                    />
                        })
                    :
                        // <div className='noCar__block'>
                        //     <img src={noCarImg} alt="немає авто на KIRINS" />
                        //     <h3>У Вас ще немає доданих авто</h3>
                        // </div>
                        <div class='notFound__img-block'>
                            <img src="/img/profile/auto_not_found.png" alt="авто не знайдено" />
                        </div>
                }
                </>
        }
    </div>
  )
}

export default CarsList