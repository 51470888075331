import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { getCitiesByQueryAPI } from '../../api/cityAPI';

const CitiesFilter = ({ city }) => { // объект из формы валидации

    const [cityTemp, setCityTemp] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [citiesList, setCitiesList] = useState([])

    const [widthScreen, setWidthScreen] = useState('')

    useEffect(() => {
        if(window) {
            setWidthScreen(window.innerWidth)
            // console.log(window.innerWidth)
        }
    }, [])

    const getCities = (query) => {
        if (query.length >= 3) {
            getCitiesByQueryAPI(query).then(data => {
                setCitiesList(data)
                setIsLoading(false)
                // console.log(data)
            }).catch(e => {
                setCitiesList([])
                setIsLoading(false)
                console.error(e)
            })
        } else {
            setCitiesList([])
        }
    }

    const filterOptions = (options, state) => { // Делаю поиск как по сокращенному полю на русском так и на укр
        let newOptions = [];
        options.forEach((element) => {
            if (
                element.nameUa
                    // .replace(",", "")
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                ||
                element.nameRu
                    // .replace(",", "")
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
            )
                newOptions.push(element);
        });
        return newOptions;
    }

  return (
    <Autocomplete
        value={city.value}
        // value={''}
        // id='citiesFilter'
        onChange={(event, newValue) => { // Весь объект города
            city.onChange(newValue)
            // setCity(newValue);
        }}
        onBlur={e => city.onBlur(e)} 
        // onBlur={e => console.log(e.target.value)} 
        inputValue={cityTemp}
        onInputChange={(event, newInputValue) => {
            setCityTemp(newInputValue);
            getCities(newInputValue)
            if (newInputValue.length === 0) {
                city.onChange('')
                setIsLoading(false)
            } else if (newInputValue.length > 0) {
                setIsLoading(true)
            }
        }}
        id="controllable-states-demo"
        size="small"
        options={citiesList}
        // getOptionLabel={(option) => locale === 'ru' ? option.nameFullRu : option.nameFullUa}
        getOptionLabel={(option) => option ? option.nameFullUa : ''}
        filterOptions={filterOptions}
        // getOptionSelected={(option, value) => option.id === value.id }
        isOptionEqualToValue={(option, value) => value ? option.id === value.id : ''}
        // noOptionsText={'Не знайдено'}
        noOptionsText={isLoading ? 'Пошук...' : 'Не знайдено'}
        // sx={{minWidth:150}}
        componentsProps={{
            paper: {
                sx: {
                    // width: 500
                    width: widthScreen > 600 ? 500 : 250
                }
            }
            }}
        // sx={{ width: 300 }}
        renderInput={(params) => 
            // console.log(params)
            <TextField
                {...params} 
                variant='standard'
                // label="Місто прописки" 
                // className='testPoper'
                placeholder="Харків" 
                error={city.isDirty && city.isEmpty ? true : false} // чтобы был текст ошибки
                color={
                    city.isDirty && city.isEmpty ? 'error' : 'success'
                } // чтобы был успешный цвет
                helperText={city.isDirty && city.errors.isEmpty ? city.errors.isEmpty : ''} // Текст ошибки
                focused={city.isDirty ? true : false}
                InputLabelProps={{
                    // ...params.InputLabelProps,
                    shrink: true,
                    className: 'testPoper'
                }}
            />
        }
    />
  )
}

export default CitiesFilter