import { MenuItem } from '@mui/material';
import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useNavigate } from 'react-router-dom';
import MyDropdown from '../../UI/dropdown/MyDropdown';

const LoginBlockMobile = () => {

    const navigate = useNavigate()

  return (
    <div>
        <MyDropdown
            idButton='profile-button'
            idMenu='profile-menu'
            classWrapper='profileBlock profileBlock__mobile'
            ButtonMain={() => <AccountCircleIcon sx={{width: 35, height: 35}} />}
        >
            <MenuItem onClick={e => navigate('/login')}>
                <LoginIcon />
                Вхід
            </MenuItem>
            <MenuItem onClick={e => navigate('/register')}>
                <HowToRegIcon />
                Реєстрація
            </MenuItem>
        </MyDropdown>
    </div>
  )
}

export default LoginBlockMobile