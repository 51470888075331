import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import alertSlice from './alertSlice'
import carSlice from './carSlice'
import menuSlice from './menuSlice'
import personSlice from './personSlice'
import productHomeSlice from './productHomeSlice'
import userSlice from './userSlice'
import errorSlice from './errorSlice'

const rootReducer = combineReducers({
    menu: menuSlice,
    user: userSlice,
    car: carSlice,
    alert: alertSlice,
    productHome: productHomeSlice,
    person: personSlice,
    error: errorSlice,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
})