
import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { TextField } from "@mui/material";

const MyYearPicker = ({ value, labelText, isDirty, isError, error, onBlur, onChange, maxDateType, minDateType }, ...props) => {

    const locale = 'uk'
    const [maxDate, setMaxDate] = useState('')
    const [minDate, setMinDate] = useState('')

    useEffect(() => {
        let arr = [], 
            max = [],
            min = [],
            // yearNow = moment().format('yyyy'),
            // minYear = moment('01.01.1950'),
            // minYear = new Date('1950'),
            minYear = moment('01.01.1950')._d,
            // maxYear = moment()
            // maxYear = new Date()
            maxYear = moment()._d

        switch(maxDateType) {
            case 'today':
                maxYear = moment()._d
                // maxYear = new Date()
                break
            default:
                break
        }

        setMaxDate(maxYear)
        setMinDate(minYear)
    }, [maxDateType])

  return (
    <DatePicker 
        dateFormat='yyyy' 
        showYearPicker
        placeholderText='2008'
        customInput={
            // <MaskedInput mask="11.11.1111" placeholder="dd.MM.yyyy" />
            <TextField
                variant='standard'
                // label={labelText ? labelText : ''}
                size="small"
                value={value ? value : ''}
                onChange={(date) => onChange(date)}
                error={isDirty && isError ? isError : false} // чтобы был текст ошибки
                color={
                    isDirty && isError ? 'error' :
                    isDirty && !isError ? 'success' : ''
                } // чтобы был успешный цвет
                focused={isDirty ? true : false} // для отображения ошибки или успеха при выходе из поля
                helperText={isDirty && isError ? error : ''} //Текст ошибки
                onBlur={e => onBlur(e)}
                InputLabelProps={{
                    shrink: true
                }}
            />
        }
        maxDate={maxDate}
        minDate={minDate}
        selected={value ? value : null} 
        // selected={value} 
        onBlur={e => onBlur(e)}
        onChange={(date) => onChange(date)}
        {...props}
    />
  )
}

export default MyYearPicker