import React, { useEffect } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { IconButton, InputAdornment, Link, TextField } from '@mui/material'
import MyButton from '../UI/button/MyButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { resetUserPasswordAPI } from '../../api/userAPI';
import EmailFilter from '../../filter/email/EmailFilter';
import { useMyInput } from '../../hooks/ValidateForm';
import { errorMessage } from '../../helpers/errors/ErrorsConsole';
import ResetSuccess from './ResetSuccess';

const ResetForm = () => {

    const isAuth = useSelector(state => state.user.isAuth)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const email = useMyInput('', {isEmpty: true, isEmail: true})

    const [isValid, setIsValid] = useState(false)
    const [loadingRequest, setLoadingRequest] = useState(false)
    const [successRecover, setSuccessRecover] = useState(false)
    const [error, setError] = useState({
        status: null,
        message: ''
    })

    // useEffect(() => {
    //     if (isAuth) {
    //         navigate('profile')
    //     }
    // }, [isAuth])

    useEffect(() => {
        if (
            email.isInputValid
        ) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [email])

    const toPage = (link) => {
        navigate('/' + link)
    }

    const reset = async (e) => {
        e.preventDefault()
        setLoadingRequest(true)
        resetUserPasswordAPI(email.value).then(data => {
            console.log(data)
            // setIsClickNext(false)
            // navigate('/profile')
            setError({
                status: 200,
                message: 'Запит на відновлення успішно надіслано'
            })
            setSuccessRecover(true)
            setLoadingRequest(false)
        }).catch(e => {
            console.log(e.response.data.message)
            setError({
                status: 404,
                message: errorMessage(e)
            })
            setLoadingRequest(false)
        })
    }

  return (
    <form className='login__form'>
        <div className={
            error.status === 404 ? 'login__error' :
            error.status === 200 ? 'login__success' : 'hide'
        }>
            { error.message }
        </div>
        {
            error.status === 200 ? <ResetSuccess /> : ''
        }
        {
            error.status !== 200 ?
                <>
                    <h3>Відновлення паролю</h3>
                    <EmailFilter 
                        email={email}
                    />
                    
                    <MyButton 
                        classNameMy={'myBtn__green'}
                        type='submit'
                        onClick={reset}
                        disabled={!isValid || loadingRequest || successRecover ? true : false}
                    >
                        Відновити пароль
                    </MyButton>
                    <div className='login__link'>
                        <Link onClick={() => toPage('login')}>Вхід</Link>
                        <Link onClick={() => toPage('register')}>Реєстрація</Link>
                    </div>
                </>
            : ''
        }
    </form>
  )
}

export default ResetForm