const getProductNameById = (productId) => {
    const productsName = {
        1 : 'Зелена карта',
        2 : 'ОСЦПВ',
        3 : 'Тревел',
    }

    return productsName[productId]
}

module.exports = {
    getProductNameById
}