import React from 'react'
import { TextField } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkAutoNumberAPI, getAutoByNumberAPI } from '../../api/carAPI'
import { setAutoDataIsGetData, setAutoDataIsValidateNumber, setAutoDataList, setAutoDataNumber, setAutoDataSelect, setAutoDataValidateNumber } from '../../store/carSlice'
import { useEffect } from 'react'
import MyModalLoading from '../../components/UI/modal/MyModalLoading'

const AutoNumberFilter = ({ number }) => {

    const numberStore = useSelector(state => state.car.getAuto.number)
    const validateNumber = useSelector(state => state.car.getAuto.validateNumber)
    const isValidateNumber = useSelector(state => state.car.getAuto.isValidateNumber)
    const dispatch = useDispatch()

    const [numberTemp, setNumberTemp] = useState('')

    const [openModalLoading, setOpenModalLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => { // Валидация при изменении данных о валидации
        if (validateNumber) {
            validate()
        }
    }, [validateNumber])

    useEffect(() => { // Получение данных по авто при успешной валидации номера (если будет и иностранная регистрация, то добавить првоерку по типу валидации)
        if (isValidateNumber && numberStore) {
            getDataByNumber()
        }
    }, [isValidateNumber, numberStore])

    const checkNumber = (numberEnter) => { // Валидация номера
        checkAutoNumberAPI(numberEnter).then(data => {
            dispatch(setAutoDataValidateNumber(data))
        }).catch(e => {
            // setError(responseError(e))
        })
    }

    const getDataByNumber = () => {
        dispatch(setAutoDataIsGetData(true))

        setOpenModalLoading(true)

        getAutoByNumberAPI(numberTemp).then(data => {
            dispatch(setAutoDataList(data))
            if (data) {
                if (data.length === 1) {
                    dispatch(setAutoDataSelect(data[0]))
                } else if (data.length > 1) {
                    // setOpenModalAutos(true)
                    
                }
            }
            setOpenModalLoading(false)
        }).catch(e => {
            setOpenModalLoading(false)
        })
    }

    const validate = () => {
        if (validateNumber && validateNumber.simple && validateNumber.simple.error) {
            setError(validateNumber.simple.error)
            dispatch(setAutoDataIsValidateNumber(false))
        } else {
            setError('')
            dispatch(setAutoDataIsValidateNumber(true))
        }
    }

  return (
    <>
        {
            openModalLoading ?
                <MyModalLoading
                    header={'Завантаження'}
                    text={'Триває завантаження даних по автомобілю'}
                    open={openModalLoading}
                    setOpen={setOpenModalLoading}
                />
            : ''
        }
        <TextField
            // label={}
            // disabled={
            //     isForeign && foreignType === 'withoutNumber' ? true : 
            //     disabled ? true : false
            // }
            variant='standard'
            placeholder='АХ0000АХ'
            size="small"
            onChange={e => {
                number.onChange(e.target.value ? (e.target.value).toUpperCase() : e.target.value)
                // Очищаю хранилище
                dispatch(setAutoDataValidateNumber(null))
                dispatch(setAutoDataIsValidateNumber(false))
                dispatch(setAutoDataList([]))
                dispatch(setAutoDataSelect(null))
                dispatch(setAutoDataIsGetData(false))
                dispatch(setAutoDataNumber(''))

                // Удаляю ошибку, если она была
                if (error) {
                    setError('')
                }
            }} 
            onBlur={e => {
                number.onBlur(e)
                setNumberTemp(e.target.value)
                if (number.isInputValid){
                    checkNumber(e.target.value)
                    dispatch(setAutoDataNumber(e.target.value))
                    // setIsGetData(true)
                }
                // getDataAuto(e)
            }} 
            value={number.value}
            error={
                number.isDirty && 
                (number.minLengthError || number.maxLengthError || error) ? true : false
            } // чтобы был текст ошибки
            color={
                number.isDirty && 
                (number.minLengthError || number.maxLengthError || error) ? 'error' : 'success'
            } // чтобы был успешный цвет
            helperText={
                number.isDirty && number.errors.minLength ? number.errors.minLength : 
                number.isDirty && number.errors.maxLength ? number.errors.maxLength : 
                error ? error : ''
                
            } //Текст ошибки
            focused={number.isDirty ? true : false}
            InputLabelProps={{
                shrink: true
            }}
        />
    </>
  )
}

export default AutoNumberFilter