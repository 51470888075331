import { $authHost } from "./index"

export const getMenuNameAPI = async () => {
    const {data} = await $authHost.get('api/menu/')
    return data
}

export const getMenuItemsAPI = async () => {
    const {data} = await $authHost.get('api/menu/items/')
    return data
}