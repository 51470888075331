import { Skeleton } from '@mui/material'
import React from 'react'

const PersonSceleton = () => {
  return (
    <div className='personOne'>
        <div className='documents-listOne-headName'>
            KIRINS | <span>людина</span>
        </div>
        <div className='personOne__center'>
            <div>
                <ul className='personOne__list'>
                    <li>
                        <p><Skeleton variant="text" width={100} /></p>
                        <span className='personOne__list-inn'><Skeleton variant="text" width={100} /></span>
                    </li>
                    <li>
                        <p><Skeleton variant="text" width={100} /></p>
                        <span><Skeleton variant="text" width={100} /></span>
                    </li>
                    <li>
                        <p><Skeleton variant="text" width={100} /></p>
                        <span>
                            <Skeleton variant="text" width={100} />
                        </span>
                    </li>
                </ul>
            </div>
            <div className='personOne__listPerson'>
                <p className='personOne__listPerson-name'>
                    <Skeleton variant="text" width={200} />
                </p>
                <p>
                    <Skeleton variant="text" width={100} />
                </p>
                <p><Skeleton variant="text" width={70} /></p>
            </div>
        </div>
    </div>
  )
}

export default PersonSceleton