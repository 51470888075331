import { Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { DOCUMENTS_TYPE_MAIN } from '../../locale/document/DocumentLocale';
import DocumentOneFull from './DocumentOneFull';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { setAlertOpen, setAlertText, setAlertType } from '../../store/alertSlice';
import MyModal from '../UI/modal/MyModal';
import { deleteDocumentAPI } from '../../api/documentAPI';
import { deletePersonDocument } from '../../store/personSlice';

const DocumentOne = ({ document, setDocEditId, personId }) => {

    const [isOpenFull, setIsOpenFull] = useState(false)

    const dispatch = useDispatch()

    const [openModal, setOpenModal] = useState(false)
    const [isAgry, setIsAgry] = useState(false)

    useEffect(() => {
        if (isAgry) {
            deleteDocumentOne()
            setIsAgry(false)
        }
    }, [isAgry])

    const deleteDocumentOne = () => {
        const delEl = {
            id: document.id,
            personId: personId
        }
        console.log(delEl)
        deleteDocumentAPI(delEl).then(data => {
            dispatch(deletePersonDocument(document.id))

            dispatch(setAlertText('Документ успішно видалено'))
            dispatch(setAlertType('success'))
            dispatch(setAlertOpen(true))
        }).catch(e => {
            dispatch(setAlertText(e.response.data.message))
            dispatch(setAlertType('error'))
            dispatch(setAlertOpen(true))
        })

        setTimeout(() => {
            dispatch(setAlertOpen(false))
        }, 3000);
    }

  return (
    <>
        <MyModal
            header='Ви впевнені, що хочете видалити документ?'
            text='Повернути документ вже не буде можливості'
            isOpen={openModal}
            setOpenModal={setOpenModal}
            setIsAgry={setIsAgry}
            textYes='Видалити'
            textNo='Скасувати'
        /> 
        <li>
            <p>
                {
                    DOCUMENTS_TYPE_MAIN[document.type]['uk']
                }
            </p>
            <p>
                <span>
                    {
                        document.series ? document.series + ' ' : ''
                    }
                    {
                        document.number ? document.number : ''
                    }
                </span>
                <Tooltip title='Повна інформація'>
                    <SearchIcon onClick={e => setIsOpenFull(!isOpenFull)} />
                </Tooltip>
                <Tooltip title='Редагувати'>
                    <EditIcon onClick={e => setDocEditId(document.id)} />
                </Tooltip>
                <Tooltip title='Видалити'>
                    <DeleteIcon className='documentDeleteBtn' onClick={e => setOpenModal(true)} />
                </Tooltip>
            </p>
        </li>
        {
            isOpenFull ? <DocumentOneFull document={document} /> : ''
        }
    </>
  )
}

export default DocumentOne