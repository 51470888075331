import React from 'react'
import LoginForm from '../components/login/LoginForm';

const Login = () => {
    // const isAuth = useSelector(state => state.user.isAuth)
    // const url = window.location.href
    // const url = window.location
    // console.log(url)
    // const url = useCookies()
    // console.log(url.getAll())
  return (
    <div className='login__page'>
        <LoginForm page='login' />
        {/* {
            !window.localStorage.getItem('token') && !isAuth ?
            // <Navigate to='/profile' />
            <Navigate to='/login' />
            :
            ''
        } */}
    </div>
  )
}

export default Login