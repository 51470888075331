import moment from 'moment'

export const getPolicyColor = (date) => {
    const dateNow = moment()
    const datePolicyEnd = moment(date, 'YYYY-MM-DD')
    if (datePolicyEnd.diff(dateNow, 'days') < 0) {
        return -1
    }
    if (datePolicyEnd.diff(dateNow, 'days') <= 15) {
        return 0
    }
    return 1
}