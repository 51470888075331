// VIN- код повинен складати від 5 до 17 символів, може містити символи латиниці, 
// деякі символи кирилиці (Б, Г, Д, Е, Э, Ж, З, И, Л, П, Ф, Ц, Ч, Ш, Щ, Ю, Я), а також хоч би одну арабську цифру.
// Використання букв I, O, Q у VIN- коді заборонено, щоб запобігти можливості їх сплутування із цифрами 1 та 0.
// Для зручності користувачів при заповненні VIN- коду ТЗ можна не міняти розкладку клавіатури. 
// EWA автоматично перетворює символи, які були введенні з кириличною розкладкою клавіатури, в латиницю.



export const useVinAutoValidate = (vin) => { // Валидация VIN

    const vinReplace = vin.replace(/ /g, '').toUpperCase()
    let textError = ''
    let result = {isValidVin: true, textError: ''}
    
    const vinRegDigit = /\d/
    const vinReg = /[A-HJ-NPR-ZБГДЕЭЖЗИЛПФЦЧШЩЮЯ\d]/

    // Проверка на длину
    if (vinReplace.length < 5 || vinReplace.length > 17) {
        result.isValidVin = false
        result.textError = 'Може бути від 5 до 17 символів'
        return result
    }

    // Проверка на наличие цифр
    if (!vinRegDigit.test(vinReplace)) {
        result.isValidVin = false
        result.textError = 'Має бути хоча б 1 цифра'
        return result
    }

    // Проверка каждой буквы на валидность
    for (const i in vinReplace) {
        if (!vinReg.test(vinReplace[i])) {
            result.isValidVin = false
            result.textError = 'Мають бути лише латинські літери. Також не має бути букв Q, O, I'
            return result
        }
    }

    return {
        isValidVin:true,
        textError: textError
    }
}