import { createSlice } from "@reduxjs/toolkit"

const productHomeSlice = createSlice({
    name: 'productHome',
    initialState: {
        productsHome: [],
    },
    reducers: {
        setProductsHome(state, action) {
            state.productsHome = action.payload
        },
        addProductHome(state, action) {
            state.productsHome.push(action.payload)
        },
        deleteProductHome(state, action) {
            state.productsHome = state.productsHome.filter(product => product.id !== action.payload)
        },
        editProductHome(state, action) {
            state.productsHome = state.productsHome.map(product => {
                if (product.id === action.payload.id)
                    return action.payload
                return product
            })
        },
    }
})

export default productHomeSlice.reducer

export const {setProductsHome, 
                addProductHome,
                deleteProductHome,
                editProductHome,
                
            } = productHomeSlice.actions