import { Alert, Snackbar } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

const MySnakbar = () => {

    const alert = useSelector(state => state.alert)

  return (
    <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alert.open}
    >
        <Alert severity={alert.type} sx={{ width: '100%' }}>
            {alert.text}
        </Alert>
    </Snackbar>
  )
}

export default MySnakbar