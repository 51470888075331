import React, { useState } from 'react'

const PolicyAuto = ({ auto }) => {

    const [visible, setVisible] = useState(false)

  return (
    <div>
        <h3>Авто</h3>
        <ul>
            
            <li>
                <p>Державний номер</p>
                <span>
                    { auto ? auto.number.toUpperCase() : '' }
                </span>
            </li>
            {
                visible ?
                    <>
                        <li>
                            <p>Марка</p>
                            <span>
                                { auto ? auto.mark.toUpperCase() : '' }
                            </span>
                        </li>
                        <li>
                            <p>Модель</p>
                            <span>
                                { auto ? auto.model.toUpperCase() : '' }
                            </span>
                        </li>
                        <li>
                            <p>VIN-код</p>
                            <span>
                                { auto ? auto.vin.toUpperCase() : '' }
                            </span>
                        </li>
                        {
                            auto && auto.volumeEngine ?
                                <li>
                                    <p>Об'єм двигуна</p>
                                    <span>{auto.volumeEngine} см3</span>
                                </li>
                            : ''
                        }
                        <li>
                            <p>Рік випуску</p>
                            <span>
                                { auto ? auto.year : '' }
                            </span>
                        </li>
                    </>
                    : ''
            }
            <p
                className='policy_info-more'
                onClick={e => setVisible(!visible)}
            >
                {
                    !visible ? 'Детальніше' : 'Сховати'
                }
            </p>
        </ul>
    </div>
  )
}

export default PolicyAuto