import React from 'react'

const MenuHeader = ({menu, menuId}) => {
  return (
    <>
        {
          	menu ? 
                menu.map(name => name.id === menuId ? name.nameUa : '') 
            : ''
        }
    </>
  )
}

export default MenuHeader