import React from 'react'

const ActivateEmailError = () => {
  return (
    <div>
        <p>Виникла помилка!</p>
    </div>
  )
}

export default ActivateEmailError