import { createSlice } from "@reduxjs/toolkit"

const carSlice = createSlice({
    name: 'car',
    initialState: {
        cars: [],
        getAuto: {
            list: [],
            validateNumber: null,
            number: '',
            isValidateNumber: false,
            selectAuto: null,
            isGetData: false
        }
    },
    reducers: {
        setCars(state, action) {
            state.cars = action.payload
        },
        addCar(state, action) {
            state.cars.unshift(action.payload)
        },
        deleteCar(state, action) {
            state.cars = state.cars.filter(car => car.id !== action.payload)
        },
        editCar(state, action) {
            state.cars = state.cars.map(car => {
                if (car.id === action.payload.id)
                    return action.payload
                return car
            })
        },
        setAutoDataList(state, action) {
            state.getAuto.list = action.payload
        },
        setAutoDataNumber(state, action) {
            state.getAuto.number = action.payload
        },
        setAutoDataValidateNumber(state, action) {
            state.getAuto.validateNumber = action.payload
        },
        setAutoDataIsValidateNumber(state, action) {
            state.getAuto.isValidateNumber = action.payload
        },
        setAutoDataSelect(state, action) {
            state.getAuto.selectAuto = action.payload
        },
        setAutoDataIsGetData(state, action) {
            state.getAuto.isGetData = action.payload
        },
    }
})

export default carSlice.reducer

export const {
    setCars,
    addCar,
    deleteCar,
    editCar,
    setAutoDataList,
    setAutoDataNumber,
    setAutoDataValidateNumber,
    setAutoDataIsValidateNumber,
    setAutoDataSelect,
    setAutoDataIsGetData,
} = carSlice.actions