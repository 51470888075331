import moment from "moment"

export const useInnGetDateOfBirth = (inn) => { // Получаем дату рождения из ИНН
    if (inn.length !== 10) {
        return false
    }

    let days = ''

    for (let i = 0; i < 5; i++) {
        days += inn[i] // получаем первые 5 цифр (к-во дней с 31.12.1899)
    }

    return moment('31.12.1899', 'DD.MM.YYYY').add(Number(days), 'days')
}

export const useInnGetSex = (inn) => { // Получение пола (м/ж)
    if (inn.length !== 10) {
        return false
    }

    const gender = inn[8] % 2 == 0

    if (gender) {
        return 'жінка'
    } else {
        return 'чоловік'
    }
}

export const useInnCheckLastDigit = (inn) => { // Проверка последней цифры на действительность
    if (inn.length !== 10) {
        return false
    }

    const checkInn = [-1, 5, 7, 9, 4, 6, 10, 5, 7] // цифры для проверки контрольной цифры в ИНН
    let summAndMulti = 0
    let isOk = false

    for (let i = 0; i < 9; i++) {
        summAndMulti += checkInn[i] * inn[i] // сумма всех чисел в результате перемножения каждой цифры ИНН и checkInn
    }

    const checkDigit = summAndMulti % 11 // Получаем верную цифру в конце кода инн

    if (checkDigit && String(checkDigit).length === 1) {
        isOk = inn[9] == checkDigit // true / false
    } else if (checkDigit && String(checkDigit).length === 2) {
        isOk = inn[9] == String(checkDigit)[1] // true / false
    }

    return isOk // true / false
}

export const useInnValidation = (inn) => {
    const dateOfBirth = useInnGetDateOfBirth(inn)
    const sex = useInnGetSex(inn)
    const checkLastDigit = useInnCheckLastDigit(inn)

    return {
        dateOfBirth, sex, checkLastDigit
    }
}