import { Button, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import PoliciesList from '../components/policy/PoliciesList';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveProductItem } from '../store/menuSlice';
import { getProductsAPI } from '../api/productAPI';
import PolicyProducts from '../components/policy/PolicyProducts';

const Policies = () => {
	// library.add(faCar);

	const [products, setProducts] = useState([])
	const [selecterProductId, setSelectedProductId] = useState(null)

	useEffect(() => {
		getProductsAPI().then(data => {
			setProducts(data)
		})
	}, [])

  return (
    <div className='page'>
		<Sidebar />
		<div className='main'>
			<h1>Мої страховки</h1>
			<PolicyProducts 
				products={products}
			/>
			{/* <div className='page-filter'>
				<Button>Діючі поліси</Button>
				<Button>Архів полісів</Button>
			</div> */}
			<div>
				<PoliciesList 
					products={products} 
				/>
			</div>
		</div>
    </div>
  )
}

export default Policies