import { $authHost } from "./index"

export const addPersonAPI = async (person) => {
    const {data} = await $authHost.post('api/person/', person)
    return data
}

export const getPersonAPI = async (id) => {
    const {data} = await $authHost.get('api/person/' + id)
    return data
}

export const getPeopleAPI = async () => {
    const {data} = await $authHost.get('api/person/')
    return data
}

export const editPersonAPI = async (person) => {
    const {data} = await $authHost.post('api/person/' + person.id, person)
    return data
}

export const deletePersonAPI = async (id) => {
    const {data} = await $authHost.delete('api/person/' + id)
    return data
}