import React from 'react'

const RegisterSuccess = () => {
  return (
    <div className='login__success-p'>
        <p>
            На Ваш email відправлено лист з підтвердженням електронної пошти.<br />
            Слідуйте інструкціям в листі.
        </p>

    </div>
  )
}

export default RegisterSuccess