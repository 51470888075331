import React, { useState } from 'react'
import MyButton from '../UI/button/MyButton'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DocumentsList from '../document/DocumentsList';
import DocumentForm from '../document/DocumentForm';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setAlertOpen, setAlertText, setAlertType } from '../../store/alertSlice';
import { deletePersonAPI } from '../../api/personAPI';
import { deletePerson } from '../../store/personSlice';
import MyModal from '../UI/modal/MyModal';
import { setIsAuth } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';

const PersonOne = ({ person, setPersonEditId }) => {

    const [isAddDoc, setIsAddDoc] = useState(false)

    const [hasDocuments, setHasDocuments] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [openModal, setOpenModal] = useState(false)
    const [isAgry, setIsAgry] = useState(false)

    useEffect(() => {
        if(person.documents) {
            const has = []
            person.documents.forEach(doc => has.push(doc.type))
            setHasDocuments(has)
        }
    }, [person])

    useEffect(() => {
        if (isAgry) {
            deletePersonOne()
            setIsAgry(false)
        }
    }, [isAgry])

    const deletePersonOne = () => {
        deletePersonAPI(person.id).then(data => {
            dispatch(deletePerson(person.id))

            dispatch(setAlertText('Людину успішно видалено'))
            dispatch(setAlertType('success'))
            dispatch(setAlertOpen(true))
        }).catch(e => {
            if (e.response.status === 401) {
                dispatch(setIsAuth(false))
                navigate('/login')
            }
            dispatch(setAlertText(e.response.data.message))
            dispatch(setAlertType('error'))
            dispatch(setAlertOpen(true))
        })

        setTimeout(() => {
            dispatch(setAlertOpen(false))
        }, 3000);
    }

  return (
    <div className='personOne'>
        <MyModal
            header='Ви впевнені, що хочете видалити людину?'
            text='Повернути людину вже не буде можливості :) Разом із людиною буде видалено усі документи, що належать до цієї людини'
            isOpen={openModal}
            setOpenModal={setOpenModal}
            setIsAgry={setIsAgry}
            textYes='Видалити'
            textNo='Скасувати'
        /> 
        <div className='documents-listOne-headName'>
            KIRINS | <span>людина</span>
        </div>
        <div className='personOne__center'>
            <div>
                <ul className='personOne__list'>
                    <li>
                        <p>Код ІПН</p>
                        <span className='personOne__list-inn'>{person.inn ? person.inn : ''}</span>
                    </li>
                    <li>
                        <p>Місто прописки</p>
                        <span>{person.city ? person.city.nameFullUa : 'не вказано'}</span>
                    </li>
                    <li>
                        <p>Адреса</p>
                        <span>
                            {
                                person.adress ? person.adress : 'не вказано'
                            }
                            {
                                person.build ? ' ' + person.build : ''
                            }
                            {
                                person.flat ? ', ' + person.flat : ''
                            }
                        </span>
                    </li>
                    <DocumentsList 
                        documents={person.documents} 
                        hasDocuments={hasDocuments} 
                        personId={person.id} 
                    />
                </ul>
            </div>
            <div className='personOne__listPerson'>
                <p className='personOne__listPerson-name'>
                    {
                        person.surname ? person.surname + ' ' : ''
                    }
                    {
                        person.name ? person.name + ' ' : '' 
                    }
                    {
                        person.patronymic ? person.patronymic : ''
                    }
                </p>
                <p>
                    {
                        person.nameEng ? person.nameEng + ' ' : ''
                        
                    }
                    {
                        person.surnameEng ? person.surnameEng : ''
                    }
                </p>
                <p>{person.dateOfBirth ? moment(person.dateOfBirth).format('DD.MM.YYYY') : ''}</p>
            </div>
        </div>
        {
            isAddDoc ?
                <DocumentForm 
                    typeForm='add' 
                    setIsAddDoc={setIsAddDoc} 
                    personId={person.id}
                    hasDocuments={hasDocuments}
                /> : ''
        }
        <div className='personOne__buttons'>
            {
                !isAddDoc ?
                    <>
                        <MyButton
                            onClick={e => setIsAddDoc(true)}
                        >
                            <AddCircleOutlineIcon />
                            Додати документ
                        </MyButton>
                        <div className='personOne__icoButtons'>
                            <Tooltip title='Редагувати людину'>
                                <ManageAccountsIcon
                                    className='personOne__edit'
                                    onClick={e => setPersonEditId(person.id)}
                                />
                            </Tooltip>
                            <Tooltip title='Видалити людину'>
                                <PersonOffIcon
                                    className='personOne__delete'
                                    onClick={e => setOpenModal(true)}
                                />
                            </Tooltip>
                        </div>
                    </>
                : ''
            }
        </div>
    </div>
  )
}

export default PersonOne