import React from 'react'
import DocumentsList from '../components/document/DocumentsList'
import PersonList from '../components/person/PersonList'
import Sidebar from '../components/Sidebar'

const Documents = () => {
  return (
    <div className='page'>
		<Sidebar />
        <div className='main'>
            <h1>Мої документи</h1>
            <div>
				<PersonList />
				{/* <DocumentsList /> */}
            </div>
        </div>
    </div>
  )
}

export default Documents