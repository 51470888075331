import { faCar, faCompass, faEarthAmericas, faListUl, faUmbrella, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton, Tooltip } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeActiveProductItem } from '../../store/menuSlice'

const PolicyProductsSceleton = ({ products }) => {

  return (
    <>
        <div>
            <Skeleton variant="rounded" width={44} height={44} />
        </div>
        <div>
            <Skeleton variant="rounded" width={44} height={44} />
        </div>
        <div>
            <Skeleton variant="rounded" width={44} height={44} />
        </div>
        <div>
            <Skeleton variant="rounded" width={44} height={44} />
        </div>
    </>
  )
}

export default PolicyProductsSceleton