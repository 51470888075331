import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import {useState } from 'react'
import { getModelsAutoByNameAPI } from '../../api/carAPI';

const AutoModelFilter = ({ mark, model, typeAuto, disabled }) => {

    // console.log(typeAuto)

    const [modelTemp, setModelTemp] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [modelsList, setModelsList] = useState([])
    const [closeList, setCloseList] = useState(true)
    
    const getAutoModelsFromBd = (name) => {
        if (name.length > 0) {
            setIsLoading(true)
            getModelsAutoByNameAPI(name, mark.value.id, typeAuto).then(data => {
                console.log(data)
                // setModelsList(data)
                // setListData(data)
                setModelsList(data)
                setIsLoading(false)
                setCloseList(false)
                if (data.length === 0) {
                    setCloseList(true)
                    setModelsList([])
                }
            }).catch(e => console.log(e))
        } else if (name.length === 0) {
            model.onChange({name: ''})
            setModelsList([])
        }
    }

    const filterOptions = (options, state) => { // Делаю поиск как по сокращенному полю на русском так и на укр
        let newOptions = [];
        // console.log(options)
        options.forEach((element) => {
            if (
                element.name
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
            )
                newOptions.push(element);
        });
        // console.log(newOptions)
        return newOptions;
    }

    const setListData = (data) => { // чтобы в поле id получить не наше ИД, а ЕВЫ
        if (data.length > 0) {
            const newList = data.map(one => {
                return {
                    id: one.ewaId,
                    name: one.name
                }
            })
            // console.log(modelsList)
            setModelsList(newList)
        }
    }

  return (
    <Autocomplete
        value={model.value ? model.value : modelTemp}
        // limitTags={1}
        // disablePortal={true}
        disabled={disabled ? true : false}
        onChange={(event, newValue) => { // Весь объект города
            if (newValue) {
                model.onChange({
                    id: newValue.id ? newValue.id : '',
                    ewaId: newValue.ewaId ? newValue.ewaId : '',
                    name: newValue.name ? newValue.name : '',
                })
            }
            setCloseList(true)
        }}
        onBlur={e => {
            model.onBlur(e)
            setCloseList(true)
        }} 
        sx={{width:150}}
        clearOnBlur={false}
        open={!closeList || isLoading ? true : false}
        inputValue={modelTemp}
        onInputChange={(event, newInputValue) => {
            if (event === null) {
                model.onChange({...model.value, name: newInputValue})
                setCloseList(true)
            } else if (event.type === 'change') {
                model.onChange({name: newInputValue})
                getAutoModelsFromBd(newInputValue)
            }
            setModelTemp(newInputValue);
            if (newInputValue.length === 0) {
                model.onChange({name: ''})
                setIsLoading(false)
                setCloseList(true)
            }
        }}
        id="marksAuto"
        size="small"
        options={modelsList}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option ? option.name : ''}
        // getOptionLabel={(option) => option.name === value.name}
        getOptionSelected={(option, value) => option.name === value.name }
        // isOptionEqualToValue={(option, value) => option.name === value}
        noOptionsText={isLoading ? 'Пошук...' : 'Не знайдено'}
        clearText={'Очистити'}
        componentsProps={{
            paper: {
                sx: {
                minWidth: 150,
                maxWidth: 300
                }
            }
            }}
        // sx={{ width: 300 }}
        renderInput={(params) => 
            <TextField
                {...params} 
                variant='standard'
                // label="Марка ТС" 
                placeholder="X5" 
                error={model.isDirty && model.markAutoError ? true : false} // чтобы был текст ошибки
                color={
                    model.isDirty && model.isMarkAuto ? 'error' : 'success'
                } // чтобы был успешный цвет
                helperText={model.isDirty && model.errors.isMarkAuto ? model.errors.isMarkAuto : ''} // Текст ошибки
                focused={model.isDirty ? true : false}
                InputLabelProps={{
                    shrink: true
                }}
            />
        }
    />
  )
}

export default AutoModelFilter