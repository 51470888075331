import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { TextField } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const MyDatePicker = ({ value, labelText, isDirty, isError, error, onBlur, onChange, maxDateType, minDateType, isDisable, placeholderText }, ...props) => {


    const [years, setYears] = useState([])
    const locale = 'uk'
    // const maxDate = moment()._d
    const [maxDate, setMaxDate] = useState('')
    const [minDate, setMinDate] = useState('')
    const months = {
        uk: [
            "Січень",
            "Лютий",
            "Березень",
            "Квітень",
            "Травень",
            "Червень",
            "Липень",
            "Серпень",
            "Вересень",
            "Жовтень",
            "Листопад",
            "Грудень",
        ],
        ru: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
        ],
        en: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]
    }

    // console.log(isError)
    // console.log(value)
    // console.log(labelText)

    useEffect(() => {
        let arr = [], 
            max = [],
            min = [],
            // yearNow = moment().format('yyyy'),
            minYear = 1920,
            // maxYear = 2023
            maxYear = moment().add(1, 'years').format('YYYY')

        switch(maxDateType) {
            case 18:
                max = moment().subtract('18', 'years')._d
                maxYear = moment().subtract('18', 'years').format('yyyy')
                break
            case 'today':
                max = moment()._d
                // minYear = moment().format('yyyy')
                maxYear = moment().format('yyyy')
                break
            case 'to1year':
                max = moment().add('1', 'years')._d
                maxYear = moment().add('1', 'years').format('yyyy')
                break
            case 'to30years':
                max = moment().add('30', 'years')._d
                maxYear = moment().add('30', 'years').format('yyyy')
                break
            default:
                break
        }

        switch(minDateType) {
            case 'today':
                min = moment()._d
                minYear = moment().format('yyyy')
                break
            case 'tomorrow':
                min = moment().add('1', 'days')._d
                minYear = moment().format('yyyy')
                break
            default:
                break
        }

        for(let i = minYear; i <= maxYear; i++) {
            arr.push(i)
        }

        setYears(arr)
        setMaxDate(max)
        setMinDate(min)
    }, [maxDateType, minDateType])

  return (
    <DatePicker 
        locale="uk"
        renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        }) => (
            <div>
                {/* Кнопка назад */}
                <button onClick={e => {
                    e.preventDefault()
                    decreaseMonth()
                }} disabled={prevMonthButtonDisabled}>
                    <KeyboardArrowLeftIcon />
                </button>

                {/* Отображение месяца */}
                <select
                    value={ months[locale][moment(date).format('MM') - 1] }
                    onChange={
                        ({ target: { value } }) =>
                            changeMonth(months[locale].indexOf(value))
                        }
                >
                    {
                        months[locale].map((month) => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))
                    }
                </select>

                {/* Отображение года */}

                <select
                    value={moment(date).format('yyyy')}
                    onChange={({ target: { value } }) => changeYear(value)}
                >
                    {
                        years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))
                    }
                </select>

                {/* Кнопка дальше */}
                <button onClick={e => {
                    e.preventDefault()
                    increaseMonth()
                }} disabled={nextMonthButtonDisabled}>
                    {/* {">"} */}
                    <ChevronRightIcon />
                </button>
            </div>  
        )}
        dateFormat='dd.MM.yyyy' 
        // placeholderText={location ? '__.__._____' : 'Оберіть місцезнаходження авто'}
        placeholderText={
            // !isDisable ? '__.__._____' : ''
            !isDisable ? (placeholderText ? placeholderText : '31.12.1980') : ''
        }
        customInput={
            // <MaskedInput mask="11.11.1111" placeholder="dd.MM.yyyy" />
            <TextField
                variant='standard'
                // label={labelText ? labelText : ''}
                // placeholder='dd.MM.yyyy'
                size="small"
                value={value ? value : ''}
                onChange={(date) => onChange(date)}
                error={isError} // чтобы был текст ошибки
                color={
                    isDirty && isError ? 'error' :
                    isDirty && !isError ? 'success' : ''
                } // чтобы был успешный цвет
                // focused // для отображения ошибки или успеха при выходе из поля
                focused={isDirty ? true : false} // для отображения ошибки или успеха при выходе из поля
                helperText={error} //Текст ошибки
                onBlur={e => onBlur(e)}
                InputLabelProps={{
                    shrink: true
                }}
            />
        }
        maxDate={maxDate}
        minDate={minDate}
        selected={value ? value : ''} 
        // onBlur={e => onBlur(e)}
        onBlur={e => onBlur(e)}
        // onBlur={e => console.log(e)}
        onChange={(date) => {
            onChange(date)
            onBlur()
        }}
        // onChange={(date) => onChange(moment(date).set({'hour': 0, 'minute' : 0, 'second' : 0, 'millisecond' : 0}))}
        // onChange={(date) => onChange(moment(date).format('DD.MM.YYYY'))}
        disabled={
            !isDisable ? false : true
        }
        // disabled={false}
        {...props}
    />
  )
}

export default MyDatePicker