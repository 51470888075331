import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import MySpiner from '../spiner/MySpiner'

const MyModalLoading = ({ header, text, open, setOpen }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={e => setOpen(false)}
        aria-labelledby="loading-modal-auto"
      >
        <DialogTitle id="loading-modal-auto">
          { header }
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                { text }
            </DialogContentText>
			<MySpiner />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MyModalLoading