import React, { useEffect, useState } from 'react'
import MyModal from '../../components/UI/modal/MyModal'
import MyModalLoading from '../../components/UI/modal/MyModalLoading'
import { TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setErrorEmailIsCanOffer, setErrorEmailIsConfirmOne, setErrorEmailIsDoRequest, setErrorEmailIsValid, setErrorEmailTextBody, setErrorEmailTextHeader } from '../../store/errorSlice'
import { checkEmailAPI } from '../../api/mailAPI'

const EmailFilter = ({ email }) => {

    const emailError = useSelector(state => state.error.email)
    const dispatch = useDispatch()
    
    const [openEmail, setOpenEmail] = useState(false)
    const [loadingEmail, setLoadingEmail] = useState(false)

    useEffect(() => {
        if (emailError.isDoRequest) {
            checkEmailRequest()
        }
        // setOpenEmail(emailError.openModal) // Открыть модальное окно
    }, [emailError])

    // useEffect(() => {
    //     if (!openEmail) {
    //         dispatch(setErrorEmailOpenModal(false))
    //     }
    // }, [openEmail])

    const checkEmailRequest = () => {
        // if (!emailError.isConfirmOne) {
            setLoadingEmail(true)
            checkEmailAPI(email.value).then(data => {
                setModalEmail(data)
                setLoadingEmail(false)
                dispatch(setErrorEmailIsDoRequest(false))
                // if (setModalEmail(data)) {
                //     dispatch(setErrorEmailIsConfirmOne(false))
                //     dispatch(setErrorEmailIsCanOffer(true))
                // }
            })
        // }
    }

    const setModalEmail = (data) => {

        if (data && data.status === 200) {
            dispatch(setErrorEmailIsValid(true))
            dispatch(setErrorEmailTextHeader(''))
            dispatch(setErrorEmailTextBody(''))
            dispatch(setErrorEmailIsConfirmOne(true))
            dispatch(setErrorEmailIsCanOffer(true))
            return true
        } else if (data && data.status === 201) { // Не дійсний
            dispatch(setErrorEmailIsValid(false))
            dispatch(setErrorEmailTextHeader('Помилка'))
            dispatch(setErrorEmailTextBody('Вказаний email не пройшов перевірку. Адреса не дійсна. Перевірте, будь ласка, та виправте помилку'))
            dispatch(setErrorEmailIsConfirmOne(true))
            dispatch(setErrorEmailIsCanOffer(false))
            setOpenEmail(true)
            // dispatch(setErrorEmailOpenModal(true))
            return false
        } else if (data && data.status === 202) { // Тимчасова пошта
            dispatch(setErrorEmailIsValid(false))
            dispatch(setErrorEmailTextHeader('Помилка'))
            dispatch(setErrorEmailTextBody('Вказаний email зареєстровано через сервіс тимчасової пошти. Вкажіть, будь ласка, повноцінний email'))
            dispatch(setErrorEmailIsConfirmOne(true))
            dispatch(setErrorEmailIsCanOffer(false))
            setOpenEmail(true)
            // dispatch(setErrorEmailOpenModal(true))
            return false
        } else if (data && data.status === 203) { // Невідомо
            dispatch(setErrorEmailIsValid(false))
            dispatch(setErrorEmailTextHeader('Зауваження'))
            dispatch(setErrorEmailTextBody('Вказаний email не вдалось перевірити. Можливо він має помилки. Перевірте, будь ласка, ще раз. Якщо все вірно, то просто перейдіть на наступний крок'))
            dispatch(setErrorEmailIsConfirmOne(true))
            dispatch(setErrorEmailIsCanOffer(true))
            setOpenEmail(true)
            // dispatch(setErrorEmailOpenModal(true))
            return false
        } else if (data && data.status === 404) { // Не оброблено
            dispatch(setErrorEmailIsValid(true))
            dispatch(setErrorEmailTextHeader(''))
            dispatch(setErrorEmailTextBody(''))
            dispatch(setErrorEmailIsConfirmOne(true))
            dispatch(setErrorEmailIsCanOffer(true))
            return true
        }
    
        return true
    }

  return (
    <>
        <div className="hide">
            <MyModal
                isOpen={openEmail}
                setOpenModal={setOpenEmail}
                header={emailError.textHeader} 
                text={emailError.textBody}
                onlyOk={true}
            />
            <MyModalLoading
                open={loadingEmail}
                setOpen={setLoadingEmail}
                header={'Завантаження'}
                text={'Триває перевірка даних. Це займе кілька секунд'}
            />
        </div>
        <TextField
            label={'Email'}
            placeholder={'example@gmail.com'}
            size="small"
            variant='standard'
            onChange={e => {
                if (e.target.value) {
                    email.onChange(e.target.value.replace(/ /g, ''))
                } else {
                    email.onChange(e.target.value)
                }
                if (emailError.isConfirmOne) {
                    dispatch(setErrorEmailIsValid(true))
                    dispatch(setErrorEmailTextHeader(''))
                    dispatch(setErrorEmailTextBody(''))
                    dispatch(setErrorEmailIsConfirmOne(false))
                    dispatch(setErrorEmailIsCanOffer(true))
                    dispatch(setErrorEmailIsDoRequest(false))
                }
            }} 
            onBlur={e => {
                email.onBlur(e)
            }} 
            value={email.value}
            error={email.isDirty && email['emailError'] || !emailError.isCanOffer ? true : false} // чтобы был текст ошибки
            color={
                email.isDirty && email['emailError'] ? 'error' : 'success'
                // inn.isDirty && !inn.innError ? 'success' : ''
            } // чтобы был успешный цвет
            helperText={email.isDirty && email.errors['isEmail'] ? email.errors['isEmail'] : ''} //Текст ошибки
            focused={email.isDirty ? true : false}
            InputLabelProps={{
                shrink: true
            }}
        />
    </>
  )
}

export default EmailFilter